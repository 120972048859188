import { createEntityAdapter, createSlice, PayloadAction, Reducer, } from '@reduxjs/toolkit'
import moment from 'moment'

export enum NotificationStatus {
  new = 'new',
  read = 'read',
  unread = 'unread'
}

export type Notification = {
  id: number
  message_ar: string
  message_en: string
  created_at: string
  updated_at: string
  personal_number: string
  links: string[]
  redirect_url: string
  status: NotificationStatus
  type: 'error' | 'info' | 'warning'
}

interface NotificationsState {
  notifications: Notification[]
  hasNewNotification: boolean
  hasMorePages: boolean
  page: number
  pageSize: number
  counter: number
}

const stateAdapter = createEntityAdapter()

const initialState = stateAdapter.getInitialState<NotificationsState>({
  notifications: [],
  hasNewNotification: false,
  hasMorePages: false,
  page: 1,
  pageSize: 10,
  counter: 0,
})

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications(state, action: PayloadAction<Notification>) {
      const existingNoticeIndex = state.notifications.findIndex(item => item.id === action.payload.id)
      if (existingNoticeIndex !== -1) {
        state.notifications[existingNoticeIndex] = action.payload
      } else {
        state.counter = state.counter + 1
        state.notifications = [ ...state.notifications, action.payload ]
        state.notifications.sort((a, b) => {
          return moment(b.created_at).diff(a.created_at, 'seconds')
        })
      }
      state.hasNewNotification = state.notifications?.some((item) => {
        return item.status === NotificationStatus.new || item.status === NotificationStatus.unread
      })
      if (state.counter === state.pageSize) {
        state.page = state.page + 1
      }
    },
    setHasNewNotification(state, action: PayloadAction<boolean>) {
      state.hasNewNotification = action.payload
    },
    markNotificationAsRead(state, action: PayloadAction<Notification>) {
      let unreadCounter: number = 0
      state.notifications = state.notifications?.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }
        unreadCounter = item.status === NotificationStatus.unread ? unreadCounter + 1 : unreadCounter
        return item
      })
      state.hasNewNotification = unreadCounter >= 1
    },
    setCounter(state, action: PayloadAction<number>) {
      state.counter = action.payload
    },
  },
})

export const reducer: Reducer<typeof initialState> = notifications.reducer
export const {
  setNotifications,
  setHasNewNotification,
  markNotificationAsRead,
  setCounter,
} = notifications.actions
