import React from 'react';
import { Box, Typography } from '@material-ui/core'
import ContentWrapper from '../shared/ContentWrapper'
import { makeStyles } from '@material-ui/core/styles'

type ApiIncludes = {
  title: string
  apiName?: string | null
  apiVersion?: string | null
  description?: string | null
  bordered?: boolean
}

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary
  },
  api: {
    wordBreak: 'break-word',
    color: theme.UP.content.primary
  },
  apiVersion: {
    whiteSpace: 'nowrap',
    color: theme.UP.content.primary,
    paddingLeft: theme.spacing(1)
  }
}))

const ApiIncludes = (props: ApiIncludes) => {
  const classes = useStyles()
  const { title, description, apiName, apiVersion, bordered } = props
  return (
    <ContentWrapper title={title} bordered={bordered}>
      <Box mb={3} display='flex'>
        {apiName && <Typography data-testid="api-name" variant='h3' className={classes.api}>
          {apiName}
        </Typography>}
        {apiVersion && <Typography data-testid="api-version" variant='h3' className={`${classes.apiVersion} arial`}>
          &nbsp;{`v.${apiVersion}`}
        </Typography>}
      </Box>
      {description && <Typography data-testid="api-description" variant='h4' className={classes.primary}>
        {description}
      </Typography>}
    </ContentWrapper>
  );
};

export default ApiIncludes;
