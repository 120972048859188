import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice, PayloadAction,
  Reducer,
} from '@reduxjs/toolkit'
import React from 'react'

export interface IModalManager {
  open?: boolean
  variant: 'confirm' | 'error' | 'success' | 'custom';
  title?: string;
  text?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  showIcon?: boolean;
  maxWidth?: number;
  children?: React.ReactNode;
}

const stateAdapter = createEntityAdapter()

const initialState = stateAdapter.getInitialState({
  open: false,
  variant: 'custom',
  title: '',
  text: '',
  onClose: () => true,
  onConfirm: () => true,
  confirmBtnText: '',
  cancelBtnText: '',
  showIcon: true,
  maxWidth: 560,
  children: null
}) as IModalManager

export const showModal = createAsyncThunk(
  'modalManager/showModal',
  async (props: IModalManager) => props
)

const modalManager = createSlice({
  name: "modalManager",
  initialState,
  reducers: {
    setOpen(state: IModalManager, action: PayloadAction<boolean>) {
      state.open = action.payload
    },
    resetState(state: IModalManager) {
      state.open = false
      state.variant = 'custom'
      state.title = ''
      state.text = ''
      state.confirmBtnText = ''
      state.cancelBtnText = ''
      state.onConfirm = () => true
      state.onClose = () => true
      state.children = null
      state.showIcon = true
      state.maxWidth = 560
    },
  },
  extraReducers: builder =>
    builder
      .addCase(showModal.fulfilled, (state: IModalManager, action: PayloadAction<IModalManager>) => {
        const {
          confirmBtnText,
          cancelBtnText,
          onConfirm,
          text,
          onClose,
          children,
          title,
          variant,
          showIcon = true,
          maxWidth = 560
        } = action.payload
        state.open = true
        state.variant = variant
        state.title = title
        state.text = text
        state.confirmBtnText = confirmBtnText
        state.cancelBtnText = cancelBtnText
        state.onConfirm = onConfirm
        state.onClose = onClose
        state.showIcon = showIcon
        state.children = children
        state.maxWidth = maxWidth
      })
})

export const reducer: Reducer<typeof initialState> = modalManager.reducer
export const { setOpen, resetState } = modalManager.actions

