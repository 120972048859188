import {
  createAsyncThunk,
  createSlice,
  Reducer,
  SerializedError,
  createEntityAdapter
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_REDIRECTION_URL, LOGOUT } from '../constants/api'
import { globalErrorService } from '../services/globalError'
import getEnvVar from '../utils/getEnvVar'

const stateAdapter = createEntityAdapter()

export const logout = createAsyncThunk(
  'auth/logout',
  async () => await api('post', LOGOUT)
)

export const getRedirectionUrl = createAsyncThunk(
  'auth/getRedirectionUrl',
  async (_, { rejectWithValue }) => {
    return await api('get', GET_REDIRECTION_URL, {}, rejectWithValue)
  }
)

export interface AuthState {
  error: SerializedError
}

const initialState = stateAdapter.getInitialState({
  error: {}
})

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout.fulfilled, (state: AuthState) => {
        state.error = initialState.error
        globalErrorService.clearError()
      })
      .addCase(logout.rejected, (state: AuthState, action) => {
        state.error.name = action.error.name
        state.error.message = action.error.message
        globalErrorService.setError(state.error)
      })
      .addCase(getRedirectionUrl.fulfilled, (state: AuthState, action) => {
        window.location.href = action.payload.url || getEnvVar('REACT_APP_AUTH_SPA') || ''
        state.error = initialState.error
        globalErrorService.clearError()
      })
      .addCase(getRedirectionUrl.rejected, (state: AuthState, action) => {
        state.error.name = action.error.name
        state.error.message = action.error.message
        globalErrorService.setError(state.error)
      })
  }
})

export const reducer: Reducer<typeof initialState> = auth.reducer
