import React from 'react';
import { ReactComponent as ConfirmActionIcon } from '../../assets/images/confirmActionIcon.svg'
import { ReactComponent as ConfirmActionIconDark } from '../../assets/images/confirmActionIconDark.svg'
import { useTheme } from '@material-ui/core'

const ActionIcon = () => {
  const theme = useTheme()
  return (
    <>
      {theme.UP.type === 'light'
        ? <ConfirmActionIcon/>
        : <ConfirmActionIconDark/>
      }
    </>
  );
};

export default ActionIcon;
