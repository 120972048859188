import React, { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Typography,
  Button,
  Container,
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/images/logo-alt.svg'
import heroDecor from '../../assets/images/banner-element-desktop.svg'
import LanguageSwitcher from '../../components/shared/LanguageSwitcher'
import { useAppSelector, useAppDispatch } from '../../store/root'
import getEnvVar from '../../utils/getEnvVar'
import GeneralInformation from '../../components/products/GeneralInformation'
import { PRODUCTS } from '../../constants/landing'
import ApiIncludes from '../../components/products/ApiIncludes'
import PagePreloader from '../../components/shared/PagePreloader'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 10,
    color: '#fff',
    padding: `${theme.spacing(2)}px 0`,
  },
  logo: {
    width: 99,
    height: 'auto',
    marginRight: 'auto',
    '@media (min-width: 768px)': {
      width: 161,
    },
  },
  buttonPrimary: {
    background: theme.UP.controls.button.background.default,
    color: '#fff',
    minWidth: 98,
    textTransform: 'none',
    minHeight: 48,
    borderRadius: 8,
    boxShadow: '0px 10px 20px rgba(21, 130, 133, 0.1)',
    transition: theme.transitions.create(
     ['box-shadow', 'color', 'background-color', 'border-color', 'opacity'],
    {duration: theme.transitions.duration.complex},
    ),
    '@media (min-width: 768px)': {
      minWidth: 150,
    },
    "&:hover": {
      background: theme.UP.controls.button.background.default,
      color: '#fff',
      opacity: 0.85,
      boxShadow: 'none',
    },
    "&.btn-contact": {
      minWidth: 260,
      marginTop: 24,
    },
    '&.btn-sign-up': {
      minHeight: 40,
      '@media (min-width: 551px)': {
        minHeight: 42,
      },
    },
  },
  buttonText: {
    textTransform: 'none',
    color: 'inherit',
    "&:hover": {
      background: 'transparent',
      color: 'inherit',
      opacity: 0.85,
    },
    '&.btn-login': {
      margin: '0 12px',
      '@media (min-width: 768px)': {
        margin: '0 24px',
      }
    },
  },
  buttonWrap: {
    '@media (min-width: 551px)': {
      maxWidth: 309,
    },
  },
  sectionHero: {
    padding: `140px 0 60px`,
    overflow: 'hidden',
    background: 'linear-gradient(to top, #08113D 0%, #1E2872 100%) no-repeat',
    color: '#fff',
    fontSize: 16,
    position: 'relative',
    zIndex: 1,
    '@media (min-width: 1440px)': {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      justifyContent: 'center',
      minHeight: 646,
    },
    '&:before': {
      position: 'absolute',
      left: 0,
      top: 130,
      width: 81,
      height: 100,
      background: `url(${heroDecor}) no-repeat`,
      zIndex: -1,
      '@media (min-width: 1440px)': {
        content: '""',
      },
    },
    '& h1': {
      fontSize: 32,
      lineHeight: 1.25,
      margin: '0 0 0.75em',
    },
    '& img': {
      maxWidth: '100%',
    }
  },
  sectionHeroFrame: {
    maxWidth: 850,
  },
  sectionContent: {
    padding: `${theme.spacing(10)}px 0`,
    overflow: 'hidden',
    background: '#FAFAFA',
    '& img': {
      maxWidth: '100%',
    }
  },
  container: {
    maxWidth: 1128,
    padding: '0 10px',
    '@media (min-width: 768px)': {
      padding: '0 24px',
    },
  },
  footer: {
    fontSize: 14,
    padding: `${theme.spacing(7)}px 0`,
    background: 'linear-gradient(to top, #08113D 0%, #1E2872 100%) no-repeat',
    color: '#fff',
    '@media (max-width: 768px)': {
      padding: `${theme.spacing(5)}px 0`,
    },
    '& a': {
      color: 'inherit',
    },
    '& *': {
      fontSize: 'inherit',
    },
  },
  languageSwitcher: {
    marginLeft: 24,
    '@media (min-width: 768px)': {
      marginLeft: 32,
    },
    '& #changeLanguage:not([aria-controls="menu-list-grow"])': {
      background: 'transparent',
      color: 'inherit',
      borderColor: '#45474B',
    },
    '& [aria-controls="menu-list-grow"]#changeLanguage': {
      background: '#fff',
      borderColor: '#E9EDF3',
    },
  }
}))

const LandingProductDetails = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { lang } = useAppSelector(state => state.user)
  const product = PRODUCTS.find(product => product.id === +id)
  const [ loading, setLoading ] = useState<boolean>(true)

  const handleSignUp = () => {
    window.location.href = `${getEnvVar('REACT_APP_AUTH_SPA')}/${lang}/registration`
  }

  const handleLogin = () => {
    window.location.href = `${getEnvVar('REACT_APP_AUTH_SPA')}/${lang}/authorise`
  }

  const handlePush = (url: string) => {
    dispatch(push({
      pathname: url,
      search: '?scrollToTarget=api-products',
    }))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      setLoading(false)
    }, 500)

  }, [])

  if (loading) return <PagePreloader/>

  return (
    <>
      <Box
        component="header"
        className={classes.header}
      >
        <Container maxWidth={'lg'} className={classes.container}>
          <Box display="flex" alignItems="center">
            <img src={logo} className={classes.logo} alt="logo"/>
            <Button
              variant="text"
              className={`${classes.buttonText} btn-login`}
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
              disableElevation={true}
              onClick={handleLogin}
            >
              {t('actions.login')}
            </Button>
            <Button
              variant="contained"
              className={`${classes.buttonPrimary} btn-sign-up`}
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
              disableElevation={true}
              onClick={handleSignUp}
            >
              {t('actions.signUp')}
            </Button>
            <Box className={classes.languageSwitcher}>
              <LanguageSwitcher />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.sectionHero}>
        <Container maxWidth={'lg'} className={classes.container}>
          <Box className={classes.sectionHeroFrame}>
            <Typography variant="h1">
              {lang === 'ar' ? product?.nameAr : product?.nameEn}
            </Typography>
            <Typography variant='inherit' component='p'>
              {lang === 'ar' ? product?.descriptionAr : product?.descriptionEn}
            </Typography>
            <Box mt={3} className={classes.buttonWrap}>
              <Button
                fullWidth
                id='confirm'
                type='button'
                className={classes.buttonPrimary}
                onClick={() => handlePush('/')}
              >
                {t('actions.backToProducts')}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.sectionContent}>
        <Container maxWidth={'lg'} className={classes.container}>
          <ApiIncludes
            title={t('products.apiIncluded')}
            apiName={product?.info?.title}
            apiVersion={product?.info?.version}
            description={product?.info?.description}
            bordered={false}
          />
          <Box mt={6}>
            <GeneralInformation
              type='REST'
              url={''}
              securityItems={Object.keys(product?.security?.[0] || [])}
              bordered={false}
            />
          </Box>
        </Container>
      </Box>
      <Box className={classes.footer}>
        <Container maxWidth={'lg'} className={classes.container}>
          <Typography variant='inherit' component='p'>Takamol. Copyrights 2021. All rights reserved.</Typography>
        </Container>
      </Box>
    </>
  )
}

export default LandingProductDetails
