import React from 'react';
import { Box, Typography } from '@material-ui/core'
import ContentWrapper from '../shared/ContentWrapper'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CopyField from '../shared/CopyField'
import SecurityItems from './SecurityItems'

type GeneralInformationProps = {
  type: string
  url?: string
  securityItems?: string[]
  bordered?: boolean
}

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary
  },
}))

const GeneralInformation = (props: GeneralInformationProps) => {
  const classes = useStyles()
  const { type, url, bordered, securityItems } = props
  const { t } = useTranslation()

  return (
    <ContentWrapper title={t('products.generalInfo')} bordered={bordered}>
      <Box mb={0.5}>
        <Typography className={classes.primary} variant='subtitle2'>
          {t('products.type')}
        </Typography>
      </Box>
      <Typography className={classes.primary} variant='subtitle1'>
        {type}
      </Typography>
      {url && <>
        <Box mt={3} mb={2}>
          <Typography variant='h3' className={classes.primary}>
            {t('products.endpoint')}
          </Typography>
        </Box>
        <Box>
          <Typography variant='subtitle1' className={classes.primary}>
            {/*TODO*/}
            Production:
          </Typography>
          <Box maxWidth={620} mt={1} data-testid="copy-url">
            <CopyField value={url}/>
          </Box>
        </Box>
      </>}
      {securityItems && <Box mt={4} data-testid="security-items">
        <Typography variant='h3' className={classes.primary}>
          {t('products.security')}
        </Typography>
        <Box maxWidth={700} mt={2}>
          <SecurityItems securityItems={securityItems}/>
        </Box>
      </Box>}
    </ContentWrapper>
  );
};

export default GeneralInformation;
