import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { push } from 'connected-react-router'
import { fetchProducts, setLoading } from '../../store/products'
import { Block } from '@takamol/unified-components'
import { GET_PRODUCT } from '../../constants/api'
import Card from '../../components/products/Card'
import PagePreloader from '../../components/shared/PagePreloader'
import ErrorOverlay from '../../components/shared/DataGrid/ErrorOverlay'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary,
  },
  cardsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  card: {
    maxWidth: 357,
    width: '100%',
    padding: theme.spacing(3),
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 24,
  }
}))

const Products = () => {
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const { products, loading, error } = useAppSelector(state => state.products)
  const { lang, theme } = useAppSelector(state => state.user)

  const handlePush = (id: string) => () => {
    dispatch(push(GET_PRODUCT(id)))
  }

  const handleRefresh = () => {
    dispatch(setLoading(true))
    dispatch(fetchProducts())
  }

  useEffect(() => {
    handleRefresh()
  }, [ dispatch ])

  return (
    <Layout>
      <Block>
        {error && <ErrorOverlay title={t('errors.errorName')} text={error} onRefresh={handleRefresh}/>}
        {loading && <PagePreloader/>}
        {!error && <>
            <Typography className={classes.primary} variant="h2">
              {t('products.title')}
            </Typography>
            <Box className={classes.cardsWrapper}>
              {products.map((item) => (
                <Card
                  key={item.id}
                  name={lang === 'ar' ? item.nameAr || '' : item.nameEn || ''}
                  iconName={`product-icon${theme === 'dark' ? `-${theme}` : ''}-${item.id}`}
                  description={lang === 'ar' ? item.descriptionAr || '' : item.descriptionEn || ''}
                  descriptionMaxLength={lang === 'ar' ? 125 : 85}
                  btnText={t('products.productBtn')}
                  onClick={handlePush(`${item.id}`)}
                />
              ))}
            </Box>
        </>}
      </Block>
    </Layout>
  )
}

export default Products
