import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { serializer, IFilter } from '@takamol/unified-components'
import { GridSortModel } from '@material-ui/data-grid'
import {
  GET_INVOICE,
  GET_SUBSCRIPTIONS,
  POST_CHECK_PAYMENT,
  SEND_APPROVE,
  SEND_REJECT,
  SEND_ACTIVATE,
  POST_CHECK_EMAIL
} from '../constants/api'
import i18n from '../localization/i18n'
import api from '../services/axios'
import getErrorText from '../utils/getErrorText'

export const initialFilters: IFilter = {
  id: {
    value: null,
    action: 'eq'
  },
  product: {
    value: null,
    title: 'name',
    action: 'eq'
  },
  establishment_name: {
    value: null,
    action: 'eq'
  },
  company: {
    value: null,
    action: 'like'
  },
  status: {
    value: null,
    action: 'eq'
  },
  request_date: {
    value: null,
    action: 'eq'
  },
}

export const initialSortModel: GridSortModel = [
  {
    field: 'id',
    sort: 'desc'
  }
]

type Error = { name: string | undefined, message: string | undefined }

type ProductItem = {
  apiUrl: string
  basePrice: number
  descriptionAr: string
  descriptionEn: string
  documentationUrl: string
  id: number
  nameAr: string
  nameEn: string
}

type PlanItem = {
  id: number
  name: string
  rateLimit: number
}

export type SubscriptionItem = {
  id: string
  status: string
  personalNumber: string
  email: string
  userName: string
  laborOffice: number
  sequenceNumber: number
  establishmentId: number
  establishmentName: string
  unifiedNumber: number
  rejectionReason: string | null
  requestDate: string
  startDate: string
  expiryDate: string
  vatRate: number
  vatNumber: string | null
  ibmClientId: string
  ibmClientSecret: string
  paymentStatus: string
  paymentAmount: string
  createdAt: string
  updatedAt: string
  paymentRedirectUrl: string
  product: ProductItem
  plan: PlanItem
}

type SubscriptionsState = {
  pageCount: number
  currentPage: number
  loading: boolean
  subscriptions: SubscriptionItem[]
  filters: IFilter
  sortModel: GridSortModel
  error: Error
  checkPaymentSubscription: {
    checkData: string
    id: string
    status: string
    subscriptionId: number | null
  }
  init: boolean
}

const stateAdapter = createEntityAdapter()

const initialState = stateAdapter.getInitialState({
  pageCount: 1,
  currentPage: 1,
  loading: true,
  subscriptions: [],
  filters: initialFilters,
  sortModel: initialSortModel,
  error: {
    name: '',
    message: ''
  },
  checkPaymentSubscription: {
    checkData: '',
    id: '',
    status: '',
    subscriptionId: null
  },
  init: false
}) as SubscriptionsState

export const fetchSubscriptions = createAsyncThunk(
  'subscriptions/fetchSubsciptions',
  async ({
           currentPage,
           sort,
           filters,
         }: {
    currentPage: number,
    sort: string,
    filters: string
  }, { rejectWithValue }) => {
    return await api('get', GET_SUBSCRIPTIONS(currentPage, sort, filters?.includes('[product]') ? filters.replace('[product]', `[product][name-${i18n.language}]`) : filters), {}, rejectWithValue)
  })

export const sendApprove = createAsyncThunk(
  'subscriptions/sendApprove',
  async (subscriptionId: string, { rejectWithValue }) => {
    return await api('patch', SEND_APPROVE(subscriptionId), {}, rejectWithValue)
  })

type SendRejectProps = {
  id: number
  rejectionReason: string
}

type TActivate = {
  id: number
  clientId: string
  clientSecret: string
}

export const sendActivate = createAsyncThunk(
  'subscriptions/sendActivate',
  async ({ id, clientId, clientSecret } : TActivate, { rejectWithValue }) => {
    const payload = serializer('subscription', {
      id,
      'ibm-client-id': clientId,
      'ibm-client-secret': clientSecret
    })
    return await api('patch', SEND_ACTIVATE, payload, rejectWithValue)
  })

export const sendReject = createAsyncThunk(
  'subscriptions/sendReject',
  async (props: SendRejectProps, { rejectWithValue }) => {
    const { id, rejectionReason } = props
    const payload = serializer('subscription', {
      id,
      rejectionReason
    })
    return await api('patch', SEND_REJECT, payload, rejectWithValue)
  }
)

export const checkPayment = createAsyncThunk(
  'subscriptions/checkPayment',
  async (id: string, { rejectWithValue }) => {
    return await api('post', POST_CHECK_PAYMENT(id), {}, rejectWithValue)
  }
)

export const downloadInvoice = createAsyncThunk(
  'subscriptions/downloadInvoice',
  async (id: string, { rejectWithValue }) => {
    return await api('get', GET_INVOICE(id), {}, rejectWithValue)
  }
)

export const checkEmail = createAsyncThunk(
  'subscriptions/checkEmail',
  async () => {
    return await api('post', POST_CHECK_EMAIL)
  }
)

const subscriptions = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setError(state: SubscriptionsState, action: PayloadAction<Error>) {
      state.error = action.payload
    },
    setCurrentPage(state: SubscriptionsState, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setTotal(state: SubscriptionsState, action: PayloadAction<number>) {
      state.pageCount = action.payload
    },
    setInit(state: SubscriptionsState, action: PayloadAction<boolean>) {
      state.init = action.payload
    },
    setLoading(state: SubscriptionsState, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setFilters(state: SubscriptionsState, action) {
      state.currentPage = 1
      state.filters = action.payload
    },
    setSortModel(state: SubscriptionsState, action) {
      state.currentPage = 1
      state.sortModel = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.subscriptions = action.payload.data
        state.error = initialState.error
        state.currentPage = Math.floor(action.payload.meta.from / action.payload.meta.size) + 1
        state.pageCount = Math.floor(action.payload.meta.total.value / action.payload.meta.size) + 1
        state.loading = false
      })
      .addCase(fetchSubscriptions.rejected, (state, action: any) => {
        state.subscriptions = initialState.subscriptions
        state.pageCount = initialState.pageCount
        state.error = {
          name: i18n.t('modal.errorTitle'),
          message: getErrorText(action.payload?.errors?.[0].title)
        }
        state.loading = false
      })
      .addCase(sendApprove.fulfilled, (state) => {
        state.error = initialState.error
        state.loading = false
      })
      .addCase(checkPayment.fulfilled, (state, action: any) => {
        const { data } = action.payload
        state.checkPaymentSubscription = data
      })
      .addCase(sendApprove.rejected, (state, action: any) => {
        state.error = {
          name: i18n.t('modal.errorTitle'),
          message: getErrorText(action.payload?.errors?.[0].title)
        }
        state.loading = false
      })
      .addCase(sendReject.fulfilled, (state) => {
        state.error = initialState.error
        state.loading = false
      })
      .addCase(sendReject.rejected, (state, action: any) => {
        state.error = {
          name: i18n.t('modal.errorTitle'),
          message: getErrorText(action.payload?.errors?.[0].title)
        }
        state.loading = false
      })
      .addCase(sendActivate.fulfilled, (state) => {
        state.error = initialState.error
        state.loading = false
      })
      .addCase(sendActivate.rejected, (state, action: any) => {
        state.error = {
          name: i18n.t('modal.errorTitle'),
          message: getErrorText(action.payload?.errors?.[0].title)
        }
        state.loading = false
      })
  }
})

export const reducer: Reducer<typeof initialState> = subscriptions.reducer
export const {
  setCurrentPage,
  setTotal,
  setLoading,
  setFilters,
  setInit,
  setError,
  setSortModel
} = subscriptions.actions
