import React, { useMemo } from 'react';
import { ChangeLanguageDropdown } from '@takamol/unified-components';
import { makeStyles } from '@material-ui/core/styles'
import { sendLang } from '../../store/user';
import Ar from '../../assets/images/ar-flag.png';
import En from '../../assets/images/en-flag.png';
import { RootState, useAppDispatch, useAppSelector } from '../../store/root';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#menu-list-grow': {
      fontFamily: 'Almarai, sans-serif',
      fontWeight: 'normal',
      '& *': {
        fontFamily: 'inherit !important',
        fontWeight: 'inherit !important',
      },
    },
    '#changeLanguage': {
      fontFamily: 'Almarai, sans-serif',
      fontWeight: 'normal',
      '& *': {
        fontFamily: 'inherit !important',
        fontWeight: 'inherit !important',
      },
      '& img': {
        display: 'block',
      },
      '& svg *': {
        stroke: 'currentColor',
      },
      '& ul img': {
        marginRight: 8,
      },
    },
  },
}))

const LanguageSwitcher = () => {
  const classes = useStyles()
  const langList = [
    {
      name: 'العربية',
      id: 'ar',
      img: Ar,
    },
    {
      name: 'English',
      id: 'en',
      img: En,
    }
  ];
  const { lang } = useAppSelector((state: RootState) => state.user)
  const dispatch = useAppDispatch()
  const currentLang = useMemo(() => {
    if (lang === 'ar') {
      return langList[0]
    }
    return langList[1]
  }, [ lang ]);

  const handleSendLang = (newLang: { id: string }) => {
    if (lang !== newLang.id) {
      dispatch(sendLang(newLang.id))
      window.sessionStorage.setItem('tipLocale', newLang.id)
    }
  }

  return (
    <ChangeLanguageDropdown
      onItemClick={handleSendLang}
      currentLang={currentLang}
      langList={langList}
    />
  );
};

export default LanguageSwitcher;
