import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { GET_COMPANIES, POST_SET_COMPANY } from '../constants/api'
import api from '../services/axios'
import getErrorText from '../utils/getErrorText'
import i18n from 'i18next'

export interface Workspace {
  id: string
  laborOffice: number
  sequenceNumber: number
  establishmentName: string
  establishmentId: number
  unifiedNumber: number
}

interface WorkspacesInitialState {
  workspaces: Workspace[]
  selectedWorkspace: Workspace
  error: {
    name: string | undefined
    message: string | undefined
  }
  workspaceError: {
    name: string | undefined
    message: string | undefined
  }
  loading: boolean
}

const stateAdapter = createEntityAdapter()

const initialState = stateAdapter.getInitialState({
  workspaces: [],
  selectedWorkspace: {
    id: '',
    laborOffice: 0,
    sequenceNumber: 0,
    establishmentName: '',
    establishmentId: 0,
    unifiedNumber: 0
  },
  error: {
    name: '',
    message: ''
  },
  workspaceError: {
    name: '',
    message: ''
  },
  loading: true
});

export const fetchWorkspaces = createAsyncThunk(
  'workspaces/fetchWorkspaces',
  async (_, {dispatch, rejectWithValue}) => {
    dispatch(setLoading(true))
    return await api('get', GET_COMPANIES, {}, rejectWithValue)
  }
)

export const setWorkspace = createAsyncThunk(
  'workspaces/setWorkspace',
  async (id: string, { rejectWithValue }) => {
    return await api('post', POST_SET_COMPANY(id), {}, rejectWithValue)
  }
)

const workspaces = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    setLoading(state: WorkspacesInitialState, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSelectedWorkspace(state: WorkspacesInitialState, action: PayloadAction<Workspace>) {
      state.selectedWorkspace = action.payload
    },
    setWorkspaceError(state: WorkspacesInitialState, action: PayloadAction<{
      name: string | undefined;
      message: string | undefined;
    }>) {
      state.workspaceError = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWorkspaces.fulfilled, (state: WorkspacesInitialState, action) => {
        state.error = initialState.error
        state.workspaces = action.payload.data
        state.loading = false
      })
      .addCase(fetchWorkspaces.rejected, (state: WorkspacesInitialState, action: any) => {
        if (action.payload?.status === 422) {
          state.error = {
            name: i18n.t('workspacesTip.emptyWorkspaces'),
            message: ''
          }
        } else {
          state.error = {
            name: i18n.t('modal.errorTitle'),
            message: getErrorText(action.payload?.errors?.[0].title)
          }
        }
        state.loading = false
      })
      .addCase(setWorkspace.rejected, (state: WorkspacesInitialState, action: any) => {
        state.loading = false
        state.workspaceError = {
          name: i18n.t('modal.errorTitle'),
          message: getErrorText(action.payload?.errors?.[0].title)
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = workspaces.reducer
export const {setSelectedWorkspace, setLoading, setWorkspaceError} = workspaces.actions

