import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as ErrorIcon } from '../../assets/images/error.svg'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Button, Input, TextButton } from '@takamol/unified-components'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { resetState, showModal } from '../../store/modalManager'
import { sendActivate, setError, setLoading } from '../../store/subscriptions'
import { unwrapResult } from '@reduxjs/toolkit'

type FormValues = {
  clientId: string,
  clientSecret: string
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#clientId::placeholder, #clientSecret::placeholder': {
      color: '#3ABBC1'
    }
  },
  primary: {
    color: theme.UP.content.primary,
  },
  blue: {
    color: theme.UP.common.alternativeBlue,
  },
  error: {
    color: theme.UP.content.error
  },
  modalWrapper: {
    textAlign: 'center',
    padding: theme.spacing(5),

    '@media screen and (max-width:550px)': {
      padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    }
  },
  modalTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  formWrapper: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))

type ActivateModalProps = {
  id: number
  onSuccess: () => void
}

const ActivateModal = (props: ActivateModalProps) => {
  const { id, onSuccess } = props
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(state => state.subscriptions)
  const { t } = useTranslation()
  const { register, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      clientId: '',
      clientSecret: ''
    }
  })
  const handleClose = () => {
    dispatch(resetState())
  }

  const handleActivate = ({ clientId, clientSecret }: FormValues) => {
    dispatch(setLoading(true))
    dispatch(sendActivate({
      id,
      clientId,
      clientSecret
    }))
      .then(unwrapResult)
      .then(() => dispatch(showModal({
        variant: 'success',
        text: t('subscriptions.successfullyActivated')
      })))
      .then(() => onSuccess())
  }

  useEffect(() => () => {
    dispatch(setError({
      name: '',
      message: ''
    }))
  }, [])
  return (
    <Box className={classes.modalWrapper}>
      <ErrorIcon/>
      <Box className={classes.modalTitle}>
        <Typography variant='h1' className={classes.primary}>
          {t('subscriptions.activateAction')}
        </Typography>
      </Box>
      <Typography className={classes.primary} variant='h4'>
        <Trans
            i18nKey='subscriptions.areYouSureToActivate'
            values={{id}}
            components={[<span className='arial'/>]}
        />
      </Typography>

      <form onSubmit={handleSubmit(handleActivate)}>
        <Box className={classes.formWrapper}>
          <Box m={1}>
            <Input
              fullWidth
              type='text'
              name='clientId'
              id='clientId'
              label={t('subscriptions.clientIdLabel')}
              placeholder={t('subscriptions.clientIdPlaceholder')}
              hasError={!!errors.clientId}
              errorMessage={errors.clientId?.message}
              // @ts-ignore
              inputRef={register({
                required: t('validation.thisIsRequiredField')
              })}
            />
          </Box>
          <Box m={1}>
            <Input
              fullWidth
              type='text'
              name='clientSecret'
              id='clientSecret'
              label={t('subscriptions.clientSecretLabel')}
              placeholder={t('subscriptions.clientSecretPlaceholder')}
              hasError={!!errors.clientSecret}
              errorMessage={errors.clientSecret?.message}
              // @ts-ignore
              inputRef={register({
                required: t('validation.thisIsRequiredField')
              })}
            />
          </Box>
        </Box>
        {error.message && <Box mb={4}>
            <Typography className={classes.error} variant='subtitle2'>{error.message}</Typography>
        </Box>}
        <Button disabled={loading} fullWidth id='confirm' type='submit'>
          {t('buttons.confirm')}
        </Button>
        <Box mt={4}>
          <TextButton fullWidth onClick={handleClose} id='cancel'>
            {t('buttons.cancel')}
          </TextButton>
        </Box>
      </form>
    </Box>
  );
};

export default ActivateModal;
