import React, { useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core"
import { ActionButton, Pagination, sortSerializer } from "@takamol/unified-components"
import Layout from "../../components/Layout"
import { useAppDispatch, useAppSelector } from "../../store/root"
import { useTranslation } from "react-i18next"
import { GridAlignment, GridCellParams, GridSortModelParams } from '@material-ui/data-grid'
import { unwrapResult } from '@reduxjs/toolkit'
import { Tooltip } from '@material-ui/core'
import {
  checkPayment,
  fetchSubscriptions,
  setCurrentPage,
  setFilters,
  setSortModel,
  setInit,
  setLoading
} from '../../store/subscriptions'
import DataGridWithOverlays from '../../components/shared/DataGrid/DataGridWithOverlays'
import Filters from '../../components/subscriptions/Filters'
import moment from 'moment'
import sleep from '../../utils/sleep'
import { showModal } from '../../store/modalManager'
import { push } from 'connected-react-router'
import { SUBSCRIPTIONS } from '../../constants/routes'
import { GET_INVOICE } from '../../constants/api';
import SummaryModal from '../../components/subscriptions/SummaryModal'
import useDebounce from '../../hooks/useDebounce'
import { setWorkspace } from '../../store/workspaces'
import { getContext } from '../../store/user'
import getEnvVar from '../../utils/getEnvVar'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#product::placeholder,#status::placeholder,#request_date::placeholder,#id::placeholder,#establishment_name::placeholder': {
      color: '#3ABBC1'
    },
    '#request_date':{
      fontFamily: 'Arial !important'
    },
    '[id*="view"] span svg path, [id*="download"] span svg path': {
      fill: `${theme.UP.common.alternativeGreen} !important`
    },
    '[role="tooltip"] > div': {
      fontFamily: 'Almarai, sans-serif',
      fontSize: 12,
      lineHeight: 1.75,
      fontWeight: 'normal',
      padding: theme.spacing(2),
      background: theme.UP.background.paper,
      margin: 0,
      border: `1px solid ${theme.UP.content.border}`,
      color: theme.UP.common.alternativeGreen,
      borderRadius: 8,
      boxShadow: '0 15px 18px rgba(51, 70, 129, 0.08)',
      '& > div': {
        font: 'inherit',
        padding: 0,
        background: 'transparent',
        margin: 0,
        border: 'none',
        color: 'inherit',
        boxShadow: 'none',
      }
    },
    '.MuiDataGrid-cell': {
      fontFamily: 'Almarai !important'
    },
    '.MuiAutocomplete-popupIndicator svg': {
      fill: `#3ABBC1 !important`
    },
    '[data-field="action"] .MuiDataGrid-colCellTitle': {
      paddingLeft: '12px'
    }
  },
  subscriptionsWrapper: {
    background: theme.UP.background.paper,
    borderRadius: 24,
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(5)}px`,
    '& .Accepted': {
      color: theme.UP.common.alternativeGreen
    },
    "@media (max-width: 840px)": {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(5)}px`,
    },
    "@media (max-width: 1439px)": {
      '& .MuiDataGrid-viewport': {
        overflow: theme.direction === 'rtl' ? 'visible !important' : 'hidden',
      },
    },
    '& .MuiButton-text': {
      backgroundColor: theme.UP.content.primary,
      color: theme.UP.type === 'light' ? theme.UP.common.white : theme.UP.common.black,
      '&:hover': {
        backgroundColor: theme.UP.content.primary,
        color: theme.UP.type === 'light' ? theme.UP.common.white : theme.UP.common.black,
      }
    },
    '& [role="cell"], & [role="columnheader"]': {
      padding: '14px 8px !important',
      '&:first-child': {
        paddingLeft: '16px !important',
      }
    },
    '& .data-container [role="cell"] .cellValue': {
      whiteSpace: 'normal',
      display: '-webkit-box !important',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      lineHeight: 1.25,
    },
    '& .data-container [role="row"]:hover': {
      background: theme.UP.content.lightGray,
    },
  },
  dataGridWrapper: {
    height: '642px !important',
  },
  viewDetails: {
    color: theme.UP.common.alternativeGreen,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  requested: {
    color: theme.UP.content.success,
  },
  active: {
    color: theme.UP.content.success,
  },
  paid: {
    color: theme.UP.content.success,
  },
  approved: {
    color: theme.UP.content.success,
  },
  rejected: {
    color: theme.UP.content.error,
  },
  expired: {
    color: theme.UP.content.error,
  },
  btn: {
    cursor: 'pointer',
    padding: '0 12px',
    display: 'inline-block',
    verticalAlign: 'middle',
    color: theme.UP.common.alternativeGreen,
    "&:hover": {
      textDecoration: 'underline',
    }
  }
}))

const filtersSerializer = (data: any) => {
  let filter = '&'
  Object.keys(data).forEach(key => {
    if (data[key].value) {
      filter += `q[${key}]`
      filter += data[key].action ? `[${data[key].action}]=${data[key].value}${data[key].action === 'like' ? '*' : ''}` : `=${data[key].value}`
      filter += '&'
    }
  })
  return filter.substring(0, filter.length - 1)
}

const Subscriptions = () => {
  const showScroll = useMediaQuery('(max-width:1440px)')
  const flexValue = showScroll ? 0 : 1
  // @ts-ignore
  const params = (new URL(document.location)).searchParams
  const { t, i18n: { language } } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const {
    pageCount,
    currentPage,
    filters,
    sortModel,
    error,
    loading,
    subscriptions,
    checkPaymentSubscription,
    init
  } = useAppSelector(state => state.subscriptions)
  const { nationalId, sequenceNumber, laborOffice, workspace } = useAppSelector(state => state.user)
  const debouncedFilters = useDebounce(filters, 700)

  const paymentId = params.get('paymentid') || params.get('payment-id')
  const subscriptionId = init ? null : params.get('subscription-id') || checkPaymentSubscription?.subscriptionId
  const establishmentId = params.get('establishment-id')

  const columns = [
    {
      field: 'id',
      headerName: t('subscriptions.id'),
      width: 130,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      showTooltip: true,
      renderCell: (params: GridCellParams) => (
        <Box style={{ textAlign: 'center' }}>
          <b className='arial'>{params.id}</b>
        </Box>
      )
    },
    {
      field: 'productName',
      headerName: t('subscriptions.productName'),
      width: 200,
      flex: flexValue,
      sortable: false,
      showTooltip: true,
      renderCell: (params: GridCellParams) => (
        <>{language === 'ar' ? params.row?.product?.nameAr : params.row?.product?.nameEn}</>
      )
    },
    {
      field: 'createdAt',
      headerName: t('subscriptions.creationDate'),
      width: 115,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      renderCell: (params: GridCellParams) => (
        <Typography variant='subtitle2' className='arial'>
          {params.value && moment(String(params.value)).format('DD/MM/YYYY')}
        </Typography>
      )
    },
    {
      field: 'expiryDate',
      headerName: t('subscriptions.expiryDate'),
      width: 115,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      renderCell: (params: GridCellParams) => (
        <Typography variant='subtitle2' className='arial'>
          {params.value && moment(String(params.value)).format('DD/MM/YYYY')}
        </Typography>
      )
    },
    {
      field: 'establishmentName',
      headerName: t('subscriptions.company'),
      width: 205,
      flex: flexValue,
      showTooltip: true,
    },
    {
      field: 'productPrice',
      headerName: t('subscriptions.productPrice'),
      width: 115,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <Typography variant='subtitle2' className='arial'>{params.row?.product?.basePrice}</Typography>
          <Typography variant='subtitle2'>&nbsp;SAR</Typography>
        </>
      )
    },
    {
      field: 'status',
      headerName: t('subscriptions.status'),
      width: 100,
      renderCell: (params: GridCellParams) => {
        const isPaid = checkPaymentSubscription.subscriptionId?.toString() === params.id
          && checkPaymentSubscription.status === 'paid'
        return (
          <Typography
            variant="subtitle2"
            className={classes[isPaid ? 'paid' : params.value as 'paid' | 'requested' | 'approved' | 'active' | 'rejected' | 'expired']}
          >
            {t(`subscriptions.${isPaid ? checkPaymentSubscription.status : params.value}`)}
          </Typography>
        )
      }
    },
    {
      field: 'action',
      headerName: t('subscriptions.action'),
      width: 120,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Box className='cell-value'>
            {params.row.status === 'rejected' &&
              <Tooltip
                enterTouchDelay={0}
                title={t('subscriptions.actionViewTooltip') || ''}
                placement="top"
              >
                <Box style={{display: 'inline-block', verticalAlign: 'middle'}}>
                  <ActionButton
                    variant='view'
                    id={`view-${params.row.id}`}
                    onClick={handleShowReason(params.row.rejectionReason)}
                  />
                </Box>
              </Tooltip>
            }
            {params.row.status === 'active' &&
              <Tooltip
                enterTouchDelay={0}
                title={t('subscriptions.actionViewTooltip') || ''}
                placement="top"
              >
                <Box style={{display: 'inline-block', verticalAlign: 'middle'}}>
                  <ActionButton
                    variant='view'
                    id={`view-${params.row.id}`}
                    onClick={handleShowSummary(params.row.ibmClientId, params.row.ibmClientSecret)}
                  />
                </Box>
              </Tooltip>
            }
            {(params.row.status === 'active' || params.row.status === 'paid') &&
              <Tooltip
                enterTouchDelay={0}
                title={t('subscriptions.actionInvoiceTooltip') || ''}
                placement="top"
              >
                <Box style={{display: 'inline-block', verticalAlign: 'middle'}}>
                  <ActionButton
                    variant='download'
                    id={`download-${params.row.id}`}
                    onClick={handleDownload(params.id as string)}
                  />
                </Box>
              </Tooltip>
            }
            {params.row.status === 'approved' &&
              <Typography
                variant='subtitle2'
                onClick={handlePush(SUBSCRIPTIONS + `/${params.id}`)}
                className={classes.btn}
              >
                {t('subscriptions.pay')}
              </Typography>
            }
          </Box>
        )
      }
    },
  ]

  const handlePush = (url: string) => () => {
    dispatch(push(url))
  }

  const handleShowReason = (reason: string) => () => {
    dispatch(showModal({
      variant: 'error',
      title: t('subscriptions.rejectModalTitle'),
      text: `${t('subscriptions.rejectReason')} — ${reason}`
    }))
  }

  const handleShowSummary = (id: string, secretKey: string) => () => {
    dispatch(showModal({
      variant: 'custom',
      children: <SummaryModal id={id} secretKey={secretKey}/>,
    }))
  }

  const handleDownload = (id: string) => () => {
    const link = document.createElement('a')
    link.download = `invoice-${GET_INVOICE(id)}`
    link.target = '_blank'
    link.href = `${getEnvVar('REACT_APP_API_GATEWAY')}${GET_INVOICE(id)}`
    link.click()
  }

  const handleSort = (params: GridSortModelParams) => {
    const { sortModel } = params
    dispatch(setCurrentPage(1))
    dispatch(setSortModel(sortModel))
  }

  const handlePagination = (event: object, page: number) => {
    dispatch(setCurrentPage(page))
  }

  const handleRefresh = () => {
    dispatch(fetchSubscriptions({
      currentPage,
      sort: sortSerializer(sortModel),
      filters: filtersSerializer({
        ...filters,
        // TODO Mocked on FE
        ...{
          personal_number: {
            value: nationalId,
            action: 'eq'
          },
          sequence_number: {
            value: sequenceNumber,
            action: 'eq'
          },
          labor_office: {
            value: laborOffice,
            action: 'eq'
          }
        }
      })
    }))
  }

  const setSubscriptionId = (id: string) => {
    dispatch(setFilters({
      ...filters,
      ['id']: {
        action: "eq",
        value: id
      }
    }))
  }

  useEffect(() => {
    if (init) {
      handleRefresh()
    }
  }, [ currentPage, sortModel, debouncedFilters, init ])

  useEffect(() => {
    if (paymentId) {
      dispatch(setLoading(true))
      dispatch(checkPayment(paymentId))
      .then(unwrapResult)
      .then((res) => {
        const { subscriptionId } = res.data
        if (subscriptionId) {
          setSubscriptionId(subscriptionId.toString())
        }
      })
      sleep()
      .then(() => {
        dispatch(setInit(true))
      })
    }
  }, [ paymentId ])

  useEffect(() => {
    if (subscriptionId) {
      setSubscriptionId(subscriptionId.toString())
      if (workspace === establishmentId) {
        setSubscriptionId(subscriptionId.toString())
        sleep(1000).then(() => dispatch(setInit(true)))
      } else if (establishmentId) {
        dispatch(setWorkspace(establishmentId))
        .then(() => dispatch(getContext()))
        .then(() => {
          setSubscriptionId(subscriptionId.toString())
          sleep(1000).then(() => dispatch(setInit(true)))
        })
      }
    } else {
      dispatch(setInit(true))
    }
  }, [])

  return (
    <Layout>
      <Grid className={classes.subscriptionsWrapper}>
        <DataGridWithOverlays
          onSort={handleSort}
          title={t('subscriptions.title')}
          loading={loading}
          autoHeight
          hasError={!!(error.name || error.message)}
          errorTitle={error.name}
          errorText={error.message}
          onRefresh={handleRefresh}
          columns={columns}
          rows={subscriptions}
          sortModel={sortModel}
          Filters={<Filters/>}
          Pagination={pageCount > 1
            ? <Pagination total={pageCount} currentPage={currentPage} handlePagination={handlePagination}/>
            : null}
          rowsHeight={66}
        />
      </Grid>
    </Layout>
  )
}

export default Subscriptions
