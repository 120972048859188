import { PaletteType } from "@material-ui/core"
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Reducer,
  SerializedError
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { POST_PHONE, SEND_LANG, SEND_THEME, SEND_VERIFICATION, POST_PASSWORD, GET_USER_DATA } from '../constants/api'
import { serializer } from '@takamol/unified-components'

interface UserState {
  lang: string
  theme: PaletteType
  error: null | SerializedError
  loading: boolean
  name: string
  nationalId: string
  img: string
  phoneNumber: string
  birthDate: string
  account: string
  roles: string[]
  workspace: string
  sequenceNumber: string
  laborOffice: string
}

export type TChangePassword = {
  password: string
  newPassword: string
  passwordConfirm: string
}

const stateAdapter = createEntityAdapter()

export const sendLang = createAsyncThunk(
  'user/sendLang',
  async (locale: string, { dispatch, rejectWithValue }) => {
    dispatch(setLang(locale))
    const payload = serializer('session', {
      language: locale
    })
    return await api('post', SEND_LANG(locale), payload, rejectWithValue)
  }
)

export const sendTheme = createAsyncThunk(
  'user/sendTheme',
  async (theme: string, { rejectWithValue }) => {
    const payload = serializer('session', {
      theme
    })
    return await api('post', SEND_THEME(theme), payload, rejectWithValue)
  }
)

export const sendPhone = createAsyncThunk(
  'user/sendPhone',
  async (phone: string, { rejectWithValue }) => {
    const payload = serializer('phones', {
      phone
    })
    return await api('post', POST_PHONE, payload, rejectWithValue)
  }
)

type TSendOtp = {
  otp: string
  apiUrl: string
}

export const sendVerification = createAsyncThunk(
  'user/sendVerification',
  async (nationalId: string, { rejectWithValue }) => {
    const payload = serializer('phones', {
      nationalId
    })
    return await api('post', SEND_VERIFICATION, payload, rejectWithValue)
  }
)
export const sendOtp = createAsyncThunk(
  'user/sendOtp',
  async ({otp, apiUrl}: TSendOtp, { rejectWithValue }) => {
    const payload = serializer('phones', {
      otp
    })
    return await api('post', apiUrl, payload, rejectWithValue)
  }
)

export const sendPassword = createAsyncThunk(
  'user/sendPassword',
  async ({password, newPassword, passwordConfirm}: TChangePassword, { rejectWithValue }) => {
    const payload = serializer('password', {
      password,
      newPassword,
      passwordConfirm
    })
    return await api('post', POST_PASSWORD, payload, rejectWithValue)
  }
)

export const getContext = createAsyncThunk(
  'user/getContext',
  async (_, { rejectWithValue }) => {
    return await api('get', '/context', {}, rejectWithValue)
  }
)

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async (_, { rejectWithValue }) => {
    return await api('get', GET_USER_DATA, {}, rejectWithValue)
  }
)

export const receiveGWTToken = createAsyncThunk(
  'user/receiveGWTToken',
  async ({ code }: { code: string }, { rejectWithValue }) => {
    return await api('post', `/oauth/callback?code=${code}`, {}, rejectWithValue)
  }
)

const initialState = stateAdapter.getInitialState({
  lang: 'en', // can be ar || en
  theme: 'light' as PaletteType,
  error: {
    name: '',
    message: '',
    code: ''
  },
  loading: true,
  name: '',
  nationalId: '',
  img: '',
  phoneNumber: '',
  birthDate: '',
  account: '',
  roles: [],
  workspace: '',
  sequenceNumber: '',
  laborOffice: ''
}) as UserState

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLang(state: UserState, action) {
      state.lang = action.payload
    },
    setTheme(state: UserState, action) {
      state.theme = action.payload
    },
    setLoading(state: UserState, action) {
      state.loading = action.payload
    },
    setError(state: UserState, action) {
      state.error = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getContext.fulfilled, (state: UserState, action) => {
        state.error = initialState.error
        state.lang = action.payload.data.language
        if (action.payload.data.theme) state.theme = action.payload.data.theme
        state.name = action.payload.data.name
        state.nationalId = action.payload.data.nationalId
        state.roles = action.payload.data.roles
        state.sequenceNumber = action.payload.data.sequenceNumber
        state.workspace = `${action.payload.data.laborOffice}-${action.payload.data.sequenceNumber}`
        state.loading = false
      })
      .addCase(getContext.rejected, (state: UserState) => {
        state.loading = false
      })
      .addCase(getUserData.fulfilled, (state: UserState, action) => {
        state.error = initialState.error
        state.phoneNumber = action.payload.data.phone
        state.birthDate = action.payload.data.birthDate
      })
      .addCase(getUserData.rejected, (state: UserState) => {
        state.loading = false
      })
      .addCase(receiveGWTToken.fulfilled, (state: UserState, action) => {
        state.error = initialState.error
        state.loading = false
      })
      .addCase(receiveGWTToken.rejected, (state: UserState, action) => {
        const { name, message } = action.error
        state.error = {
          name,
          message
        }
        state.loading = false
      })
      .addCase(sendLang.fulfilled, (state: UserState) => {
        state.error = initialState.error
      })
      .addCase(sendLang.rejected, (state: UserState, action) => {
        const { name, message } = action.error
        state.error = {
          name,
          message
        }
        state.loading = false
      })
      .addCase(sendTheme.fulfilled, (state: UserState, action) => {
        state.error = initialState.error
        state.theme = action.meta.arg as PaletteType
      })
      .addCase(sendTheme.rejected, (state: UserState, action) => {
        const { name, message } = action.error
        state.error = {
          name,
          message
        }
        state.loading = false
      })
      .addCase(sendVerification.fulfilled, (state: UserState, action) => {
        state.error = initialState.error
      })
      .addCase(sendVerification.rejected, (state: UserState, action) => {
        const { name, message } = action.error
        state.error = {
          name,
          message
        }
        state.loading = false
      })
      .addCase(sendPhone.fulfilled, (state: UserState, action) => {
        state.error = initialState.error
      })
      .addCase(sendPhone.rejected, (state: UserState, action) => {
        const { name, message } = action.error
        state.error = {
          name,
          message
        }
        state.loading = false
      })
      .addCase(sendOtp.fulfilled, (state: UserState, action) => {
        state.error = initialState.error
      })
      .addCase(sendOtp.rejected, (state: UserState, action) => {
        const { name, message } = action.error
        // @ts-ignore
        const { errors } = action.payload
        const errorIndex = errors?.findIndex(
          (item: SerializedError) => item?.code === 'unifonic-error-1' || item?.code === 'absher-error-1'
        )
        state.error = {
          name,
          message,
          code: errors[errorIndex !== -1 ? errorIndex : 0]?.code || ''
        }
        state.loading = false
      })
      .addCase(sendPassword.fulfilled, (state: UserState, action) => {
        state.error = initialState.error
      })
      .addCase(sendPassword.rejected, (state: UserState, action) => {
        const { name, message } = action.error
        // @ts-ignore
        const { errors } = action.payload
        state.error = {
          name,
          message,
          code: errors?.length ? errors[0]?.code : ''
        }
        state.loading = false
      })
  }
})

export const reducer: Reducer<typeof initialState> = user.reducer
export const { setLoading, setError, setTheme, setLang } = user.actions

