import React from 'react';
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Preloader } from "@takamol/unified-components";

const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    background: theme.UP.background.paper,
    minHeight: 300,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  }
}));

const DataGridLoader = () => {
  const classes = useStyles()
  return (
    <Box className={classes.loaderWrapper}>
      <Preloader/>
    </Box>
  );
};

export default DataGridLoader;
