import React, { useState, useEffect } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import setupAxiosInterceptors from '../../services/interceptors'
import { push } from 'connected-react-router'
import PagePreloader from '../../components/shared/PagePreloader'
import { RootState, useAppDispatch, useAppSelector } from '../../store/root'
import { getContext, receiveGWTToken } from '../../store/user'
import { LANDING, LANDING_PRODUCT, PRODUCTS, SUBSCRIPTIONS, WORKSPACES } from '../../constants/routes'
import Landing from '../../pages/landing/Landing'
import LandingProductDetails from '../../pages/landing/LandingProductDetails'

function useQuery() {
  const {search} = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

type TRoute = {
  path: string
  permissions: string[]
  // tslint:disable-next-line:ban-types
  data: Function
  // tslint:disable-next-line:ban-types
  rules: Function
  component: any
  _hasPermission?: boolean
  _hasData?: boolean
  _hasRules?: boolean
}

type TAttributes = {
  routes: TRoute[]
}

const PermissionManager = ({routes}: TAttributes) => {
  const {nationalId, roles, loading, sequenceNumber} = useAppSelector((state: RootState) => state.user)
  const dispatch = useAppDispatch()
  const [newRoutes, setNewRoutes] = useState([] as any)
  const query = useQuery()
  const isAdmin = roles.includes('admin') || roles.includes('ibm-admin')

  useEffect(() => {
    const code = query.get('code') || ''
    setupAxiosInterceptors();
    onLoad(code)
  }, [])

  useEffect(() => {
    if (nationalId) {
      Promise.all(routes.map(calculate))
        .then((data) => {
          setNewRoutes(data)
        })
        .catch((e) => {
          console.error('calculate routes error:', e)
        })
    }
  }, [nationalId])

  async function calculate(route: TRoute) {
    if (route.permissions.length && roles.length) {
      route._hasPermission = route.permissions.some((permission) => {
        return roles.includes(permission as never)
      })
    } else if (route.permissions.length === 0) {
      route._hasPermission = true
    } else {
      route._hasPermission = false
    }

    if (route._hasPermission) {
      const data = await route.data()
      if (data) {
        route._hasData = !!data
      }
      const resolved = route.rules(data)
      if (resolved) {
        route._hasRules = resolved
      }
    }

    return route
  }

  const checkPermission = (route: TRoute): boolean => {
    const hasAccess = !!route._hasData && !!route._hasRules && !!route._hasPermission
    return hasAccess
  }

  async function onLoad(code: string) {
    try {
      if (code) {
        await dispatch(receiveGWTToken({code}))
      }
      await dispatch(getContext()).then(unwrapResult).then((res) => {
        const { roles } = res.data
        if (code) {
          if (roles.includes('admin') || roles.includes('ibm-admin')) {
            dispatch(push(SUBSCRIPTIONS))
          } else {
            const url = window.localStorage.getItem('tip_redirected_from_url')
            if (url && url.includes('subscription-id') && url.includes('establishment-id')) {
              dispatch(push(url))
            } else {
              dispatch(push(WORKSPACES))
            }
          }
          window.localStorage.removeItem('tip_redirected_from_url')
        }
      })
    } catch (err) {
      console.error('onLoad:', err)
    }
  }

  const getRedirectionUrl = () => {
    if (isAdmin) {
      return SUBSCRIPTIONS
    } else if (sequenceNumber) {
      return PRODUCTS
    }

    return WORKSPACES
  }

  if (loading) return <PagePreloader/>

  return (
    nationalId ? <Switch>
        {!!newRoutes.length && newRoutes.map((route: TRoute) => (
            checkPermission(route) && <Route exact key={route.path} path={route.path} component={route.component}/>
          ))
        }
        <Redirect exact from={LANDING} to={getRedirectionUrl()} />
        <Redirect exact from={LANDING_PRODUCT} to={getRedirectionUrl()} />
      </Switch>
      : <Switch>
        <Route exact key={'default'} path={LANDING} component={Landing} />
        <Route exact key={LANDING_PRODUCT} path={LANDING_PRODUCT} component={LandingProductDetails} />
      </Switch>
  )
}

export default PermissionManager
