import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '../modalManager/EditIcon'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Input, TextButton } from '@takamol/unified-components'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { resetState, showModal } from '../../store/modalManager'
import { sendOtp, sendVerification, setError } from '../../store/user'
import { POST_ABSHER_OTP as apiUrl } from '../../constants/api'
import ChangePhoneNumberModal from './ChangePhoneNumberModal'

type FormValues = {
  otp: string
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#otp::placeholder': {
      color: '#3ABBC1'
    }
  },
  primary: {
    color: theme.UP.content.primary,
  },
  blue: {
    color: theme.UP.common.alternativeBlue,
  },
  error: {
    color: theme.UP.content.error
  },
  modalWrapper: {
    textAlign: 'center',
    padding: theme.spacing(5),

    '@media screen and (max-width:550px)': {
      padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    }
  },
  modalTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  formWrapper: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  resendButton: {
    padding: 0,
    margin: '0 auto 0 0',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 'bold',
    textDecoration: 'none !important',
    opacity: 1,
    '&:not(:disabled):hover span': {
      textDecoration: 'underline',
    },
  },
  resendText: {
    ...theme.typography.subtitle1,
    padding: '0 0 2px',
    color: '#3abbc1',
  },
  resendCounter: {
    ...theme.typography.subtitle1,
    padding: '0 0 2px',
    marginLeft: theme.spacing(1),
    textDecoration: 'underline',
    color: '#3abbc1',
  }
}))

const OtpAbsherModal = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { nationalId, error } = useAppSelector(state => state.user)
  const { t } = useTranslation()
  const { register, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      otp: ''
    }
  })
  const [counter, setCounter] = useState(90)
  const isDisabledButton = error && (error.code === 'unifonic-error-1' || error.code === 'absher-error-1')

  const handleClose = () => {
    dispatch(resetState())
  }

  const handleSendOtp = ({ otp }: FormValues) => {
    dispatch(sendOtp({ otp, apiUrl }))
    .then(unwrapResult)
    .then(() => {
      dispatch(showModal({
        variant: 'custom',
        children: <ChangePhoneNumberModal/>,
        maxWidth: 561,
        onClose: () => {
          dispatch(resetState())
        }
      }))
    }).catch(err => {
      console.error(err)
    })
  }

  const handleResend = () => {
    dispatch(sendVerification(nationalId))
    setCounter(90)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => prev - 1)
    }, 1000)
    if (counter === 0) {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [counter])

  useEffect(() => () => {
    dispatch(setError({
      name: '',
      message: '',
      code: ''
    }))
  }, [])

  return (
    <Box className={classes.modalWrapper}>
      <EditIcon/>
      <Box className={classes.modalTitle}>
        <Typography variant='h1' className={classes.primary}>
          {t('profile.modalTitlePhone')}
        </Typography>
      </Box>
      <Typography className={classes.primary} variant='h4'>
        {t('profile.modalSubTitleAbsher')}
      </Typography>
      <form onSubmit={handleSubmit(handleSendOtp)}>
        <Box className={classes.formWrapper}>
          <Box mt={1} mb={!!errors.otp ? 3 : 1}>
            <Input
              fullWidth
              type='text'
              name='otp'
              id='otp'
              label={t('profile.otpAbsherLabel')}
              placeholder={t('profile.otpAbsherPlaceholder')}
              hasError={!!errors.otp}
              errorMessage={errors.otp?.message}
              inputProps={{maxLength: 6}}
              // @ts-ignore
              inputRef={register({
                required: t('validation.thisIsRequiredField')
              })}
            />
          </Box>
          <TextButton
            id='resend'
            disabled={counter !== 0}
            className={classes.resendButton}
            onClick={handleResend}
          >
            <span className={classes.resendText}>{t('profile.resend')}</span>
            {counter !== 0 && <span className={`${classes.resendCounter} arial`}>({counter})</span>}
          </TextButton>
        </Box>
        {error?.message && <Box mb={4}>
          <Typography
            className={classes.error}
            variant='subtitle2'>
              {error.code ? t(`errorsTip.${error.code}`) : error.message}
          </Typography>
        </Box>}
        <Button fullWidth id='next' disabled={!!isDisabledButton} type='submit'>
          {t('profile.next')}
        </Button>
        <Box mt={4}>
          <TextButton fullWidth onClick={handleClose} id='cancel'>
            {t('profile.back')}
          </TextButton>
        </Box>
      </form>
    </Box>
  );
};

export default OtpAbsherModal;
