import { configureStore } from '@reduxjs/toolkit'
import { reducer as auth } from './auth'
import { reducer as user } from './user'
import { reducer as notifications } from './notifications'
import { reducer as modalManager } from './modalManager'
import { reducer as subscriptions } from './subscriptions'
import { reducer as subscriptionSummary } from './subscriptionSummary'
import { reducer as product } from './product'
import { reducer as products } from './products'
import { reducer as workspaces } from './workspaces'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import getEnvVar from '../utils/getEnvVar'

export const history = createBrowserHistory()

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  auth,
  user,
  notifications,
  modalManager,
  subscriptions,
  product,
  products,
  workspaces,
  subscriptionSummary
})

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(routerMiddleware(history)),
  devTools: getEnvVar('NODE_ENV') !== 'production'
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
