import React from 'react';
import { Box, Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@takamol/unified-components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '[role="tooltip"] > div': {
      fontFamily: 'Almarai, sans-serif',
      fontSize: 14,
      lineHeight: 1.45,
      fontWeight: 'normal',
      padding: theme.spacing(2),
      background: theme.UP.background.paper,
      border: `1px solid ${theme.UP.content.border}`,
      color: '#3ABBC1',
      borderRadius: 8,
      boxShadow: '0 15px 18px rgba(51, 70, 129, 0.08)',
      '& > div': {
        font: 'inherit',
        padding: 0,
        background: 'transparent',
        margin: 0,
        border: 'none',
        color: 'inherit',
        boxShadow: 'none',
      }
    },
  },
  primary: {
    color: theme.UP.content.primary,
  },
  card: {
    display: 'flex',
    flexFlow: 'column nowrap',
    maxWidth: 357,
    width: 'calc(33.3334% - 8px)',
    padding: theme.spacing(3),
    userSelect: 'none',
    boxSizing: 'border-box',
    background: theme.UP.background.paper,
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 24,
    '@media (max-width: 1439px)': {
      maxWidth: 'calc(50% - 8px)',
      width: 'calc(50% - 8px)',
    },
    '@media (max-width: 767px)': {
      maxWidth: '100%',
      width: '100%',
    },
  },
  productIcon: {
    display: 'block',
    width: 48,
    height: 'auto',
  },
  description: {
    color: theme.UP.content.primary,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  tooltip: {
    fontSize: 14,
  }
}))

interface ICardProps {
  name: string
  iconName?: string
  description?: string
  descriptionMaxLength?: number
  btnText: string
  onClick: () => void
}

const Card = (props: ICardProps) => {
  const { name, iconName, description, btnText, descriptionMaxLength, onClick } = props
  const classes = useStyles()
  return (
    <Box className={classes.card}>
      {!!iconName && <img
        data-testid='card-icon'
        className={classes.productIcon}
        src={`images/${iconName}.svg`}
        alt={name || ''}
      />}
      <Box mt={3} mb={'auto'}>
        <Typography variant='h3' className={classes.primary}>
          {name}
        </Typography>
      </Box>
      {description && <Box my={3} data-testid="card-description">
        {!!descriptionMaxLength && description?.length >= descriptionMaxLength
        ? <Tooltip
            data-testid="card-tooltip"
            enterTouchDelay={0}
            title={description || ''}
            placement="top"
            classes={{ tooltip: classes.tooltip }}>
            <Typography variant='subtitle2' className={classes.description}>
              {description}
            </Typography>
          </Tooltip>
        : <Typography variant='subtitle2' className={classes.description}>
          {description}
        </Typography>}
      </Box>}
      <Button fullWidth id='productDetails' onClick={onClick}>
        {btnText}
      </Button>
    </Box>
  );
};

export default Card;
