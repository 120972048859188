import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  checkProduct,
  fetchProduct,
  setCreateSubscriptionRequestError,
  setLoading
} from '../../store/product'
import { Trans, useTranslation } from 'react-i18next'
import { Block, Button } from '@takamol/unified-components'
import { unwrapResult } from '@reduxjs/toolkit'
import ContentWrapper from '../../components/shared/ContentWrapper'
import ApiIncludes from '../../components/products/ApiIncludes'
import GeneralInformation from '../../components/products/GeneralInformation'
import RouteInfo from '../../components/products/RouteInfo'
import RouteAccordion from '../../components/products/RouteAccordion'
import { resetState, showModal } from '../../store/modalManager'
import SubscribeModal from '../../components/products/SubscribeModal'
import PagePreloader from '../../components/shared/PagePreloader'
import ErrorOverlay from '../../components/shared/DataGrid/ErrorOverlay'
import { checkEmail } from '../../store/subscriptions'
import { push } from 'connected-react-router'
import getEnvVar from '../../utils/getEnvVar'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary
  },
  secondary: {
    color: theme.UP.content.secondary,
  },
  pageSubTitle: {
    maxWidth: 738,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    '@media (max-width:550px)': {
      flexDirection: 'column',
      alignItems: 'unset'
    }
  },
  priceWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    background: theme.UP.content.background,
    padding: theme.spacing(3),
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 8,
    '@media (max-width:550px)': {
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
    }
  },
  price: {
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.UP.content.primary,
    '@media (min-width: 768px)': {
      fontSize: '20px',
      lineHeight: '27px',
      width: 167,
      minWidth: 167,
    },
    '@media (min-width: 1440px)': {
      width: 380,
      minWidth: 380,
    },
  },
  priceSubtitle: {
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.UP.content.primary,
    '@media (min-width: 768px)': {
      fontSize: '20px',
      lineHeight: '27px',
    },
  },
  apiInfoWrapper: {
    background: theme.UP.content.background,
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 8,
    '@media (max-width:550px)': {
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
    }
  },
  apiInfoItems: {
    gridGap: theme.spacing(2),
    marginTop: theme.spacing(3),
    overflowY: 'auto',
    '&:first-child': {
      marginTop: 0,
    },
    '@media (min-width: 768px)': {
      display: 'flex',
      justifyContent: 'space-between',
      '& > h4:last-child': {
        width: 167,
        minWidth: 167,
      },
    },
    '@media (min-width: 1440px)': {
      '& > h4:last-child': {
        width: 380,
        minWidth: 380,
      },
    },
  },
  methodUrl: {
    wordBreak: 'break-word',
  },
  basicPlanWrapper: {
    marginTop: theme.spacing(4),
    '@media (min-width: 768px)': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: `0 ${theme.spacing(3)}px`,
      '& > h2:last-child': {
        width: 167,
        minWidth: 167,
      },
    },
    '@media (min-width: 1440px)': {
      '& > h2:last-child': {
        width: 380,
        minWidth: 380,
      },
    },
  },
  accordionTitleWrapper: {
    display: 'flex',
    '@media (max-width:550px)': {
      flexDirection: 'column'
    },
  },
  badge: {
    width: 'fit-content',
    height: 'fit-content',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    border: `1px solid #E69954`,
    background: theme.UP.type === 'light'
    ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #E69954'
    : 'linear-gradient(0deg, rgba(26, 38, 50, 0.9), rgba(26, 38, 50, 0.9)), #E69954',
    color: theme.UP.content.primary,
    borderRadius: 4,
    textTransform: "uppercase",
    marginRight: theme.spacing(2),
    '@media (max-width:550px)': {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    }
  },
  routeUrl: {
    wordBreak: 'break-word',
    color: theme.UP.content.primary,
  },
  subscriptionButton: {
    background: theme.UP.type === 'light'
      ?  `${theme.UP.content.primary} !important`
      : `#4078B7 !important`,
    color: theme.UP.common.white,
  },
  rateLimit: {
    margin: 0,
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.UP.content.primary
  }
}))

const ProductDetails = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const { product, error, loading, productSubscriptionStatus } = useAppSelector(state => state.product)
  const { lang, roles, sequenceNumber } = useAppSelector(state => state.user)
  const [ expanded, setExpanded ] = useState<number | false>(0)

  const handleAccordion = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleModal = () => {
    dispatch(checkEmail())
    .then(unwrapResult)
    .then((res) => {
      const { email, exists } = res.data
      dispatch(showModal({
        variant: 'custom',
        children: <SubscribeModal
          estEmail={email}
          exists={exists}
          onSubscribe={() => dispatch(checkProduct({ id }))}
        />,
        maxWidth: 756,
        onClose: () => {
          dispatch(setCreateSubscriptionRequestError(''))
          dispatch(resetState())
        }
      }))
    })
  }

  const handleRefresh = () => {
    dispatch(setLoading(true))
    dispatch(checkProduct({ id }))
    dispatch(fetchProduct({ id }))
  }

  useEffect(() => {
    handleRefresh()
  }, [ dispatch ])

  useEffect(() => {
    dispatch(setLoading(true))
    dispatch(checkProduct({ id }))
  }, [ sequenceNumber ])

  return (
    <Layout>
      <Block>
        {error && <ErrorOverlay title={t('errors.errorName')} text={error} onRefresh={handleRefresh}/>}
        {!error && <Box>
            <Typography variant='h2' gutterBottom className={classes.primary}>
              {lang === 'ar' ? product.nameAr : product.nameEn}
            </Typography>
            <Box className={classes.pageSubTitle}>
              <Typography variant='h4' className={classes.primary}>
                {lang === 'ar' ? product.descriptionAr : product.descriptionEn}
              </Typography>
            </Box>
            <ApiIncludes
              title={t('products.apiIncluded')}
              apiName={product?.info?.title}
              apiVersion={product?.info?.version}
              description={product?.info?.description}
            />
            <Box mt={2}>
              <GeneralInformation
                type='REST'
                url={product?.securityDefinitions ? product?.securityDefinitions['OAuth2.0'].tokenUrl : ''}
                securityItems={Object.keys(product?.security?.[0] || [])}
              />
            </Box>
            <Box mt={2} mb={3}>
              {product?.paths?.map((path) => (
                path.methods.map((method) => (
                  <RouteAccordion
                    key={path.url + method.method}
                    expanded={expanded === 0}
                    onChange={handleAccordion(0)}
                    title={
                      <Box className={classes.accordionTitleWrapper}>
                        <Box className={classes.badge}>
                          <Typography variant='h6'>{method.method}</Typography>
                        </Box>
                        <Typography variant='h2' className={classes.routeUrl}>
                          {path.url}
                        </Typography>
                      </Box>
                    }
                  >
                    <RouteInfo
                      parameters={method.parameters}
                      url={getEnvVar('REACT_APP_API_EXAMPLE_URL') + product?.basePath + path.url}
                      requestBody={method.requestBody}
                      response={method.responses}
                    />
                  </RouteAccordion>
                ))
              ))}
            </Box>
            <ContentWrapper title={t('products.subscribeToProduct')} showDivider={false}>
              <Box className={classes.basicPlanWrapper}>
                <Typography variant='h2' className={classes.primary}>{t('products.basicPlan')}</Typography>
              </Box>
              <Box className={classes.apiInfoWrapper}>
                <Box className={classes.apiInfoItems}>
                  <Typography variant='h4' className={classes.primary} style={{ wordBreak: 'break-word' }}>
                    {product?.info?.title}
                  </Typography>
                  <Typography variant='h4' className={`${classes.primary} arial`} style={{ wordBreak: 'break-word' }}>
                    &nbsp;{`v.${product?.info?.version}`}
                  </Typography>
                </Box>
                <Box className={classes.apiInfoItems}>
                  {product?.paths?.map((path) => (
                    path?.methods?.map((method) => (
                      <React.Fragment key={path.url + method.method}>
                        <Typography
                          variant='h4'
                          className={classes.primary}
                        >
                          <span>{method.method.toUpperCase()}</span>
                          {' '}
                          <span className={classes.methodUrl}>{path.url}</span>
                        </Typography>
                        <Trans
                          i18nKey='subscriptions.modalRequestsPerSec'
                          values={{limit: 5}}
                          components={[<span className='arial'/>]}
                          parent='h4'
                          className={classes.rateLimit}
                        />
                      </React.Fragment>
                    ))
                  ))}
                </Box>
              </Box>
              <Box className={classes.descriptionWrapper}>
                <Box className={classes.priceWrapper}>
                  <Typography className={classes.priceSubtitle}>{t('products.productPriceSubtitle')}</Typography>
                  <Typography className={classes.price}>
                    <span className='arial'>{product?.basePrice}</span>  SAR
                  </Typography>
                </Box>
              </Box>
            </ContentWrapper>
            {(!roles.includes('admin') || !roles.includes('ibm-admin')) && (
              <Box mt={5}>
                {!!productSubscriptionStatus
                  ? (<Button
                    onClick={() => dispatch(push('/subscriptions'))}
                    id='subscriptions'
                    fullWidth
                    className={classes.subscriptionButton}
                  >
                    {t('products.subscriptions')}
                  </Button>)
                  : (<Button onClick={handleModal} id='subscribe' fullWidth>
                    {t('actions.requestService')}
                  </Button>)
                }
              </Box>
            )}
        </Box>
        }
      </Block>
      {loading && <PagePreloader/>}
    </Layout>
  )
}

export default ProductDetails
