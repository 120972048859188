import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Button, Modal, TextButton } from '@takamol/unified-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ActionIcon from './ActionIcon'
import ErrorIcon from '../modalManager/ErrorIcon'
import SuccessIcon from '../modalManager/SuccessIcon'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { resetState } from '../../store/modalManager'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#cancel': {
      color: theme.UP.content.secondary
    },
  },
  contentWrapper: {
    background: theme.UP.modal.background,
    padding: `${theme.spacing(6)}px ${theme.spacing(7)}px`,
    textAlign: 'center',

    '@media (max-width:375px)': {
      padding: `${theme.spacing(6)}px ${theme.spacing(3)}px`,
    }
  },
  iconWrapper: {
    marginBottom: theme.spacing(4),
  },
  title: {
    color: theme.UP.content.primary,
    marginBottom: theme.spacing(2),
  },
  text: {
    color: theme.UP.content.primary,
    marginBottom: theme.spacing(3),
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  shortBtn: {
    width: '150px',
    margin: '0 auto'
  },
  cancelBtn: {
    marginTop: theme.spacing(4),
    '& > span': {
      color: theme.UP.content.secondary
    }
  }
}))

const icons = {
  confirm: <ActionIcon/>,
  success: <SuccessIcon/>,
  error: <ErrorIcon/>
}

const ModalManager = () => {
  const {
    title,
    text,
    onClose,
    onConfirm,
    open = false,
    confirmBtnText,
    cancelBtnText,
    variant,
    children,
    showIcon,
    maxWidth
  } = useAppSelector(state => state.modalManager)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const handleConfirm = () => {
    dispatch(resetState())
    if (onConfirm) onConfirm()
  }

  const handleClose = () => {
    dispatch(resetState())
    if (onClose) onClose()
  }
  return (
    <Modal open={open} onClose={handleClose} maxWidth={maxWidth}>
      <div>
        {variant !== 'custom'
          ? <Box className={classes.contentWrapper}>
            {showIcon && <Box className={classes.iconWrapper}>
              {icons[variant]}
            </Box>}
            <Typography className={classes.title} variant='h1'>{title}</Typography>
            <Typography className={classes.text} variant='h4'>{text}</Typography>
            <Box className={classes.buttonsWrapper}>
              <Button
                  id='confirmBtn'
                  className={variant === 'success' ? classes.shortBtn : ''}
                  onClick={handleConfirm}
              >
                {confirmBtnText || t('buttons.ok')}
              </Button>
              {variant === 'confirm' && <TextButton id='confirmBtn' className={classes.cancelBtn} onClick={handleClose}>
                {cancelBtnText || t('buttons.cancel')}
              </TextButton>}
            </Box>
          </Box>
          : children ? children : null
        }
      </div>
    </Modal>
  );
};

export default ModalManager;
