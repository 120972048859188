import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Preloader } from '@takamol/unified-components'

const useStyles = makeStyles((theme)=>({
  blockLoaderWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 12,
    background: theme.UP.background.paper
  }
}))

const BlockLoader = () => {
  const classes = useStyles()
  return (
    <div className={classes.blockLoaderWrapper}>
      <Preloader/>
    </div>
  );
};

export default BlockLoader;
