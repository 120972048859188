import React, { useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { useLocation, useHistory } from 'react-router-dom'
import logo from '../../assets/images/logo-alt.svg'
import imageBannerTop from '../../assets/images/banner-top.png'
import imageGettingStarted from '../../assets/images/getting-started.png'
import imageContact from '../../assets/images/contact-us.png'
import iconDataquality from '../../assets/images/icon-dataquality.svg'
import iconDocumentation from '../../assets/images/icon-documentation.svg'
import iconIntegration from '../../assets/images/icon-integration.svg'
import iconSecurity from '../../assets/images/icon-security.svg'
import iconEmail from '../../assets/images/icon-email.svg'
import heroDecor from '../../assets/images/banner-element-desktop.svg'
import LanguageSwitcher from '../../components/shared/LanguageSwitcher'
import Card from '../../components/products/Card'
import { PRODUCTS } from '../../constants/landing'
import { useAppDispatch, useAppSelector } from '../../store/root'
import logoutIconDark from '../../assets/images/logoutIconDark.svg'
import logoutIcon from '../../assets/images/logoutIcon.svg'
import { showModal } from '../../store/modalManager'
import { getRedirectionUrl , logout } from '../../store/auth'
import getEnvVar from '../../utils/getEnvVar'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 10,
    color: '#fff',
    padding: `${theme.spacing(2)}px 0`,
  },
  logo: {
    width: 99,
    height: 'auto',
    marginRight: 'auto',
    '@media (min-width: 768px)': {
      width: 161,
    },
  },
  buttonPrimary: {
    background: theme.UP.controls.button.background.default,
    color: '#fff',
    minWidth: 98,
    textTransform: 'none',
    minHeight: 48,
    borderRadius: 8,
    boxShadow: '0px 10px 20px rgba(21, 130, 133, 0.1)',
    transition: theme.transitions.create(
     ['box-shadow', 'color', 'background-color', 'border-color', 'opacity'],
    {duration: theme.transitions.duration.complex},
    ),
    '@media (min-width: 768px)': {
      minWidth: 150,
    },
    "&:hover": {
      background: theme.UP.controls.button.background.default,
      color: '#fff',
      opacity: 0.85,
      boxShadow: 'none',
    },
    "&.btn-contact": {
      minWidth: 260,
      marginTop: 24,
    },
    '&.btn-sign-up': {
      minHeight: 40,
      '@media (min-width: 551px)': {
        minHeight: 42,
      },
    },
  },
  buttonText: {
    textTransform: 'none',
    color: 'inherit',
    "&:hover": {
      background: 'transparent',
      color: 'inherit',
      opacity: 0.85,
    },
    '&.btn-login': {
      margin: '0 12px',
      '@media (min-width: 768px)': {
        margin: '0 24px',
      }
    },
  },
  buttonWrap: {
    '@media (min-width: 551px)': {
      maxWidth: 309,
    },
  },
  logout: {
    background: theme.UP.background.paper,
    color: theme.UP.content.primary,
    border: `1px solid ${theme.UP.content.border}`,
    minWidth: 120,
    height: 42,
    textTransform: "unset",
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.UP.background.paper,
      color: theme.UP.content.primary,
      borderColor: theme.UP.content.border,
      opacity: 0.85,
    },
    '@media (max-width: 550px)': {
      minWidth: 'unset',
      width: '40px',
      height: '40px',
      padding: 0,
      marginRight: 0,
      '& h5': {
        display: 'none'
      }
    }
  },
  logoutIcon: {
    marginLeft: theme.spacing(1),
    '@media (max-width: 550px)': {
      margin: 0
    }
  },
  sectionHero: {
    padding: `140px 0 60px`,
    overflow: 'hidden',
    background: 'linear-gradient(to top, #08113D 0%, #1E2872 100%) no-repeat',
    fontSize: 16,
    color: '#fff',
    position: 'relative',
    zIndex: 1,
    '@media (min-width: 1440px)': {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      justifyContent: 'center',
      minHeight: 646,
    },
    '&:before': {
      position: 'absolute',
      left: 0,
      top: 130,
      width: 81,
      height: 100,
      background: `url(${heroDecor}) no-repeat`,
      zIndex: -1,
      '@media (min-width: 1440px)': {
        content: '""',
      },
    },
    '& h1': {
      fontSize: 40,
      lineHeight: 1.25,
      fontWeight: 'normal',
      margin: '0 0 0.75em'
    },
    '& img': {
      maxWidth: '100%',
    }
  },
  sectionContent: {
    padding: `${theme.spacing(10)}px 0`,
    overflow: 'hidden',
    fontSize: 16,
    background: '#FAFAFA',
    color: theme.UP.content.primary,
    '@media (min-width: 1440px)': {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      justifyContent: 'center',
      minHeight: 547,
    },
    '&.style-dark': {
      background: 'linear-gradient(to top, #08113D 0%, #1E2872 100%) no-repeat',
      color: '#fff',
      '@media (min-width: 1440px)': {
        minHeight: 609,
      },
      '& h1, & h2, & h3': {
        color: 'inherit',
      },
    },
    '& h1': {
      fontSize: 40,
      lineHeight: 1.25,
      fontWeight: 'bold',
      margin: '0 0 0.75em',
      color: theme.UP.content.primary,
    },
    '& h2': {
      fontSize: 24,
      lineHeight: 1.25,
      fontWeight: 'bold',
      margin: '0 0 1.5em',
      color: theme.UP.content.primary,
      '@media (min-width: 768px)': {
        fontSize: 32,
      },
    },
    '& h3': {
      fontSize: 18,
      lineHeight: 1.67,
      fontWeight: 'bold',
      margin: '0 0 5px',
      color: theme.UP.content.primary,
    },
    '& img': {
      maxWidth: '100%',
    }
  },
  container: {
    maxWidth: 1128,
    padding: '0 10px',
    '@media (min-width: 768px)': {
      padding: '0 24px',
    },
  },
  dataBlock: {
    maxWidth: 195,
    margin: '20px auto',
    fontSize: 14,
    lineHeight: 1.25,
    textAlign: 'center',
    color: theme.UP.content.primary,
  },
  iconWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
    margin: '0 auto 30px',
    background: '#F2F4FF',
    borderRadius: '10px 0 10px 0',
    boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.09)',
  },
  listItem: {
    padding: 0,
    marginTop: 25,
    fontWeight: 'normal',
    '&:last-child': {
      marginBottom: 0,
    }
  },
  listIcon: {
    background: '#fff',
    color: '#2d2e33',
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24,
    fontSize: 14,
    marginTop: 2,
    fontWeight: 'bold',
    lineHeight: '26px',
    textAlign: 'center',
    justifyContent: 'center',
    marginRight: 27,
    borderRadius: '50%',
  },
  listBullets: {
    '& li': {
      position: 'relative',
      padding: '0 0 20px 20px',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 5,
        width: 8,
        height: 8,
        background: '#cfd2e3',
        borderRadius: '50%',
      },
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  scrollableWrapper: {
    minWidth: '100%',
    overflow: 'hidden',
    margin: '0 -10px',
    '@media (min-width: 768px)': {
      margin: '0 -24px',
    },
    '& .scrollable-area': {
      marginBottom: '-20px',
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      padding: '0 24px',
      '@media (max-width: 1023px)': {
        padding: '0 0 0 15vw'
      },
    }
  },
  planCardWrap: {
    minWidth: 252,
    display: 'flex',
    '@media (max-width: 768px)': {
      '&:last-child': {
        marginRight: '15vw'
      },
    },
  },
  planCard: {
    background: '#fff',
    width: '100%',
    marginBottom: 50,
    overflow: 'hidden',
    boxShadow: '0px 15px 18px rgba(51, 70, 129, 0.08)',
    borderTop: '5px solid',
    borderRadius: 6,
    '&.basic': {
      borderTopColor: '#149785',
    },
    '&.enterprise': {
      borderTopColor: '#6063CC',
    },
    '&.advanced': {
      borderTopColor: '#6CC8F9',
    },
    '&.government': {
      borderTopColor: '#EE8F62',
    },
    '& h3': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 60,
      fonSize: '20px !important',
      lineHeight: 1.35,
      fontWeight: 'bold',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 1,
        background: 'linear-gradient(90deg, rgba(233, 237, 243, 0) 0%, #E9EDF3 19.21%, #E9EDF3 81.44%, rgba(233, 237, 243, 0) 100%)',
      },
    },
  },
  cardsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '100%',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    '@media (min-width: 1440px)': {
      margin: `${theme.spacing(3)}px -12px 0`
    },
    '& > *': {
      padding: theme.spacing(3),
      maxWidth: 'calc(33.3334% - 11px)',
      width: 'calc(33.3334% - 11px)',
      '@media (max-width: 1439px)': {
        maxWidth: 'calc(50% - 8px)',
        width: 'calc(50% - 8px)',
      },
      '@media (max-width: 767px)': {
        maxWidth: '100%',
        width: '100%',
      },
      '& h2': {
        fontSize: 20,
        marginBottom: '1em',
      },
    },
  },
  contactGrid: {
    marginTop: '-80px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  contactLink: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'top',
    marginTop: 16,
    fontWeight: 'normal',
    color: theme.UP.content.secondary,
    '& img': {
      marginRight: '0.75em',
    },
  },
  footer: {
    fontSize: 14,
    padding: `${theme.spacing(7)}px 0`,
    background: 'linear-gradient(to top, #08113D 0%, #1E2872 100%) no-repeat',
    color: '#fff',
    '@media (max-width: 768px)': {
      padding: `${theme.spacing(5)}px 0`,
    },
    '& a': {
      color: 'inherit',
    },
    '& *': {
      fontSize: 'inherit',
    },
  },
  languageSwitcher: {
    marginLeft: 24,
    '@media (min-width: 768px)': {
      marginLeft: 32,
    },
    '& #changeLanguage:not([aria-controls="menu-list-grow"])': {
      background: 'transparent',
      color: 'inherit',
      borderColor: '#45474B',
    },
    '& [aria-controls="menu-list-grow"]#changeLanguage': {
      background: '#fff',
      borderColor: '#E9EDF3',
    },
  },
  gutterBottom: {
    marginBottom: '0.75em !important',
  },
}))

const Landing = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const { search } = useLocation()
  const history = useHistory()
  const { theme, lang, nationalId } = useAppSelector(state => state.user)

  const handleSignUp = () => {
    window.location.href = `${getEnvVar('REACT_APP_AUTH_SPA')}/${lang}/registration`
  }

  const handleLogin = () => {
    window.location.href = `${getEnvVar('REACT_APP_AUTH_SPA')}/${lang}/authorise`
  }

  const handlePush = (id: number) => {
    dispatch(push(`/demo-products/${id}`))
  }

  const handleScrollTo = (targetId: string, withAnim: boolean) => {
    const targetBlock = document.querySelector(`#${targetId}`) as HTMLDivElement
    if (targetBlock) {
      if (withAnim) {
        window.scrollTo({
          top: targetBlock?.offsetTop,
          behavior: 'smooth',
        })
      } else {
        window.scrollTo({
          top: targetBlock?.offsetTop,
        })
      }

    }
  }

  const handleLogoutModal = () => {
    dispatch(showModal({
      variant: 'confirm',
      title: t('changeCompanyProfile.modalTitle'),
      text: t('common.logoutConfirmText'),
      showIcon: false,
      onConfirm: () => {
        dispatch(logout()).then(() => dispatch(getRedirectionUrl()))
      }
    }))
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    const scrollToTarget = queryParams.get('scrollToTarget')
    if (scrollToTarget) {
      handleScrollTo(scrollToTarget, false)
      setTimeout(() => {
        queryParams.delete('scrollToTarget')
        history.replace({
          search: queryParams.toString()
        })
      }, 100)
    }
  }, [])

  return (
    <>
      <Box
        component="header"
        className={classes.header}
      >
        <Container maxWidth={'lg'} className={classes.container}>
          <Box display="flex" alignItems="center">
            <img src={logo} className={classes.logo} alt="logo"/>
            {nationalId ? (
              <Button id='logout-btn' onClick={handleLogoutModal} className={classes.logout} variant='outlined'>
                <Typography variant='h5'>
                  {t('common.logout')}
                </Typography>
                <img className={classes.logoutIcon} src={theme === 'dark' ? logoutIconDark : logoutIcon} alt="logout"/>
              </Button>
            ) : (
              <>
                <Button
                  variant="text"
                  className={`${classes.buttonText} btn-login`}
                  disableRipple={true}
                  disableFocusRipple={true}
                  disableTouchRipple={true}
                  disableElevation={true}
                  onClick={handleLogin}
                >
                  {t('actions.login')}
                </Button>
                <Button
                  variant="contained"
                  className={`${classes.buttonPrimary} btn-sign-up`}
                  disableRipple={true}
                  disableFocusRipple={true}
                  disableTouchRipple={true}
                  disableElevation={true}
                  onClick={handleSignUp}
                >
                  {t('actions.signUp')}
                </Button>
              </>
            )}
            <Box className={classes.languageSwitcher}>
              <LanguageSwitcher />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.sectionHero}>
        <Container maxWidth={'lg'} className={classes.container}>
          <Grid container spacing={4} alignItems="center">
            <Grid item md={5}>
              <Typography variant="h1">
                {t('landing.heroTitle')}
              </Typography>
              <Typography variant='inherit' component='p'>
                {t('landing.heroText')}
              </Typography>
              <Box mt={3} className={classes.buttonWrap}>
                <Button
                  fullWidth
                  id='confirm'
                  type='button'
                  className={classes.buttonPrimary}
                  onClick={() => handleScrollTo('api-products', true)}
                >
                  {t('actions.exploreProducts')}
                </Button>
              </Box>
            </Grid>
            <Grid item md={7}>
              <img
                src={imageBannerTop}
                loading="lazy"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.sectionContent}>
        <Container maxWidth={'lg'} className={classes.container}>
          <Typography variant="h2" align="center">
            {t('landing.featuresTitle')}
          </Typography>
          <Grid container spacing={2} alignItems="flex-start" justify="center">
            <Grid item xs={12} sm={6} md={3}>
              <Box className={classes.dataBlock}>
                <div className={classes.iconWrap}>
                  <img src={iconIntegration} alt="Easy integration"/>
                </div>
                <Typography variant="h3">
                  {t('landing.featureTitle1')}
                </Typography>
                <Typography variant="inherit">
                  {t('landing.featureText1')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className={classes.dataBlock}>
                <div className={classes.iconWrap}>
                  <img src={iconDataquality} alt="Data quality"/>
                </div>
                <Typography variant="h3">
                  {t('landing.featureTitle2')}
                </Typography>
                <Typography variant="inherit">
                  {t('landing.featureText2')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className={classes.dataBlock}>
                <div className={classes.iconWrap}>
                  <img src={iconDocumentation} alt="Documentation"/>
                </div>
                <Typography variant="h3">
                  {t('landing.featureTitle3')}
                </Typography>
                <Typography variant="inherit">
                  {t('landing.featureText3')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box className={classes.dataBlock}>
                <div className={classes.iconWrap}>
                  <img src={iconSecurity} alt="Security"/>
                </div>
                <Typography variant="h3">
                  {t('landing.featureTitle4')}
                </Typography>
                <Typography variant="inherit">
                  {t('landing.featureText4')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={`${classes.sectionContent} style-dark`}>
        <Container maxWidth={'lg'} className={classes.container}>
          <Grid container spacing={2} alignItems="center" justify="space-between">
            <Grid item md={4}>
              <Typography variant="h2">
                {t('landing.gettingStartedTitle')}
              </Typography>
              <Typography variant='inherit' component='p'>
                {t('landing.gettingStartedSubTitle')}
              </Typography>
              <List>
                <ListItem className={classes.listItem} alignItems="flex-start">
                  <ListItemIcon className={classes.listIcon}>1</ListItemIcon>
                  <ListItemText primary={t('landing.gettingStartedListItem1')} disableTypography />
                </ListItem>
                <ListItem className={classes.listItem} alignItems="flex-start">
                  <ListItemIcon className={classes.listIcon}>2</ListItemIcon>
                  <ListItemText primary={t('landing.gettingStartedListItem2')} disableTypography />
                </ListItem>
                <ListItem className={classes.listItem} alignItems="flex-start">
                  <ListItemIcon className={classes.listIcon}>3</ListItemIcon>
                  <ListItemText primary={t('landing.gettingStartedListItem3')} disableTypography />
                </ListItem>
              </List>
            </Grid>
            <Grid item md={7}>
              <img
                src={imageGettingStarted}
                loading="lazy"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.sectionContent} id='api-products'>
        <Container maxWidth={'lg'} className={classes.container}>
          <Typography variant="h2" align="center">
            {t('products.title')}
          </Typography>
          <Box className={classes.cardsWrapper}>
            {PRODUCTS.map((item) => (
              <Card
                name={lang === 'ar' ? item.nameAr : item.nameEn}
                iconName={`product-icon-${item.id}`}
                description={lang === 'ar' ? item.descriptionAr : item.descriptionEn}
                descriptionMaxLength={150}
                btnText={t('products.productBtn')}
                onClick={() => handlePush(item.id)}
              />
            ))}
          </Box>
        </Container>
      </Box>
      <Box className={classes.sectionContent}>
        <Container maxWidth={'lg'} className={classes.container}>
          <Grid container spacing={2} alignItems="center" className={classes.contactGrid}>
            <Grid item md={5}>
              <img
                src={imageContact}
                loading="lazy"
              />
            </Grid>
            <Grid item md={7}>
              <Typography variant="h2" className={classes.gutterBottom}>
                {t('landing.contactTitle')}
              </Typography>
              <Typography gutterBottom={true} variant='inherit' component='p'>
                {t('landing.contactText')}
              </Typography>
              <Link
                href="mailto:info@tiptech.sa "
                className={classes.contactLink}
              >
                <img src={iconEmail} alt="email icon" />
                info@tiptech.sa
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.footer}>
        <Container maxWidth={'lg'} className={classes.container}>
          <Typography variant='inherit' component='p'>Takamol. Copyrights 2021. All rights reserved.</Typography>
        </Container>
      </Box>
    </>
  )
}

export default Landing
