import React from 'react';
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import CopyField from '../shared/CopyField'
import GrayAccordion from './GrayAccordion'
import SyntaxHighlighter from '../shared/SyntaxHighlighter'
import { Parameter, Response } from '../../store/product'
import classNames from 'classnames'

const mockedBody = [
  {
    title: 'body',
    value: 'CalculateAvailableVisasRq',
    type: 'required'
  },
]

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary
  },
  badge: {
    width: 'fit-content',
    height: 'fit-content',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    border: `1px solid #E69954`,
    background: theme.UP.type === 'light'
      ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #E69954'
      : 'linear-gradient(0deg, rgba(26, 38, 50, 0.9), rgba(26, 38, 50, 0.9)), #E69954',
    color: theme.UP.content.primary,
    borderRadius: 4,
    textTransform: "uppercase",
    marginRight: theme.spacing(2),
    '@media (max-width:550px)': {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    }
  },
  tabContentWrapper: {
    maxWidth: 600,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
  },
  paramsWrapper: {
    maxWidth: 600,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
    '@media (max-width:550px)': {
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(3),
    }
  },
  type: {
    textTransform: 'capitalize'
  },
  in: {
    marginLeft: theme.spacing(1),
    color: '#3ABBC1',
  },
  required: {
    marginLeft: theme.spacing(1),
    color: theme.UP.content.error,
  },
  headersWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
    '@media (max-width:550px)': {
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(2),
    }
  },
  accordionTitleWrapper: {
    display: 'flex',
    minHeight: 0,
    '@media (max-width:550px)': {
      flexDirection: 'column',
    },
    '& h3': {
      lineHeight: 1.7,
    },
  },
}))

type RouteInfoProps = {
  url: string
  parameters?: Parameter[]
  requestBody?: any
  response: Response[]
}

const RouteInfo = (props: RouteInfoProps) => {
  const { url, parameters, requestBody, response } = props
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <Box mt={3} mb={2}>
        <Typography variant='h3' className={classes.primary}>
          {t('products.endpoint')}
        </Typography>
      </Box>
      <Box>
        <Typography variant='subtitle1' className={classes.primary}>
          {/*TODO*/}
          Production:
        </Typography>
        <Box maxWidth={620} mt={1}>
          <CopyField value={url}/>
        </Box>
      </Box>
      <Box mt={4}>
        <Typography variant='h3' className={classes.primary}>
          {t('products.parameters')}
        </Typography>
        {parameters && <Box my={2} className={classes.paramsWrapper}>
          {parameters?.map((item) => (
            <React.Fragment key={item.name}>
              <Box>
                <Box display='flex'>
                  <Typography variant='subtitle1' className={classes.primary}>{item.name}</Typography>
                  {item.required && <Typography variant='subtitle2' className={classes.required}>
                      *{t('products.required')}
                  </Typography>}
                </Box>
                <Box display='flex' mt={2}>
                  <Typography variant='subtitle2' className={classNames([ classes.primary, classes.type ])}>
                    {item.type}
                  </Typography>
                  <Typography variant='subtitle2' className={classNames([ classes.primary, classes.in ])}>
                    ({item.in})
                  </Typography>
                </Box>
              </Box>
              <Typography variant='subtitle2' className={classes.primary}>{item.description}</Typography>
            </React.Fragment>
          ))}
        </Box>}
        {requestBody && <Box my={2}>
            <GrayAccordion title='Body'>
                <Box className={classes.tabContentWrapper}>
                  {mockedBody.map((item, index) => (
                    <React.Fragment key={index}>
                      <Box>
                        <Box mb={0.5}>
                          <Typography variant='subtitle2' className={classes.primary}>{item.title}</Typography>
                        </Box>
                        <Typography variant='subtitle1' className={classes.primary}>{item.type}</Typography>
                      </Box>
                      <Box>
                        <Typography variant='subtitle2' className={classes.primary}>{item.value}</Typography>
                      </Box>
                    </React.Fragment>
                  ))}
                </Box>
                <Box mt={3}>
                    <SyntaxHighlighter code={`
{
  "data": {
    "type": "request_review",
    "attributes": {
      "license_request_id": 0,
      "request_review": {
        "status": "rejected",
        "rejection_reason": "string"
      },
      "establishment_performance_details": {
        "violations": 1,
        "rating": "10.4",
        "complains": 1,
        "type": "recruitment_office"
      }
    }
  }
}
              `}/>
                </Box>
            </GrayAccordion>
        </Box>}
      </Box>
      {/*<Box mt={4}>*/}
      {/*  <Typography variant='h4' className={classes.primary}>*/}
      {/*    {t('products.exampleRequest')}*/}
      {/*  </Typography>*/}
      {/*  <Box mt={3} maxWidth={260}>*/}
      {/*    /!*TODO*!/*/}
      {/*    <Select*/}
      {/*      fullWidth*/}
      {/*      renderInput={(params) => <Input {...params} name='language' id='language'/>}*/}
      {/*      options={[ 'Python', 'JS' ]}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      <Box mt={4}>
        <Typography variant='h3' className={classes.primary}>
          {t('products.responses')}
        </Typography>
        <Box mt={3}>
          {response?.map((item) => (
            <Box key={item.status} mb={2}>
              <GrayAccordion title={<Box className={classes.accordionTitleWrapper}>
                <Box className={classes.badge}>
                  <Typography variant='h6' className='arial'>{item.status}</Typography>
                </Box>
                <Typography variant='h3' className={classes.primary}>
                  {item.description}
                </Typography>
              </Box>}>
                {item.schema?.properties && <Box mb={3}>
                    <Box mb={3}>
                        <Typography variant='h3' className={classes.primary}>{t('products.body')}</Typography>
                    </Box>
                    <SyntaxHighlighter code={JSON.stringify(item.schema.properties, null, 2)}/>
                </Box>}
                {item.headers && <Box mb={3}>
                    <Box mb={3}>
                        <Typography variant='h3' className={classes.primary}>{t('products.headers')}</Typography>
                    </Box>
                    <Box className={classes.headersWrapper}>
                      {item.headers.map((item) => (
                        <React.Fragment key={item.name}>
                          <Box>
                            <Box mb={0.5}>
                              <Typography variant='subtitle2' className={classes.primary}>
                                {t('products.name')}
                              </Typography>
                            </Box>
                            <Typography variant='subtitle1' className={classes.primary}>
                              {item.name}
                            </Typography>
                          </Box>
                          <Box>
                            <Box mb={0.5}>
                              <Typography variant='subtitle2' className={classes.primary}>
                                {t('products.type')}
                              </Typography>
                            </Box>
                            <Typography variant='subtitle1' className={classes.primary}>
                              {item.type}
                            </Typography>
                          </Box>
                          <Box>
                            <Box mb={0.5}>
                              <Typography variant='subtitle2' className={classes.primary}>
                                {t('products.description')}
                              </Typography>
                            </Box>
                            <Typography variant='subtitle1' className={classes.primary}>
                              {item.description}
                            </Typography>
                          </Box>
                        </React.Fragment>
                      ))}
                    </Box>
                </Box>}
              </GrayAccordion>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default RouteInfo;
