import React, { useEffect, useMemo } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { createGenerateClassName } from '@material-ui/core'
import i18nInstance from '../localization/i18n'
import { Direction, jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { RootState, useAppDispatch, useAppSelector } from '../store/root'
import { createUpTheme, UPProvider } from '@takamol/unified-components'
import PermissionManager from './shared/PermissionManager'
import ModalManager from './modalManager/ModalManager'
import theme from '../theme'
import { SUBSCRIPTIONS, PRODUCTS, PRODUCT, WORKSPACES, SUBSCRIPTION, PROFILE } from '../constants/routes'
import AdminSubscriptions from '../pages/subscriptions/AdminSubscriptions'
import IbmAdminSubscriptions from '../pages/subscriptions/IbmAdminSubscriptions'
import Products from '../pages/products/Products'
import ProductDetails from '../pages/products/ProductDetails'
import Workspaces from '../pages/workspaces/Workspaces'
import UserSubscriptions from '../pages/subscriptions/UserSubscriptions'
import Profile from '../pages/profile/Profile'
import Summary from '../pages/subscriptions/Summary'

const generateClassName = createGenerateClassName({
  productionPrefix: "prod",
  seed: "core",
})
const jss = create({ plugins: [ ...jssPreset().plugins, rtl() ] })

const App = ({ children }: any) => {
  const { i18n } = useTranslation()
  const { lang } = useAppSelector((state: RootState) => state.user)
  const themeMode = useAppSelector((state: RootState) => state.user.theme)
  const dispatch = useAppDispatch()
  const dir: Direction = useMemo(() => lang === 'ar' ? 'rtl' : 'ltr', [ lang ])
  theme.UP.type = themeMode
  theme.UP.seed = 'core'
  let themeDirectionAndMode = Object.assign({ direction: dir }, theme)

  const zendeskOptions = () => {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        color: {
          theme: '#0d91b8',
          launcherText: '#fff'
        },
        offset: {
          vertical: '40px'
        },
        position: {
          horizontal: lang === 'en' ? 'left' : 'right'
        }
      }
    })
    window.zE('webWidget', 'setLocale', lang === 'en' ? 'en-uk' : 'ar',)
    window.zE('webWidget', 'hide')
  }

  const zendeskInit = () => {
    const url = "https://static.zdassets.com/ekr/snippet.js?key=eaca008d-639a-4ae6-a9b6-2f878b47dc3a"
    const zendeskScript = document.createElement('script')
    zendeskScript.id = 'ze-snippet'
    document.body.appendChild(zendeskScript)
    zendeskScript.onload = () => zendeskOptions()
    zendeskScript.src = url
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
    document.body.setAttribute('dir', dir)
    themeDirectionAndMode = Object.assign({ direction: dir }, theme)
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        position: {
          horizontal: lang === 'en' ? 'left' : 'right'
        }
      })
      window.zE('webWidget', 'setLocale', lang === 'en' ? 'en-uk' : 'ar')
    }
  }, [ i18n, lang, dir ])

  const actionTheme = createUpTheme(themeDirectionAndMode)

  return (
    <ThemeProvider theme={actionTheme}>
      <StylesProvider generateClassName={generateClassName} jss={jss}>
        <CssBaseline/>
        <UPProvider value={actionTheme}>
          <I18nextProvider i18n={i18nInstance}>
            <PermissionManager routes={[
              {
                path: SUBSCRIPTIONS,
                permissions: [ 'admin' ],
                data: (): any => {
                  // TODO: dispatch data to preload and speedup our app.
                  // Example: return dispatch(someAction)
                  return true
                },
                rules: (data: any): boolean => {
                  // TODO: create boolean logic with data received in previous hook
                  return true
                },
                component: AdminSubscriptions
              },
              {
                path: SUBSCRIPTIONS,
                permissions: [ 'ibm-admin' ],
                data: (): any => {
                  // TODO: dispatch data to preload and speedup our app.
                  // Example: return dispatch(someAction)
                  return true
                },
                rules: (data: any): boolean => {
                  // TODO: create boolean logic with data received in previous hook
                  return true
                },
                component: IbmAdminSubscriptions
              },
              {
                path: SUBSCRIPTIONS,
                permissions: [ 'user' ],
                data: (): any => {
                  // TODO: dispatch data to preload and speedup our app.
                  // Example: return dispatch(someAction)
                  return true
                },
                rules: (data: any): boolean => {
                  // TODO: create boolean logic with data received in previous hook
                  return true
                },
                component: UserSubscriptions
              },
              {
                path: SUBSCRIPTION,
                permissions: [ 'user' ],
                data: (): any => {
                  // TODO: dispatch data to preload and speedup our app.
                  // Example: return dispatch(someAction)
                  return true
                },
                rules: (data: any): boolean => {
                  // TODO: create boolean logic with data received in previous hook
                  return true
                },
                component: Summary
              },
              {
                path: PRODUCTS,
                permissions: [ 'user' ],
                data: (): any => {
                  // TODO: dispatch data to preload and speedup our app.
                  // Example: return dispatch(someAction)
                  return true
                },
                rules: (data: any): boolean => {
                  // TODO: create boolean logic with data received in previous hook
                  return true
                },
                component: Products
              },
              {
                path: PRODUCT,
                permissions: [ 'user' ],
                data: (): any => {
                  // TODO: dispatch data to preload and speedup our app.
                  // Example: return dispatch(someAction)
                  return true
                },
                rules: (data: any): boolean => {
                  // TODO: create boolean logic with data received in previous hook
                  return true
                },
                component: ProductDetails
              },
              {
                path: WORKSPACES,
                permissions: [ 'user' ],
                data: (): any => {
                  // TODO: dispatch data to preload and speedup our app.
                  // Example: return dispatch(someAction)
                  return true
                },
                rules: (data: any): boolean => {
                  // TODO: create boolean logic with data received in previous hook
                  return true
                },
                component: Workspaces
              },
              {
                path: PROFILE,
                permissions: [],
                data: (): any => {
                  // TODO: dispatch data to preload and speedup our app.
                  // Example: return dispatch(someAction)
                  return true
                },
                rules: (data: any): boolean => {
                  // TODO: create boolean logic with data received in previous hook
                  return true
                },
                component: Profile
              }
            ]}/>
            <ModalManager/>
          </I18nextProvider>
        </UPProvider>
      </StylesProvider>
    </ThemeProvider>
  )
}

export default App;
