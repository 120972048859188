import React from 'react';
import { Box, Typography } from '@material-ui/core'
import Divider from './Divider'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary
  },
  contentWrapper: {
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 8,
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(5)}px`,
    '@media (max-width: 768px)': {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(5)}px`,
    },
    '@media (max-width: 550px)': {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(5)}px`,
    }
  }
}))

type ContentWrapperProps = {
  title: string
  showDivider?: boolean,
  bordered?: boolean
}

const ContentWrapper: React.FC<ContentWrapperProps> = (props) => {
  const { title, showDivider = true, bordered = true, children } = props
  const classes = useStyles()
  return (
    <Box className={bordered ? classes.contentWrapper : ''}>
      <Typography variant='h2' className={classes.primary}>{title}</Typography>
      {showDivider && <Box my={3}>
        <Divider/>
      </Box>}
      {children}
    </Box>
  );
};

export default ContentWrapper;
