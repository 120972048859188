import React from 'react';
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NoFound } from "@takamol/unified-components";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  noFoundWrapper: {
    padding: `${theme.spacing(16)}px ${theme.spacing(5)}px ${theme.spacing(10)}px`,
    textAlign: 'center'
  }
}));

interface INoFoundOverlay {
  title?: string;
  text?: string;
}

const NoFoundOverlay = (props: INoFoundOverlay) => {
  const { title, text } = props
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box className={classes.noFoundWrapper}>
      <NoFound title={title || t('common.noDataTitle')} text={text || t('common.noDataText')}/>
    </Box>
  );
}

export default NoFoundOverlay
