import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { ReactComponent as ExpandIcon } from '../../assets/images/expand-icon.svg'
import Divider from '../shared/Divider'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    background: theme.UP.background.paper,
    boxShadow: 'none',
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 8,
    '&.musaned-MuiAccordion-root ': { marginBottom: theme.spacing(1) },
    '&.musaned-MuiAccordion-root.Mui-expanded': { marginTop: theme.spacing(1) },
    '&:before': { display: 'none' }
  },
  accordionSummaryRoot: {
    display: 'flex',
    gap: theme.spacing(3),
    margin: 0,
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    boxShadow: 'none',
    minHeight: 'unset',
    '@media (max-width: 760px)': { padding: theme.spacing(3) },
    '@media (max-width: 550px)': { padding: theme.spacing(2) },
  },
  accordionContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: 0,
    '&.Mui-expanded': { margin: 0 }
  },
  accordionDetailsRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(2)}px`,
    '@media (max-width: 760px)': { padding: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px` },
    '@media (max-width: 550px)': { padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px` },
  },
  expandIconWrapper: {
    opacity: 1,
    padding: 0,
    margin: 0,
    transition: '0.3s opacity',
  },
}))

type RouteAccordionProps = {
  expanded: boolean
  onChange: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void
  title: React.ReactNode | string
}

const RouteAccordion:React.FC<RouteAccordionProps> = (props) => {
  const { expanded, onChange, title, children } = props
  const classes = useStyles()
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      classes={{ root: classes.accordionRoot }}
    >
      <AccordionSummary
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionContent,
          expandIcon: classes.expandIconWrapper
        }}
        expandIcon={<ExpandIcon/>}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        <Divider/>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default RouteAccordion;
