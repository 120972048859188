import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core'
import { ReactComponent as ExpandIcon } from '../../assets/images/expand-icon.svg'
import Divider from '../shared/Divider'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary,
  },
  accordionRoot: {
    background: theme.UP.content.background,
    boxShadow: 'none',
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 8,
    '&.musaned-MuiAccordion-root ': { marginBottom: theme.spacing(1) },
    '&.musaned-MuiAccordion-root.Mui-expanded': { marginTop: theme.spacing(1) },
    '&:before': { display: 'none' }
  },
  accordionSummaryRoot: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    boxShadow: 'none',
    minHeight: 'unset !important',
    '@media (max-width:550px)': {
      padding: theme.spacing(2),
    },
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
  accordionContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: 0,
    '&.Mui-expanded': { margin: 0 }
  },
  accordionDetailsRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px`,
    '@media (max-width:550px)': {
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },
  expandIconWrapper: {
    opacity: 1,
    padding: 0,
    margin: 0,
    transition: '0.3s opacity',
  },
}))

const GrayAccordion: React.FC<{ title: React.ReactNode | string }> = ({ title, children }) => {
  const classes = useStyles()
  const hasChildren = !!React.Children.toArray(children).length
  return (
    <Accordion
      classes={{ root: classes.accordionRoot }}
    >
      <AccordionSummary
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionContent,
          expandIcon: classes.expandIconWrapper
        }}
        disabled={!hasChildren}
        expandIcon={hasChildren ? <ExpandIcon/> : null}
      >
        <Typography variant='h4' className={classes.primary} >
          {title}
        </Typography>
      </AccordionSummary>
      {hasChildren &&
      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        <Box mb={3}>
          <Divider/>
        </Box>
        {children}
      </AccordionDetails>
      }
    </Accordion>
  );
};

export default GrayAccordion;
