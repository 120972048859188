import { SerializedError } from '@reduxjs/toolkit';
import { Subject } from 'rxjs';

const subject = new Subject<SerializedError>()

// TODO: integration to store frontend errors for analytics
subject.subscribe({
  next: (value) => console.error(value)
})

export const globalErrorService = {
  setError: (error: SerializedError) => setTimeout(() => subject.next(error), 0),
  clearError: () => setTimeout(() => subject.next({}), 0),
  getError: () => subject.asObservable()
}