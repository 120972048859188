import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme)=>({
  divider: {
    width: '100%',
    height: 1,
    background: `${theme.UP.type === 'light'
      ? 'linear-gradient(90deg, rgba(233, 237, 243, 0) 0%, #E9EDF3 19.21%, #E9EDF3 81.44%, rgba(233, 237, 243, 0) 100%)'
      : 'linear-gradient(90deg, rgba(69, 71, 75, 0) 0%, #45474B 19.21%, #45474B 81.44%, rgba(69, 71, 75, 0) 100%)'
    }`
  }
}))

const Divider = () => {
  const classes = useStyles()
  return (
    <div className={classes.divider}/>
  )
}

export default Divider
