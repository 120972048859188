import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CompanyProfileCard, Block } from '@takamol/unified-components'
import { push } from 'connected-react-router'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import { useAppDispatch, useAppSelector } from '../../store/root'
import {
  setLoading,
  setWorkspace,
  setWorkspaceError,
  fetchWorkspaces,
  Workspace
} from '../../store/workspaces'
import Layout from '../../components/Layout'
import { useTranslation } from 'react-i18next'
import { setError, getContext } from '../../store/user'
import { showModal } from '../../store/modalManager'
import { PRODUCTS } from '../../constants/routes'
import ErrorOverlay from "../../components/shared/DataGrid/ErrorOverlay";
import PagePreloader from "../../components/shared/PagePreloader";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.UP.content.background,
    minHeight: '100vh',
    paddingBottom: theme.spacing(5)
  },
  titleWrapper: {
    color: theme.UP.content.primary,
    flexWrap: 'nowrap',
    flexGrow: 1,
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px`,
    '@media (max-width:540px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  badge: {
    padding: `0 ${theme.spacing(2)}px`,
    '@media (max-width:540px)': {
      flexDirection: 'column'
    }
  },
  tabPanelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  workspacesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    margin: '0 auto',
  },
  workspacesList: {
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateColumns: '1fr 1fr 1fr',
    flexWrap: 'wrap',
    marginTop: `${theme.spacing(4)}px`,
    gap: theme.spacing(3),
    "& > div": {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%'
    },
    '@media (max-width:1024px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media (max-width:540px)': {
      gridTemplateColumns: '1fr',
      justifyItems: 'center'
    }
  },
  titleColor: {
    color: theme.UP.content.primary,
  },
  companyProfileTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.UP.content.primary,
    marginTop: 14,
    marginRight: theme.spacing(2),
    wordBreak: 'break-word',
  },
  companyProfileCardBody: {
    display: 'flex',
    flexWrap: 'wrap',
    height: `${theme.spacing(11)}px`,
    padding: `${theme.spacing(3)}px`,
    '& .info-wrapper': {
      '& .info-title': {
        marginBottom: theme.spacing(0.5)
      },
      '& .info-value': {
        color: theme.UP.content.primary
      },
      '& .establishment': {
        color: theme.UP.common.alternativeGreen
      },
      '& .admin': {
        color: '#FE7338'
      }
    }
  },
}))

const Workspaces = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const {
    loading,
    workspaces,
    error,
    workspaceError,
  } = useAppSelector((state) => state.workspaces)
  const { sequenceNumber } = useAppSelector((state) => state.user)

  const isActive = () => {
    return workspaces.some((item: Workspace) => sequenceNumber === item.sequenceNumber.toString())
  }

  const handleActiveWorkspace = (workspace: Workspace) => () => {
    dispatch(setWorkspace(workspace.id)).then(() => {
      dispatch(getContext()).then(() => dispatch(push(PRODUCTS)))
    })
  }

  const handleWorkspace = (workspace: Workspace) => () => {
    dispatch(showModal({
      variant: 'confirm',
      title: t('workspacesTip.confirmModalTitle'),
      text: isActive()
          ? t('workspacesTip.switch', { name: workspace.establishmentName })
          : `${t('workspaces.sure')} ${workspace.establishmentName} ${t('workspacesTip.defaultCompany')}`,
      showIcon: false,
      onConfirm: () => {
        dispatch(setWorkspace(workspace.id))
          .then((res: any) => {
            if(!res.error) {
              dispatch(getContext()).then(() => dispatch(push(PRODUCTS)))
            }
        })
      }
    }))
  }

  const clearError = () => {
    dispatch(setWorkspaceError({
      name: '',
      message: ''
    }))
  }

  const handleRefresh = () => {
    dispatch(setLoading(true))
    dispatch(fetchWorkspaces())
  }

  useEffect(() => {
    if (workspaceError.name || workspaceError.message) {
      dispatch(showModal({
        variant: 'error',
        title: workspaceError.name,
        text: workspaceError.message,
        onClose: clearError,
        onConfirm: clearError
      }))
    }
  }, [workspaceError.name, workspaceError.message])

  return (
      <Layout>
        <Block>
          {loading && <PagePreloader/>}
          <Typography className={classes.titleColor} variant='h2'>{t('workspacesTip.title')}</Typography>
          <Grid className={classes.workspacesWrapper}>
            {workspaces.length
              ?
              <Grid className={classes.workspacesList}>
                {workspaces.map((item: Workspace) => (
                  <CompanyProfileCard
                      classes={{
                        title: classes.companyProfileTitle,
                        titleWrapper: classes.titleWrapper
                      }}
                      id={item.id}
                      key={item.id}
                      name={item.establishmentName}
                      isActive={sequenceNumber === item.sequenceNumber.toString()}
                      defaultBadgeTitle={t('changeCompanyProfile.default')}
                      activeBadgeTitle={t('changeCompanyProfile.active')}
                      onClick={sequenceNumber === item.sequenceNumber.toString()
                                  ? handleActiveWorkspace(item)
                                  : handleWorkspace(item)
                              }
                  >
                    <Grid className={classes.companyProfileCardBody}>
                      <Grid className="info-wrapper">
                        <Grid className="info-title">
                          <Typography className={classes.titleColor} variant='subtitle2'>
                            {t('workspacesTip.estNumber')}
                          </Typography>
                        </Grid>
                        <Grid className="info-value">
                          <Typography variant='subtitle1' className="arial">
                            {item.id}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CompanyProfileCard>
                  )
                )}
              </Grid>
              : <>
                {error.name && error.message ?
                  <ErrorOverlay title={error.name} text={error.message} onRefresh={handleRefresh}/>
                  :
                  <ErrorOverlay title={t('workspacesTip.emptyWorkspaces')} onRefresh={handleRefresh}/>
                }
              </>
            }
          </Grid>
        </Block>
      </Layout>
  );
}

export default Workspaces;
