import { instance } from './axios'

const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config: any) => {
    return config
  }
  const onResponseSuccess = (response: any) => {
    return response
  }
  const onResponseError = (err: any) => {
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      const isPermissionPages = window.location.pathname !== '/' && !window.location.pathname.includes('demo-products')
      if (err?.response?.data?.url && isPermissionPages) {
        window.localStorage.setItem('tip_redirected_from_url', window.location.pathname + window.location.search)
        window.location.href = err?.response?.data?.url
      }
      throw err
    }
    throw err
  }
  instance.interceptors.request.use(onRequestSuccess)
  instance.interceptors.response.use(onResponseSuccess, onResponseError)
}

export default setupAxiosInterceptors
