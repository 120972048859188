import React, { useEffect } from 'react';
import { DatePicker, FiltersWrapper, Input, Select } from '@takamol/unified-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { setFilters, initialFilters, setSortModel } from '../../store/subscriptions'

const statuses = [
  {
    title: 'subscriptions.requested',
    value: 'requested',
  },
  {
    title: 'subscriptions.rejected',
    value: 'rejected',
  },
  {
    title: 'subscriptions.approved',
    value: 'approved',
  },
  {
    title: 'subscriptions.paid',
    value: 'paid',
  },
  {
    title: 'subscriptions.active',
    value: 'active',
  },
  {
    title: 'subscriptions.expired',
    value: 'expired',
  },
]

const useStyles = makeStyles((theme) => ({
  '@global': {
    'label ~ [role="presentation"] *': {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  clearBtn: {
    background: theme.UP.type === 'light'
      ?  `${theme.UP.content.primary} !important`
      : `#4078B7 !important`,
    color: `${theme.UP.common.white} !important`,
    boxShadow: '0px 10px 20px rgba(21, 130, 133, 0.22)',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  },
  select: {
    '& input': {
      fontWeight: 400,
    },
  },
}));

const Filters = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const { filters } = useAppSelector(state => state.subscriptions)
  const { roles } = useAppSelector(state => state.user)
  const isAdmin = roles.includes('admin') || roles.includes('ibm-admin')

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(setFilters({
      ...filters,
      [name]: {
        ...filters[name],
        value,
      }
    }))
  }

  const handleStatus = (e: React.ChangeEvent<{}>, value: any) => {
    dispatch(setFilters({
      ...filters,
      status: {
        ...filters.status,
        value: value ? value.value : null
      }
    }))
  }

  const handleDateFilter = (value: string) => {
    dispatch(setFilters({
      ...filters,
      request_date: {
        ...filters.request_date,
        value: moment(value).format('YYYY-MM-DD')
      }
    }))
  }

  const handleDateClear = () => {
    dispatch(setFilters({
      ...filters,
      request_date: {
        ...filters.request_date,
        value: null,
      }
    }))
  }

  const handleProductNameClear = () => {
    dispatch(setFilters({
      ...filters,
      product: {
        ...filters.product,
        value: null,
      }
    }))
  }

  const handleSubscriptionId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(setFilters({
      ...filters,
      [name]: {
        ...filters[name],
        value: value.replace(/\D+/g, '') || null
      }
    }))
  }

  const handleSubscriptionIdClear = () => {
    dispatch(setFilters({
      ...filters,
      id: {
        ...filters.id,
        value: null,
      }
    }))
  }

  const handleEstablishment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(setFilters({
      ...filters,
      [name]: {
        ...filters[name],
        value: value || null
      }
    }))
    dispatch(setSortModel([]))
  }

  const handleEstablishmentClear = (ref: React.RefObject<HTMLInputElement>) => {
    const { name } = ref.current as HTMLInputElement
    dispatch(setFilters({
      ...filters,
      [name]: {
        ...filters[name],
        value: null,
      }
    }))
  }

  const handleResetFilters = () => {
    dispatch(setFilters(initialFilters))
  }

  useEffect(() => () => {
    handleResetFilters()
  }, [])

  if (isAdmin) return (
    <FiltersWrapper
      showClearBtn
      clearBtnText={t('filtersTip.clearAll')}
      onClear={handleResetFilters}
      classes={{
        clearBtn: classes.clearBtn
      }}
    >
      <Input
        showClear
        onClear={handleProductNameClear}
        fullWidth
        placeholder={t('subscriptions.namePlaceholder')}
        name='product'
        value={filters.product.value || ''}
        id='product'
        onChange={handleName}
        label={t('filtersTip.searchName')}
      />
      <Select
        className={classes.select}
        label={t('filtersTip.filterStatus')}
        id='status'
        onChange={handleStatus}
        value={filters.status?.value}
        inputValue={filters.status?.value ? t(`subscriptions.${filters.status?.value}`) : ''}
        renderInput={params => <Input {...params} name='status'
                                      placeholder={t('workPermit.statusPlaceholder')} id='status'/>}
        options={statuses}
        getOptionLabel={(option: { title: string }) => t(option.title)}
      />
      <DatePicker
        label={t('filtersTip.filterSubmit')}
        hasClear={!!filters.request_date.value}
        onClear={handleDateClear}
        id='request_date'
        name='request_date'
        format="DD/MM/YYYY"
        minDate={new Date('2000-01-01').toString()}
        value={filters.request_date.value}
        onDateChange={(value: string) => handleDateFilter(value)}
        autoOk={false}
      />
      <Input
        showClear
        onClear={handleSubscriptionIdClear}
        fullWidth
        placeholder={t('subscriptions.subscriptionIdPlaceholder')}
        name='id'
        value={filters.id.value || ''}
        id='id'
        onChange={handleSubscriptionId}
        label={t('filtersTip.searchSubscriptionId')}
      />
      {isAdmin && <Input
        showClear
        onClear={handleEstablishmentClear}
        fullWidth
        placeholder={t('subscriptions.establishmentPlaceholder')}
        name='establishment_name'
        value={filters.establishment_name.value || ''}
        id='establishment_name'
        onChange={handleEstablishment}
        label={t('filtersTip.establishmentSearchName')}
      />}
    </FiltersWrapper>
  )

  return (
    <FiltersWrapper
      showClearBtn
      clearBtnText={t('filtersTip.clearAll')}
      onClear={handleResetFilters}
      classes={{
        clearBtn: classes.clearBtn
      }}
    >
      <Input
        showClear
        onClear={handleProductNameClear}
        fullWidth
        placeholder={t('subscriptions.namePlaceholder')}
        name='product'
        value={filters.product.value || ''}
        id='product'
        onChange={handleName}
        label={t('filtersTip.searchName')}
      />
      <Select
        className={classes.select}
        label={t('filtersTip.filterStatus')}
        id='status'
        onChange={handleStatus}
        value={filters.status?.value}
        inputValue={filters.status?.value ? t(`subscriptions.${filters.status?.value}`) : ''}
        renderInput={params => <Input {...params} name='status'
                                      placeholder={t('workPermit.statusPlaceholder')} id='status'/>}
        options={statuses}
        getOptionLabel={(option: { title: string }) => t(option.title)}
      />
      <DatePicker
        label={t('filtersTip.filterSubmit')}
        hasClear={!!filters.request_date.value}
        onClear={handleDateClear}
        id='request_date'
        name='request_date'
        format="DD/MM/YYYY"
        minDate={new Date('2000-01-01').toString()}
        value={filters.request_date.value}
        onDateChange={(value: string) => handleDateFilter(value)}
        autoOk={false}
      />
      <Input
        showClear
        onClear={handleSubscriptionIdClear}
        fullWidth
        placeholder={t('subscriptions.subscriptionIdPlaceholder')}
        name='id'
        value={filters.id.value || ''}
        id='id'
        className='arial'
        onChange={handleSubscriptionId}
        label={t('filtersTip.searchSubscriptionId')}
      />
    </FiltersWrapper>
  )
};

export default Filters;
