import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import $RefParser from "@apidevtools/json-schema-ref-parser"
import { GET_PRODUCT, POST_CHECK_PRODUCT, POST_CREATE_SUBSCRIPTION_REQUEST } from '../constants/api'
import api from '../services/axios'
import getErrorText from '../utils/getErrorText'
import { TProduct } from './products'
import { serializer } from '@takamol/unified-components'
import product1 from '../constants/products/1.json'
import product2 from '../constants/products/2.json'
import product3 from '../constants/products/3.json'
import product4 from '../constants/products/4.json'
import product5 from '../constants/products/5.json'

const mockedProducts = {
  '1': product1,
  "2": product2,
  "3": product3,
  "4": product4,
  "5": product5,
}

export type Parameter = {
  in: string
  name: string
  description: string
  required: boolean
  type: string
  $ref?: string
}

type Header = {
  name: string
  type: string
  description: string
}

export type Response = {
  status: string
  description: string
  headers: Header[] | null
  schema?: {
    type: string
    description: string
    properties: {
      [key: string]: any
    }
  }
}

type Method = {
  method: string
  parameters?: Parameter[]
  description: string
  requestBody?: any
  responses: Response[]
}

type Path = {
  url: string
  methods: Method[]
}

export type Product = TProduct & {
  info: {
    title: string
    description: string
    version: string
  }
  basePath: string
  security: { [key: string]: any }[]
  paths: Path[]
  parameters?: Parameter[],
  securityDefinitions?: any,
}

type ProductState = {
  loading: boolean
  product: Product
  productSubscriptionStatus?: string
  error: string
  createSubscriptionRequestError: string
  createSubscriptionRequestLoading: boolean
}

const stateAdapter = createEntityAdapter()

const initialState = stateAdapter.getInitialState({
  loading: false,
  product: {} as Product,
  error: '',
  createSubscriptionRequestError: '',
  createSubscriptionRequestLoading: false
}) as ProductState

export const fetchProduct = createAsyncThunk(
  'product/fetchProduct',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // @ts-ignore
    await $RefParser.dereference(mockedProducts[id], {
      dereference: {
        circular: 'ignore'
      }
    })
    return await api('get', GET_PRODUCT(id), {}, rejectWithValue)
  }
)

export const checkProduct = createAsyncThunk(
    'product/checkProduct',
    async ({ id }: { id: string }, { rejectWithValue }) => {
      return await api('post', POST_CHECK_PRODUCT(id), {}, rejectWithValue)
    }
)

type CreateSubscriptionRequestProps = {
  productId: number,
  userName: string,
  email: string
}

export const createSubscriptionRequest = createAsyncThunk(
  'product/createSubscriptionRequest',
  async ({ productId, email, userName }: CreateSubscriptionRequestProps, { rejectWithValue }) => {
    const payload = serializer('subscription-request', {
      productId,
      email,
      userName
    })
    return await api('post', POST_CREATE_SUBSCRIPTION_REQUEST, payload, rejectWithValue)
  }
)


const product = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setLoading(state: ProductState, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setCreateSubscriptionRequestLoading(state: ProductState, action: PayloadAction<boolean>) {
      state.createSubscriptionRequestLoading = action.payload
    },
    setError(state: ProductState) {
      state.error = initialState.error
    },
    setCreateSubscriptionRequestError(state: ProductState, action: PayloadAction<string>) {
      state.createSubscriptionRequestError = action.payload || initialState.createSubscriptionRequestError
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProduct.fulfilled, (state, action) => {
        // @ts-ignore
        const productJson = mockedProducts[action.payload.data.id]
        state.product = {
          ...action.payload.data,
          ...productJson,
          paths: Object.keys(productJson.paths).map((item) => {
            // @ts-ignore
            const { parameters, ...rest } = productJson.paths[item]

            return ({
              url: item,
              // @ts-ignore
              methods: Object.keys(rest).map((item) => ({
                method: item,
                parameters: [
                  ...parameters
                ],
                ...rest[item],
                responses: Object.keys(rest[item].responses).map((res) => ({
                  status: res,
                  ...rest[item].responses[res],
                  headers: rest[item].responses[res].headers
                    ? Object.keys(rest[item].responses[res].headers).map((head) => ({
                      name: head,
                      ...rest[item].responses[res].headers[head],
                    }))
                    : null
                }))
              }))
            })
          })
        }
        state.error = initialState.error
        state.loading = false
      })
      .addCase(fetchProduct.rejected, (state, action: any) => {
        state.error = getErrorText(action.payload?.errors?.[0].title)
        state.loading = false
      })
      .addCase(checkProduct.fulfilled, (state, action: any) => {
        state.productSubscriptionStatus = action.payload?.data?.[0]?.status;
        state.error = initialState.error
        state.loading = false
      })
      .addCase(checkProduct.rejected, (state, action: any) => {
        state.error = getErrorText(action.payload?.errors?.[0].title)
        state.loading = false
      })
      .addCase(createSubscriptionRequest.fulfilled, (state) => {
        state.createSubscriptionRequestLoading = false
        state.createSubscriptionRequestError = initialState.createSubscriptionRequestError
      })
      .addCase(createSubscriptionRequest.rejected, (state, action: any) => {
        state.createSubscriptionRequestLoading = false
        state.createSubscriptionRequestError = getErrorText(action.payload?.subscription?.[0])
      })
  }
})

export const reducer: Reducer<typeof initialState> = product.reducer
export const {
  setLoading,
  setError,
  setCreateSubscriptionRequestLoading,
  setCreateSubscriptionRequestError
} = product.actions
