import React from 'react';
import Highlighter from 'react-syntax-highlighter';
import { a11yLight, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useAppSelector } from '../../store/root'

const SyntaxHighlighter = ({ code }: { code: string }) => {
  const { theme } = useAppSelector(state => state.user)
  return (
    <Highlighter style={theme === 'light' ? a11yLight : a11yDark}>
      {code}
    </Highlighter>
  );
};

export default SyntaxHighlighter;
