import React, { useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { Pagination, sortSerializer } from '@takamol/unified-components'
import { Trans, useTranslation } from 'react-i18next'
import { unwrapResult } from '@reduxjs/toolkit'
import moment from 'moment'
import Layout from "../../components/Layout"
import { useAppDispatch, useAppSelector } from "../../store/root"
import { GridAlignment, GridCellParams, GridSortModelParams } from '@material-ui/data-grid'
import {
  checkPayment,
  fetchSubscriptions,
  sendApprove,
  setSortModel,
  setCurrentPage,
  setInit,
  setLoading
} from '../../store/subscriptions'
import DataGridWithOverlays from '../../components/shared/DataGrid/DataGridWithOverlays'
import Filters from '../../components/subscriptions/Filters'
import sleep from '../../utils/sleep'
import { showModal } from '../../store/modalManager'
import getErrorText from '../../utils/getErrorText'
import RejectModal from '../../components/subscriptions/RejectModal'
import useDebounce from '../../hooks/useDebounce'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#product::placeholder,#status::placeholder,#request_date::placeholder,#id::placeholder,#establishment_name::placeholder': {
      color: '#3ABBC1'
    },
    '#request_date':{
      fontFamily: 'Arial !important'
    },
    '[id*="view"] span svg path, [id*="download"] span svg path': {
      fill: `${theme.UP.common.alternativeGreen} !important`
    },
    '[role="tooltip"] > div': {
      fontFamily: 'Almarai, sans-serif',
      fontSize: 12,
      lineHeight: 1.75,
      fontWeight: 'normal',
      padding: theme.spacing(2),
      background: theme.UP.background.paper,
      margin: 0,
      border: `1px solid ${theme.UP.content.border}`,
      color: '#3ABBC1',
      borderRadius: 8,
      boxShadow: '0 15px 18px rgba(51, 70, 129, 0.08)',
      '& > div': {
        font: 'inherit',
        padding: 0,
        background: 'transparent',
        margin: 0,
        border: 'none',
        color: 'inherit',
        boxShadow: 'none',
      }
    },
    '.MuiDataGrid-cell': {
      fontFamily: 'Almarai !important'
    },
    '.MuiAutocomplete-popupIndicator svg': {
      fill: `#3ABBC1 !important`
    }
  },
  subscriptionsWrapper: {
    background: theme.UP.background.paper,
    borderRadius: 24,
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(5)}px`,
    '& .Accepted': {
      color: theme.UP.common.alternativeGreen
    },
    "@media (max-width: 840px)": {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(5)}px`,
    },
    "@media (max-width: 1439px)": {
      '& .MuiDataGrid-viewport': {
        overflow: theme.direction === 'rtl' ? 'visible !important' : 'hidden',
      },
    },
    '& .MuiButton-text': {
      backgroundColor: theme.UP.content.primary,
      color: theme.UP.type === 'light' ? theme.UP.common.white : theme.UP.common.black,
      '&:hover': {
        backgroundColor: theme.UP.content.primary,
        color: theme.UP.type === 'light' ? theme.UP.common.white : theme.UP.common.black,
      }
    },
    '& [role="cell"], & [role="columnheader"]': {
      padding: '14px 8px !important',
      '&:first-child': {
        paddingLeft: '16px !important',
      }
    },
    '& .data-container [role="cell"] .cellValue': {
      whiteSpace: 'normal',
      display: '-webkit-box !important',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      lineHeight: 1.25,
    },
    '& .data-container [role="row"]:hover': {
      background: theme.UP.content.lightGray,
    },
  },
  dataGridWrapper: {
    height: '642px !important',
  },
  viewDetails: {
    color: theme.UP.common.alternativeGreen,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  textCenter: {
    textAlign: 'center',
  },
  requested: {
    color: theme.UP.content.success,
  },
  active: {
    color: theme.UP.content.success,
  },
  paid: {
    color: theme.UP.content.success,
  },
  approved: {
    color: theme.UP.content.success,
  },
  rejected: {
    color: theme.UP.content.error,
  },
  expired: {
    color: theme.UP.content.error,
  },
}))

const filtersSerializer = (data: any) => {
  let filter = '&'
  Object.keys(data).forEach(key => {
    if (data[key].value) {
      filter += `q[${key}]`
      filter += data[key].action ? `[${data[key].action}]=${data[key].value}${data[key].action === 'like' ? '*' : ''}` : `=${data[key].value}`
      filter += '&'
    }
  })
  return filter.substring(0, filter.length - 1)
}

const AdminSubscriptions = () => {
  const showScroll = useMediaQuery('(max-width:1440px)')
  const flexValue = showScroll ? 0 : 0.8
  // @ts-ignore
  const params = (new URL(document.location)).searchParams;
  const id = params.get('id');
  const { t, i18n: { language } } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const {
    pageCount,
    currentPage,
    filters,
    sortModel,
    error,
    loading,
    subscriptions,
    init
  } = useAppSelector(state => state.subscriptions)
  const debouncedFilters = useDebounce(filters,700)

  const handleApprove = (subscriptionId: string) => {
    dispatch(setLoading(true))
    dispatch(sendApprove(subscriptionId))
    .then(unwrapResult)
    .then(() => dispatch(showModal({
      variant: 'success',
      text: t('subscriptions.successfullyApproved')
    })))
    .then(handleRefresh)
    .catch(e => {
      dispatch(showModal({
        variant: 'error',
        title: t('errors.errorName'),
        text: getErrorText(e.subscription[0])
      }))
    })
  }

  const columns = [
    {
      field: 'id',
      headerName: t('subscriptions.id'),
      width: 130,
      flex: flexValue,
      showTooltip: true,
      headerAlign: 'center' as GridAlignment,
      renderCell: (params: GridCellParams) => (
        <Box style={{ textAlign: 'center' }}>
          <b className='arial'>{params.id}</b>
        </Box>
      )
    },
    {
      field: 'productName',
      headerName: t('subscriptions.productName'),
      width: 200,
      flex: flexValue,
      sortable: false,
      showTooltip: true,
      renderCell: (params: GridCellParams) => (
        <>{language === 'ar' ? params.row?.product?.nameAr : params.row?.product?.nameEn}</>
      )
    },
    {
      field: 'userName',
      headerName: t('subscriptions.name'),
      width: 100,
      flex: flexValue,
      showTooltip: true,
    },
    {
      field: 'establishmentName',
      headerName: t('subscriptions.company'),
      width: 205,
      flex: flexValue,
      showTooltip: true,
    },
    {
      field: 'createdAt',
      width: 115,
      headerName: t('subscriptions.creationDate'),
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      renderCell: (params: GridCellParams) => (
        <Typography variant='subtitle2' className="arial">
          {params.value && moment(String(params.value)).format('DD/MM/YYYY')}
        </Typography>
      )
    },
    {
      field: 'productPrice',
      headerName: t('subscriptions.productPrice'),
      width: 110,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <Typography variant='subtitle2' className='arial'>{params.row?.product?.basePrice}</Typography>
          <Typography variant='subtitle2'>&nbsp;SAR</Typography>
        </>
      )
    },
    {
      field: 'status',
      headerName: t('subscriptions.status'),
      width: 100,
      renderCell: (params: GridCellParams) => (
        <Typography
          variant="subtitle2"
          className={classes[params.value as 'paid' | 'requested' | 'approved' | 'rejected' | 'active']}
        >
          {t(`subscriptions.${params.value}`)}
        </Typography>
      )
    },
    {
      field: 'action',
      headerName: t('subscriptions.action'),
      width: 140,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return params.row.status === 'requested' ? (
          <Box display="flex" flexDirection="row" width={100} justifyContent="space-between">
            <Box mr={2}>
              <Typography className={classes.viewDetails} onClick={() => {
                dispatch(showModal({
                  variant: 'confirm',
                  title: t('subscriptions.approveAction'),
                  text: (<Trans
                      i18nKey='subscriptions.areYouSureToApprove'
                      values={{id: params.id}}
                      components={[<span className='arial'/>]}/> as unknown as string),
                  confirmBtnText: t('buttons.confirm'),
                  onConfirm: () => {
                    handleApprove(params.id as string)
                  }
                }))
              }} variant='subtitle2'>
                {t('subscriptions.approve')}
              </Typography>
            </Box>
            <Typography className={classes.viewDetails} onClick={() => {
              dispatch(showModal({
                variant: 'custom',
                children: <RejectModal onSuccess={handleRefresh} id={+ params.id}/>,
                maxWidth: 560,
              }))
            }} variant='subtitle2'>
              {t('subscriptions.reject')}
            </Typography>
          </Box>
        ) : <></>
      }
    },
  ]

  const handleSort = (params: GridSortModelParams) => {
    const { sortModel } = params
    dispatch(setCurrentPage(1))
    dispatch(setSortModel(sortModel))
  }

  const handlePagination = (event: object, page: number) => {
    dispatch(setCurrentPage(page))
  }

  const handleRefresh = () => {
    dispatch(setLoading(true))
    dispatch(fetchSubscriptions({
      currentPage,
      sort: sortSerializer(sortModel),
      filters: filtersSerializer(filters)
    }))
  }

  useEffect(() => {
    if (init) {
      handleRefresh()
    }
  }, [ currentPage, sortModel, debouncedFilters, init ])

  useEffect(() => {
    if (id) {
      dispatch(setLoading(true))
      dispatch(checkPayment(id))
      sleep()
        .then(() => {
          dispatch(setInit(true))
        })
    } else {
      dispatch(setInit(true))
    }
  }, [ id ])

  return (
    <Layout>
      <Grid className={classes.subscriptionsWrapper}>
        <DataGridWithOverlays
          onSort={handleSort}
          title={t('subscriptions.title')}
          loading={loading}
          autoHeight
          hasError={!!(error.name || error.message)}
          errorTitle={error.name}
          errorText={error.message}
          onRefresh={handleRefresh}
          columns={columns}
          rows={subscriptions}
          sortModel={sortModel}
          Filters={<Filters/>}
          Pagination={pageCount > 1
            ? <Pagination total={pageCount} currentPage={currentPage} handlePagination={handlePagination}/>
            : null}
          rowsHeight={66}
        />
      </Grid>
    </Layout>
  )
}

export default AdminSubscriptions
