import React from 'react';
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Input, TextButton } from '@takamol/unified-components'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { resetState, showModal } from '../../store/modalManager'
import Divider from '../shared/Divider'
import { useForm } from 'react-hook-form'
import { camelCase } from 'lodash'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  createSubscriptionRequest,
  setCreateSubscriptionRequestError,
  setCreateSubscriptionRequestLoading
} from '../../store/product'
import BlockLoader from '../shared/BlockLoader'
import { Workspace } from '../../store/workspaces'

type SubscribeProps = {
  estEmail: string
  exists: boolean
  onSubscribe: () => void
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#email::placeholder': {
      color: '#3ABBC1'
    }
  },
  primary: {
    color: theme.UP.content.primary
  },
  error: {
    color: theme.UP.content.error
  },
  gray: {
    color: theme.UP.common.gray
  },
  secondary: {
    color: theme.UP.content.secondary
  },
  modal: {
    background: theme.UP.modal.background,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`,
    textAlign: 'center'
  },
  formWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    textAlign: 'left',
    '@media (max-width: 550px)': {
      gridTemplateColumns: '1fr'
    }
  },
  summary: {
    background: theme.UP.content.paleGray,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 8,
  },
  summaryItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const SubscribeModal = (props: SubscribeProps) => {
  const { estEmail, exists, onSubscribe } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    product,
    createSubscriptionRequestLoading,
    createSubscriptionRequestError
  } = useAppSelector(state => state.product)
  const { lang, sequenceNumber, laborOffice, name } = useAppSelector((state => state.user))
  const { workspaces } = useAppSelector((state => state.workspaces))
  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: estEmail || ''
    }
  })
  const selectedWorkspace: Workspace = workspaces?.filter(
    (workspace: Workspace) => workspace?.sequenceNumber.toString() === sequenceNumber
  )[0]

  const handleClose = () => {
    dispatch(setCreateSubscriptionRequestError(''))
    dispatch(resetState())
  }

  const handleFormSubmit = ({ email }: { email: string }) => {
    dispatch(setCreateSubscriptionRequestLoading(true))
    dispatch(createSubscriptionRequest({
      email,
      productId: +product.id,
      userName: name
    }))
    .then(unwrapResult)
    .then(() => {
      dispatch(showModal({
        variant: 'success',
        text: t('products.requestUnderReview'),
        onConfirm: () => {
          onSubscribe()
        }
      }))
    })
    .catch((err) => {
      if (err.check_email_on_uniq?.[0]) {
        dispatch(setCreateSubscriptionRequestError(t(`errorsTip.${camelCase(err.check_email_on_uniq?.[0])}`)))
      } else if (err?.email[0] === 'email_not_valid') {
        dispatch(setCreateSubscriptionRequestError(t(`errorsTip.${camelCase(err?.email[0])}`)))
      } else {
        dispatch(setCreateSubscriptionRequestError(t('errorsTip.emailNotValid')))
      }
    })
  }

  return (
    <Box className={classes.modal}>
      {createSubscriptionRequestLoading && <BlockLoader/>}
      <Box mb={2}>
        <Typography variant='h1' className={classes.primary}>{t('products.requestService')}</Typography>
      </Box>
      <Typography variant='h4' className={classes.primary}>
        {t('products.subscribeSubTitle')}
      </Typography>
      <Box mt={4} className={classes.formWrapper}>
        <Box>
          <Input
            disabled
            fullWidth
            name='productName'
            id='productName'
            label={t('products.productName')}
            value={(lang === 'ar' ? product.nameAr : product.nameEn) || ''}
          />
        </Box>
        <Box>
          <Input
            disabled
            fullWidth
            name='estName'
            id='estName'
            label={t('products.estName')}
            value={selectedWorkspace?.establishmentName || ''}
          />
        </Box>
        <Box>
          <Input
            disabled
            fullWidth
            value={(laborOffice + sequenceNumber) || ''}
            name='estNumber'
            id='estNumber'
            label={t('products.estNumber')}
            className="arial"
          />
        </Box>
        <Box>
          <Input
            inputRef={register({
              required: t('rules.emailRequired') as string,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('rules.emailFormat')
              }
            })}
            disabled={exists}
            hasError={!!errors?.email?.message}
            errorMessage={errors?.email?.message}
            fullWidth
            name='email'
            id='email'
            label={t('products.email')}
            placeholder={t('products.emailPlaceholder')}
          />
        </Box>
      </Box>
      <Box mt={4} className={classes.summary}>
        <Box className={classes.summaryItemWrapper}>
          <Box mr={2}>
            <Typography variant='subtitle1' className={classes.primary}>{t('products.apiProductPrice')}</Typography>
          </Box>
          <Box display='flex'>
            <Typography variant='subtitle1' className={`${classes.primary} arial`}>{ product.basePrice }</Typography>
            <Typography variant='subtitle1' className={classes.primary}>&nbsp;{t('products.currency')}</Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Divider/>
        </Box>
        <Box className={classes.summaryItemWrapper}>
          <Box mr={2}>
            <Typography variant='subtitle2' className={classes.primary}>{t('products.rateLimit')}</Typography>
          </Box>
          {/*TODO*/}
          <Typography variant='subtitle1' className={classes.primary}>
            <Trans
                i18nKey='subscriptions.modalRequestsPerSec'
                values={{limit: 5}}
                components={[<span className='arial'/>]}
            />
          </Typography>
        </Box>
      </Box>
      {createSubscriptionRequestError && <Box mt={4}>
          <Typography variant='subtitle2' className={classes.error}>
            {createSubscriptionRequestError}
          </Typography>
      </Box>}
      <Box maxWidth={481} margin='0 auto' mt={4}>
        <Button onClick={handleSubmit(handleFormSubmit)} fullWidth id='confirm'>
          {t('buttons.submit')}
        </Button>
        <Box mt={4}>
          <TextButton fullWidth onClick={handleClose} id='cancel'>
            {t('buttons.cancel')}
          </TextButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SubscribeModal;
