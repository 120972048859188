export const PRODUCTS = [
    {
        id:	1,
        nameEn:	"Establishments details using establishment number",
        nameAr:	"بيانات المنشأة بدلالة رقم المنشأة",
        descriptionEn: "This service allows beneficiaries to inquire about basic details of the establishment, such as the name and address of the establishment, the status of the establishment and many other data in terms of the establishment number in the Ministry of Human Resources and Social Development",
        descriptionAr: "تتيح هذه الخدمة للمستفيدين الاستعلام عن البيانات الأساسية للمنشأة مثل اسم المنشأة وعنوانها، حالة المنشأة والعديد من البيانات الأخرى بدلالة رقم المنشأة في وزارة الموارد البشرية والتنمية الاجتماعية",
        info: {
            "title": "TIP_GetEstablishmentStatsByEstablishmentNumber",
            "description": "This API is used to get establishment stats by establishment number",
            "x-ibm-name": "tip-getestablishmentstatsbyestablishmentnumber",
            "version": "1.0.0"
        },
        security: [
            {
                "OAuth2.0": [
                    "IntegrationScope"
                ],
                "clientIdHeader": []
            }
        ],
    },
    {
        id:	2,
        nameEn:	"Establishments details using national unified number",
        nameAr:	"بيانات المنشأة بدلالة الرقم الوطني الموحد",
        descriptionEn: "This service allows beneficiaries to inquire about basic details of the establishment, such as the name and address of the establishment, the status of the establishment and many other data in terms of the unified national number",
        descriptionAr: "تتيح هذه الخدمة للمستفيدين الاستعلام عن البيانات الأساسية للمنشأة مثل اسم المنشأة وعنوانها، حالة المنشأة والعديد من البيانات الأخرى بدلالة الرقم الوطني الموحد",
        info: {
            "title": "TIP_GetEstablishmentStatsByUnifiedNumber",
            "description": "This API is used to get establishment status and employee count",
            "x-ibm-name": "tip-getestablishmentstatsbyunifiednumber",
            "version": "1.0.0"
        },
        security: [
            {
                "OAuth2.0": [
                    "IntegrationScope"
                ],
                "clientIdHeader": []
            }
        ],
    },
    {
        id:	3,
        nameEn:	"Establishment performance report using establishment number",
        nameAr:	"تقرير أداء المنشأة بدلالة رقم المنشأة",
        descriptionEn: "This service allows beneficiaries to inquire about establishment performance reports such as the status of compliance with the wage protection system, inquire about the basic compliance indicator and many other data in terms of the establishment number in the Ministry of Human Resources and Social Development",
        descriptionAr: "تتيح هذه الخدمة للمستفيدين الاستعلام عن تقارير أداء المنشآت مثل حالة الالتزام بنظام حماية الأجور، والاستعلام عن مؤشر الامتثال الأساسي والعديد من البيانات الأخرى بدلالة رقم المنشأة في وزارة الموارد البشرية والتنمية الاجتماعية",
        info: {
            "title": "TIP_GetEstablishmentPerformanceByEstablishmentNumber",
            "description": "To get establishment performance report by establishment number",
            "x-ibm-name": "tip_getestablishmentperformancereportestablishmentnumber",
            "version": "1.0.0"
        },
        security: [
            {
                "OAuth2.0": [
                    "IntegrationScope"
                ],
                "clientIdHeader": []
            }
        ],
    },
    {
        id:	4,
        nameEn:	"Establishment performance report using national unified number",
        nameAr:	"تقرير أداء المنشأة بدلالة الرقم الوطني الموحد",
        descriptionEn: "This service allows beneficiaries to inquire about establishment performance reports such as the status of compliance with the wage protection system, inquire about the basic compliance indicator and many other data in terms of the establishment number in the unified national number",
        descriptionAr: "تتيح هذه الخدمة للمستفيدين الاستعلام عن تقارير أداء المنشآت مثل حالة الالتزام بنظام حماية الأجور، والاستعلام عن مؤشر الامتثال الأساسي والعديد من البيانات الأخرى بدلالة الرقم الوطني الموحد",
        info: {
            "title": "TIP_GetEstablishmentPerformanceByUnifiedNumber",
            "description": "To get establishment performance report by unified number",
            "x-ibm-name": "tip_getestablishmentperformancereportunifiednumber",
            "version": "1.0.0"
        },
        security: [
            {
                "OAuth2.0": [
                    "IntegrationScope"
                ],
                "clientIdHeader": []
            }
        ],
    },
    {
        id:	5,
        nameEn:	"Establishment status and employee count",
        nameAr:	"حالة المنشأة وعدد الموظفين",
        descriptionEn: "This service allows beneficiaries to inquire about the status of the establishment and the number of employees in terms of the unified national number",
        descriptionAr: "تتيح هذه الخدمة للمستفيدين الاستعلام عن حالة المنشأة وعدد المظفين بدلالة الرقم",
        info: {
            title: "TIP_GetEstablishmentStatusandEmployeeCount",
            description: "This API is used to get establishment status and employee count",
            "x-ibm-name": "tip-getestablishmentstatusandemployeecount",
            "version": "1.0.0"
        },
        security: [
            {
                "OAuth2.0": [
                    "IntegrationScope"
                ],
                "clientIdHeader": []
            }
        ],
    }
]
