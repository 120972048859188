import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '../modalManager/EditIcon'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Button, Input, TextButton } from '@takamol/unified-components'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { resetState, showModal } from '../../store/modalManager'
import { sendPassword, setError } from '../../store/user'

type FormValues = {
  password: string
  newPassword: string
  passwordConfirm: string
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#password::placeholder, #newPassword::placeholder, #passwordConfirm::placeholder': {
      color: '#3ABBC1'
    },
    'input[type="password"]::-ms-reveal': {
      display: 'none',
    },
  },
  primary: {
    color: theme.UP.content.primary,
  },
  blue: {
    color: theme.UP.common.alternativeBlue,
  },
  error: {
    color: theme.UP.content.error
  },
  modalWrapper: {
    textAlign: 'center',
    padding: theme.spacing(5),

    '@media screen and (max-width:550px)': {
      padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    }
  },
  modalTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  formWrapper: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))

const ChangePasswordModal = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { error } = useAppSelector(state => state.user)
  const { t } = useTranslation()
  const { register, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      password: '',
      newPassword: '',
      passwordConfirm: ''
    }
  })
  const handleClose = () => {
    dispatch(resetState())
  }

  const handleChangePhoneNumber = ({ password, newPassword, passwordConfirm }: FormValues) => {
    dispatch(sendPassword({
      password,
      newPassword,
      passwordConfirm
    }))
    .then(unwrapResult)
    .then(() => {
      dispatch(showModal({
        variant: 'success',
        text: t('profile.modalPasswordSuccess')
      }))
    }).catch((err) => {
      if (err['field-error']) {
        dispatch(setError({
          name: err['field-error'][0],
          message: err['field-error'][1]
        }))
      }
    })
  }

  useEffect(() => () => {
    dispatch(setError({
      name: '',
      message: '',
      code: ''
    }))
  }, [])

  return (
    <Box className={classes.modalWrapper}>
      <EditIcon/>
      <Box className={classes.modalTitle}>
        <Typography variant='h1' className={classes.primary}>
          {t('profile.modalTitlePassword')}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(handleChangePhoneNumber)} autoComplete='off' noValidate>
        <input autoComplete='false' type='search' style={{overflow: 'hidden', display: 'none'}}/>
        <Box className={classes.formWrapper}>
          <Box mt={1} mb={4}>
            <Input
              fullWidth
              type='password'
              name='password'
              id='password'
              label={t('profile.passwordLabel')}
              placeholder={t('profile.passwordPlaceholder')}
              hasError={!!errors.password || error?.name === 'password'}
              errorMessage={errors.password?.message }
              // @ts-ignore
              inputRef={register({
                required: t('validation.thisIsRequiredField')
              })}
            />
          </Box>
          <Box mb={4}>
            <Input
              fullWidth
              type='password'
              name='newPassword'
              id='newPassword'
              label={t('profile.newPasswordLabel')}
              placeholder={t('profile.newPasswordPlaceholder')}
              hasError={!!errors.newPassword || error?.name === 'new_password'}
              errorMessage={errors.newPassword?.message}
              // @ts-ignore
              inputRef={register({
                required: t('validation.thisIsRequiredField')
              })}
            />
          </Box>
          <Box mb={4}>
            <Input
              fullWidth
              type='password'
              name='passwordConfirm'
              id='passwordConfirm'
              label={t('profile.passwordConfirmationLabel')}
              placeholder={t('profile.passwordConfirmationPlaceholder')}
              hasError={!!errors.passwordConfirm || error?.name === 'new_password'}
              errorMessage={errors.passwordConfirm?.message}
              // @ts-ignore
              inputRef={register({
                required: t('validation.thisIsRequiredField')
              })}
            />
          </Box>
        </Box>
        {error?.message && <Box mb={4}>
          <Typography
            className={classes.error}
            variant='subtitle2'>
              {error.code ? t(`errorsTip.${error.code}`) : error.message}
          </Typography>
        </Box>}
        <Button fullWidth id='confirm' type='submit'>
          {t('profile.savePassword')}
        </Button>
        <Box mt={4}>
          <TextButton fullWidth onClick={handleClose} id='cancel'>
            {t('buttons.cancel')}
          </TextButton>
        </Box>
      </form>
    </Box>
  );
};

export default ChangePasswordModal;
