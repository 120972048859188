import getEnvVar from '../utils/getEnvVar'

// Logout
export const LOGOUT = '/context/logout'
export const GET_REDIRECTION_URL = '/oauth/openid_connect'
// Companies
export const GET_COMPANIES = '/establishments'
export const POST_SET_COMPANY = (id: string) => `/establishments/${id}`
// Theme and language
export const SEND_THEME = (theme: string) => `${getEnvVar('REACT_APP_AUTH_API_GATEWAY')}/session/theme/${theme}`
export const SEND_LANG = (lang: string) => `${getEnvVar('REACT_APP_AUTH_API_GATEWAY')}/session/language/${lang}`
// User requests
export const GET_SUBSCRIPTIONS = (currentPage: number, sort: string, filters: string | undefined) => `/product-subscriptions?per=10&page=${currentPage}${sort}${filters}`
export const SEND_APPROVE = (subscriptionId: string) => `/product-subscriptions/${subscriptionId}/status/approved`
export const SEND_REJECT = '/product-subscriptions/status/rejected'
export const SEND_ACTIVATE = '/product-subscriptions/status/active'
export const GET_PRODUCT = (id: string) => `/products/${id}`
export const GET_PRODUCTS = `/products`
export const GET_PLANS = `/plans`
// Subscriptions
export const POST_CREATE_SUBSCRIPTION_REQUEST = '/product-subscriptions/request'
export const POST_CHECK_PAYMENT = (id:string) => `/product-subscriptions/payment/check?payment-id=${id}`
export const POST_CHECK_EMAIL = `/product-subscriptions/email/check`
export const POST_CHECK_PRODUCT = (id:string) => `/product-subscriptions/check-product/${id}`
export const GET_INVOICE = (id:string) => `/product-subscriptions/${id}/invoice`
export const GET_SUBSCRPTION = (id:string) => `/product-subscriptions/${id}`
export const INIT_PAYMENT = (id:string) => `/product-subscriptions/${id}/payment/init`
// Profile
export const SEND_VERIFICATION = `${getEnvVar('REACT_APP_AUTH_API_GATEWAY')}/session/high-security-mode/init`
export const POST_ABSHER_OTP = `${getEnvVar('REACT_APP_AUTH_API_GATEWAY')}/session/high-security-mode`
export const POST_PHONE = `${getEnvVar('REACT_APP_AUTH_API_GATEWAY')}/phones/verify`
export const POST_OTP = `${getEnvVar('REACT_APP_AUTH_API_GATEWAY')}/phones`
export const POST_PASSWORD = `${getEnvVar('REACT_APP_AUTH_API_GATEWAY')}/accounts/change-password`
export const GET_USER_DATA = `${getEnvVar('REACT_APP_AUTH_API_GATEWAY')}/profile`
