import React, { useEffect } from 'react';
import { Box, Typography, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '../modalManager/EditIcon'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Input, TextButton } from '@takamol/unified-components'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { resetState, showModal } from '../../store/modalManager'
import { sendPhone, setError } from '../../store/user'
import OtpModal from './OtpModal';

type FormValues = {
  phoneNumber: string
}

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary,
  },
  blue: {
    color: theme.UP.common.alternativeBlue,
  },
  error: {
    color: theme.UP.content.error
  },
  modalWrapper: {
    textAlign: 'center',
    padding: theme.spacing(5),

    '@media screen and (max-width:550px)': {
      padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    }
  },
  modalTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  formWrapper: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  phoneInput: {
    flip: false,
    width: 'calc(100% - 75px)',
    maxWidth: 'calc(100% - 75px)',
    marginLeft: 75,
    direction: 'ltr',
    textAlign: 'left',
    '& #phoneNumberPrefix': {
      flip: false,
      padding: 0,
      direction: 'ltr',
      textAlign: 'center',
      unicodeBidi: 'bidi-override',
      backgroundColor: theme.UP.content.background,
    },
    '& #phoneNumber': {
      flip: false,
      textAlign: 'left',
      padding: `0 ${theme.spacing(2)}px`,
      '&::placeholder, &:-moz-placeholder': {
        color: '#3ABBC1',
      },
      '& + *': {
        flip: false,
        right: 'unset',
        left: -75,
        width: 67,
        '& > div': {
          margin: 0,
        }
      }
    }
  }
}))

const ChangePhoneNumberModal = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { error } = useAppSelector(state => state.user)
  const { t } = useTranslation()
  const { register, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      phoneNumber: ''
    }
  })
  const handleClose = () => {
    dispatch(resetState())
  }

  const handleChangePhoneNumber = ({ phoneNumber }: FormValues) => {
    dispatch(setError({
      name: '',
      message: ''
    }))
    if (phoneNumber && phoneNumber.toString().length < 8) {
      dispatch(setError({
        name: 'Phone number',
        message: t('errorsTip.phoneNotValid')
      }))
    } else {
      dispatch(sendPhone(`009665${phoneNumber}`))
        .then(unwrapResult)
        .then(() => {
          dispatch(showModal({
            variant: 'custom',
            children: <OtpModal onResend={() => dispatch(sendPhone(`009665${phoneNumber}`))}
                                phone={`+966-5-${phoneNumber}`}/>,
            maxWidth: 561,
            onClose: () => {
              dispatch(resetState())
            }
          }))
        }).catch(err => {
        console.error(err)
      })
    }
  }

  useEffect(() => () => {
    dispatch(setError({
      name: '',
      message: ''
    }))
  }, [])

  return (
    <Box className={classes.modalWrapper}>
      <EditIcon/>
      <Box className={classes.modalTitle}>
        <Typography variant="h1" className={classes.primary}>
          {t('profile.modalTitlePhone')}
        </Typography>
      </Box>
      <Typography className={classes.primary} variant="h4">
        {t('profile.modalSubTitlePhone')}
      </Typography>
      <form onSubmit={handleSubmit(handleChangePhoneNumber)}>
        <Box className={classes.formWrapper}>
          <Box mt={1} mb={1}>
            <Input
              fullWidth
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              label={t('profile.phoneNumberLabel')}
              placeholder={t('profile.phoneNumberPlaceholder')}
              hasError={!!errors.phoneNumber}
              errorMessage={errors.phoneNumber?.message}
              className={`${classes.phoneInput} arial`}
              inputProps={{ maxLength: 8 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => (
                e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
              )}
              endAdornment={
                <InputAdornment position="start">
                  <Input
                    fullWidth
                    type="text"
                    name="phoneNumberPrefix"
                    id="phoneNumberPrefix"
                    value={'+966-5-'}
                    disabled
                  />
                </InputAdornment>
              }
              // @ts-ignore
              inputRef={register({
                required: t('validation.thisIsRequiredField'),
                valueAsNumber: true,
              })}
            />
          </Box>
        </Box>
        {error?.message && <Box mb={4}>
          <Typography className={classes.error} variant="subtitle2">{error.message}</Typography>
        </Box>}
        <Button fullWidth id="confirm" type="submit">
          {t('profile.save')}
        </Button>
        <Box mt={4}>
          <TextButton fullWidth onClick={handleClose} id="cancel">
            {t('buttons.cancel')}
          </TextButton>
        </Box>
      </form>
    </Box>
  );
};

export default ChangePhoneNumberModal;
