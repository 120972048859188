import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button } from '@takamol/unified-components'
import { resetState } from '../../store/modalManager'
import { useAppDispatch } from '../../store/root';
import ActionIcon from '../modalManager/ActionIcon';
import CopyField from "../shared/CopyField";

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary,
  },
  modalWrapper: {
    textAlign: 'center',
    padding: theme.spacing(5),

    '@media screen and (max-width:550px)': {
      padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    }
  },
  modalTitle: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  modalGrid: {
    textAlign: 'left',
  },
}))

type SummaryModalProps = {
  id: string
  secretKey: string
}

const SummaryModal = (props: SummaryModalProps) => {
  const { id, secretKey } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const handleClose = () => {
    dispatch(resetState())
  }
  return (
    <Box className={classes.modalWrapper}>
      <ActionIcon/>
      <Box className={classes.modalTitle}>
        <Typography variant='h1' className={classes.primary}>
          {t('subscriptions.summaryModalTitle')}
        </Typography>
      </Box>
      <Grid container spacing={2} className={classes.modalGrid}>
        <Grid item xs={12}>
          <Typography variant='subtitle1' gutterBottom className={classes.primary}>
            {t('subscriptions.clientIdLabel')}
          </Typography>
          <CopyField value={id} isMasked/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' gutterBottom className={classes.primary}>
            {t('subscriptions.clientSecretLabel')}
          </Typography>
          <CopyField value={secretKey} isMasked/>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Button fullWidth onClick={handleClose} id='close'>
          {t('common.close')}
        </Button>
      </Box>
    </Box>
  );
};

export default SummaryModal;
