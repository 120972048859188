import React, { useEffect, useMemo, useState } from 'react';
import Layout from '../../components/Layout'
import { Block, Button } from '@takamol/unified-components'
import { Box, Checkbox, Typography } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import ContentWrapper from '../../components/shared/ContentWrapper'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { showModal } from '../../store/modalManager'
import TermsModal from '../../components/subscriptions/TermsModal'
import { fetchSubscription, initPayment, reset, setLoading } from '../../store/subscriptionSummary'
import { useParams } from 'react-router-dom'
import BlockLoader from '../../components/shared/BlockLoader'
import ErrorOverlay from '../../components/shared/DataGrid/ErrorOverlay'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary
  },
  terms: {
    background: 'none',
    outline: 'none',
    border: 'none',
    color: theme.UP.type === 'light' ? theme.UP.common.green : theme.UP.common.alternativeGreen,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
  grayBlock: {
    background: theme.UP.content.background,
    borderRadius: 8,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  infoWrapper: {
    display: 'grid',
    gap: theme.spacing(3),
    gridTemplateColumns: '1fr 1fr 1fr',
    '@media screen and (max-width:550px)': {
      gridTemplateColumns: '1fr 1fr',
    }
  },
  termsBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    color: theme.UP.content.border,
    '&:hover': {
      background: 'none'
    },
    '&.Mui-disabled': {
      opacity: 0,
    },
    '&.Mui-checked': {
      color: theme.UP.common.alternativeGreen,
      '&:hover': {
        background: 'none'
      }
    },
  }
}))

const Summary = () => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const { t, i18n: { language } } = useTranslation()
  const dispatch = useAppDispatch()
  const { loading, error, subscription } = useAppSelector(state => state.subscriptionSummary)
  const [ agree, setAgree ] = useState<boolean>(false)

  const vat = useMemo(() => (+ subscription?.product?.basePrice / 100 * subscription?.vatRate)
      .toFixed(2),
    [ subscription.vatRate ])

  const handleCheck = () => {
    setAgree(prevState => !prevState)
  }

  const handleTerms = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    dispatch(showModal({
      variant: 'custom',
      children: <TermsModal/>,
      maxWidth: 857,
    }))
  }

  const handleRefresh = () => {
    dispatch(setLoading(true))
    dispatch(initPayment(id))
    dispatch(fetchSubscription(id))
  }

  const goToPayment = () => {
    window.location.href = subscription.paymentRedirectUrl
  }

  useEffect(() => {
    handleRefresh()
    return () => {
      dispatch(reset())
    }
  }, [])

  return (
    <Layout>
      <Block>
        <Box position='relative'>
          {!error && <>
              <Typography className={classes.primary} variant='h2'>
                {t('summary.title')}
              </Typography>
              <Box className={classes.grayBlock}>
                  <ContentWrapper title={t('summary.summaryDetails')}>
                      <Box className={classes.infoWrapper}>
                          <Box>
                              <Box mb={0.5}>
                                  <Typography variant='subtitle2' className={classes.primary}>
                                    {t('summary.productName')}
                                  </Typography>
                              </Box>
                              <Typography variant='subtitle1' className={classes.primary}>
                                {language === 'ar' ? subscription?.product?.nameAr : subscription?.product?.nameEn}
                              </Typography>
                          </Box>
                          <Box>
                              <Box mb={0.5}>
                                  <Typography variant='subtitle2' className={classes.primary}>
                                    {t('summary.productPrice')}
                                  </Typography>
                              </Box>
                              <Box display='flex'>
                                <Typography
                                    variant='subtitle1'
                                    className={`${classes.primary} arial`}
                                >
                                  { subscription?.product?.basePrice }
                                </Typography>
                                <Typography variant='subtitle1' className={classes.primary}>&nbsp;SAR</Typography>
                              </Box>
                          </Box>
                          <Box>
                              <Box mb={0.5}>
                                <Typography variant='subtitle2' className={classes.primary}>
                                  {t('summary.vat')}
                                </Typography>
                              </Box>
                              <Box display='flex'>
                                <Typography
                                    variant='subtitle1'
                                    className={`${classes.primary} arial`}
                                >
                                  { vat }
                                </Typography>
                                <Typography variant='subtitle1' className={classes.primary}>&nbsp;SAR</Typography>
                              </Box>
                          </Box>
                          <Box>
                              <Box mb={0.5}>
                                  <Typography variant='subtitle2' className={classes.primary}>
                                    {t('summary.limit')}
                                  </Typography>
                              </Box>
                              <Typography variant='subtitle1' className={classes.primary}>
                                <Trans
                                    i18nKey='subscriptions.requestsPerSec'
                                    values={{limit: 5}}
                                    components={[<span className='arial'/>]}
                                />
                              </Typography>
                          </Box>
                          <Box>
                              <Box mb={0.5}>
                                  <Typography variant='subtitle2' className={classes.primary}>
                                    {t('summary.vat')}%
                                  </Typography>
                              </Box>
                              <Box display='flex'>
                                <Typography
                                    variant='subtitle1'
                                    className={`${classes.primary} arial`}
                                >
                                  { subscription.vatRate }
                                </Typography>
                                <Typography variant='subtitle1' className={classes.primary}>&nbsp;%</Typography>
                              </Box>
                          </Box>
                          <Box>
                              <Box mb={0.5}>
                                  <Typography variant='subtitle2' className={classes.primary}>
                                    {t('summary.totalPrice')}
                                  </Typography>
                              </Box>
                              <Box display='flex'>
                                <Typography
                                    variant='subtitle1'
                                    className={`${classes.primary} arial`}
                                >
                                  {
                                    (Math.round(((+ vat + + subscription?.product?.basePrice) * 100)) / 100)
                                      .toFixed(2)
                                  }
                                </Typography>
                                <Typography variant='subtitle1' className={classes.primary}>&nbsp;SAR</Typography>
                              </Box>
                          </Box>
                      </Box>
                  </ContentWrapper>
              </Box>
              <Box mb={4} width='fit-content'>
                  <label>
                      <Box className={classes.termsBlock}>
                          <Checkbox
                              classes={{
                                root: classes.checkbox
                              }}
                              onChange={handleCheck}
                              checked={agree}
                          />
                          <Typography variant='h4' className={classes.primary}>
                            {t('summary.agreeWith')}
                              <button type='button' onClick={handleTerms} className={classes.terms}>
                                {t('summary.terms')}
                              </button>
                          </Typography>
                      </Box>
                  </label>
              </Box>
              <Button fullWidth id='pay' disabled={!agree} onClick={goToPayment}>
                {t('summary.pay')}
              </Button>
          </>}
          {!!error && <ErrorOverlay title={error} onRefresh={handleRefresh}/>}
          {loading && <BlockLoader/>}
        </Box>
      </Block>
    </Layout>
  );
};

export default Summary;
