import actioncabel, { ChannelNameWithParams } from 'actioncable'
import { NotificationStatus } from '../store/notifications'

let isCreated = false
let channel: any;

interface ICallbacks {
  connected?: () => void;
  disconnected?: () => void;
  received: (obj: any) => void;
}

export default (url: string, channelObj?: ChannelNameWithParams, callbacks?: ICallbacks) => {
  if (!isCreated) {
    isCreated = true
    const cable = actioncabel.createConsumer(url || '')
    channel = cable.subscriptions.create(channelObj || '', {
      connected() {
        if (callbacks?.connected) callbacks.connected()
      },
      disconnected() {
        if (callbacks?.disconnected) callbacks.disconnected()
      },
      received(obj: any) {
        callbacks?.received(obj)
      }
    })
  }

  const unsubscribe = () => {
    if (channel) channel.unsubscribe()
  }

  const getNotifications = (currentPage: number, pageSize: number) => {
    if (channel) {
      channel.perform('get', {
        query: {
          page: currentPage,
          per: pageSize
        },
        action: 'get',
      })
    }
  }

  const markAsRead = (id: number) => {
    if (channel) {
      channel.send({
        json_api: {
          data: {
            id: id.toString(),
            type: 'app-notification',
            attributes: {
              id,
              status: NotificationStatus.read
            }
          }
        },
        action: 'change_status',
      })
    }
  }

  const emit = (type: string, value: any) => {
    if (channel) {
      channel.perform(type, value)
    }
  }

  return { channel, unsubscribe, getNotifications, markAsRead, emit }
}
