import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { IFilter } from '@takamol/unified-components'
import {
  GET_SUBSCRPTION, INIT_PAYMENT,
} from '../constants/api'
import api from '../services/axios'
import getErrorText from '../utils/getErrorText'
import { SubscriptionItem } from './subscriptions'

export const initialFilters: IFilter = {
  id: {
    value: null,
    action: 'eq',
  },
  name: {
    value: null,
    action: 'like'
  },
  company: {
    value: null,
    action: 'like'
  },
  status: {
    value: null,
    action: 'eq'
  },
  submitted_date: {
    value: null,
    action: 'eq'
  },
  subscriptionId: {
    value: null,
    action: 'eq'
  }
}

type SubscriptionSummaryState = {
  loading: boolean
  error: string
  subscription: SubscriptionItem
}

const stateAdapter = createEntityAdapter()

const initialState = stateAdapter.getInitialState({
  loading: false,
  error: '',
  subscription: {} as SubscriptionItem
}) as SubscriptionSummaryState

export const fetchSubscription = createAsyncThunk(
  'subscriptionSummary/fetchSubscription',
  async (id: string, { rejectWithValue }) => {
    return await api('get', GET_SUBSCRPTION(id), {}, rejectWithValue)
  })

export const initPayment = createAsyncThunk(
  'subscriptionSummary/initPayment',
  async (id: string, { rejectWithValue }) => {
    return await api('post', INIT_PAYMENT(id), {}, rejectWithValue)
  }
)

const subscriptionSummary = createSlice({
  name: "subscriptionSummary",
  initialState,
  reducers: {
    setLoading(state: SubscriptionSummaryState, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    reset() {
      return initialState
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.error = initialState.error
        state.subscription = action.payload.data
        state.loading = false
      })
      .addCase(fetchSubscription.rejected, (state, action: any) => {
        state.loading = false
        state.error = getErrorText(action.payload?.subscription?.[0])
      })
      .addCase(initPayment.fulfilled, (state, action) => {
        state.subscription = {
          ...state.subscription,
          paymentRedirectUrl: action.payload.data.redirectUrl
        }
      })
      .addCase(initPayment.rejected, (state, action: any) => {
        state.error = getErrorText(action.payload?.subscription?.[1] || action.payload?.subscription?.[0])
      })
  }
})

export const reducer: Reducer<typeof initialState> = subscriptionSummary.reducer
export const { setLoading, reset } = subscriptionSummary.actions
