import React, { useRef, useState } from 'react'
import { ReactComponent as ActiveBell } from '../../assets/images/active-bell.svg'
import { ReactComponent as ActiveBellDark } from '../../assets/images/active-bell-dark.svg'
import { Box, ClickAwayListener, Fade, Paper, Popper, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NoFound, TextButton } from '@takamol/unified-components'
import { useAppDispatch, useAppSelector } from '../../store/root'
import classNames from 'classnames'
import moment from 'moment'
import 'moment/min/locales'
import {
  Notification,
  NotificationStatus,
  setHasNewNotification,
  setNotifications,
  setCounter,
} from '../../store/notifications'
import useSockets from '../../hooks/useSockets'
import { Trans, useTranslation } from 'react-i18next'
import getEnvVar from '../../utils/getEnvVar'

const useStyles = makeStyles((theme) => ({
  primaryTitle: {
    color: theme.UP.controls.input.text.default
  },
  secondaryTitle: {
    color: theme.UP.content.secondary
  },
  paperRoot: {
    background: 'transparent',
    borderRadius: 'unset',
    boxShadow: 'none'
  },
  icon: {
    position: 'relative',
    cursor: 'pointer',
    transition: '1s transform',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    padding: `0 ${theme.spacing(1)}px`,
    '&.open': {
      transform: 'rotateZ(25deg)',
    },
    '&:hover': {
      transform: 'rotateZ(25deg)',
    }
  },
  circle: {
    flip: false,
    position: 'absolute',
    top: 2,
    right: 12,
    width: 6,
    height: 6,
    background: '#FF635B',
    borderRadius: '50%',
    border: `1px solid ${theme.UP.background.paper}`
  },
  notificationsWrapper: {
    background: theme.UP.background.paper,
    maxWidth: 424,
    width: '100%',
    maxHeight: 524,
    overflowX: 'auto',
    border: `${theme.UP.type === 'dark' ? 1 : 0}px solid ${theme.UP.content.border}`,
    boxShadow: '0px 2px 8px rgba(51, 70, 129, 0.1)',
    borderRadius: 0,
    zIndex: 1,
  },
  notificationsTitleWrapper: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderBottom: `${theme.UP.type === 'dark' ? 1 : 0}px solid ${theme.UP.content.border}`,
    background: theme.UP.background.paper,
    boxShadow: '0px 2px 8px rgba(51, 70, 129, 0.1)',
    zIndex: 1,
    '& #readAll': {
      fontSize: 12,
      paddingLeft: 0,
      paddingRight: 0,
      color: theme.UP.type === 'light' ? theme.UP.content.secondary : '#3ABBC1',
    }
  },
  notificationItem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    cursor: 'pointer',
    '&:before': {
      content: '""',
      width: 4,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
    },
    '&:hover': {
      background: theme.UP.content.paleGray,
    },
    '&.success': {
      '&:before': {
        background: theme.UP.content.success,
      }
    },
    '&.error': {
      '&:before': {
        background: theme.UP.content.error,
      }
    },
    '&.info': {
      '&:before': {
        background: '#3ABBC1',
      }
    },
  },
  notificationItemTitleWrapper: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    '& a': {
      textDecoration: 'none',
      color: theme.UP.type === 'light' ? theme.UP.content.secondary : '#3ABBC1',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '&.readIndicator': {
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        width: 8,
        height: 8,
        borderRadius: '50%',
        background: '#FF635B'
      }
    }
  },
  noDataFoundWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    minHeight: 300,
  }
}))

const Notifications = () => {
  const { t } = useTranslation()
  const { hasNewNotification, notifications, page, counter, pageSize } = useAppSelector(state => state.notifications)
  const { nationalId, lang } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const [ open, setOpen ] = useState(false)

  const anchorRef = useRef<HTMLButtonElement>(null)

  const deserializeNotification = (data: any) => {
    const item: any = {}
    Object.keys(data).forEach((key) => {
      item[key.replaceAll('-', '_')] = data[key]
    })
    return item
  }

  const {
    markAsRead,
    getNotifications
  } = useSockets(getEnvVar('REACT_APP_WS_API'), {channel: 'app_notification', id: nationalId}, {
    connected: () => {
      getNotifications(page, 10)
    },
    received: (msg) => {
      const { data } = JSON.parse(msg)
      dispatch(setNotifications(deserializeNotification(data.attributes)))
    }
  })

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleRead = (item: Notification) => () => {
    if (item.status !== NotificationStatus.read) {
      markAsRead(item.id)
    }
  }

  const readAll = () => {
    dispatch(setHasNewNotification(false))
    notifications
      .filter(notification => notification.status !== NotificationStatus.read)
      .forEach(notification => markAsRead(notification.id))
  }

  const renderDate = (date: string) => {
    const daysAfter = moment().diff(date, 'days')
    if (daysAfter < 2) {
      return moment(date).utc(true).locale(lang).fromNow()
    }
    return moment(date).utc(true).locale(lang).format('DD/MM/YYYY')
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    const scrollTopMax = target.clientHeight
    if (target.scrollHeight - target.scrollTop - 25 <= scrollTopMax && counter >= pageSize) {
      dispatch(setCounter(0))
      getNotifications(page, pageSize)
    }
  }

  return (
    <Box>
      <button
        id='notifications'
        onClick={handleToggle}
        ref={anchorRef}
        className={classNames(classes.icon, { open })}
      >
        {theme.UP.type === 'light' ? <ActiveBell/> : <ActiveBellDark/>}
        {hasNewNotification && <Box className={classes.circle}/>}
      </button>
      <Popper
        placement='bottom-end'
        className={classes.notificationsWrapper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        onScroll={handleScroll}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={200}
          >
            <Paper classes={{ root: classes.paperRoot }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Box className={classes.notificationsTitleWrapper}>
                    <Box mr={1}>
                      <Typography className={classes.primaryTitle} variant='h2'>
                        {t('common.notifications')}
                      </Typography>
                    </Box>
                    {<TextButton id='readAll' onClick={readAll}>
                      {t('buttons.readAll')}
                    </TextButton>}
                  </Box>
                  {!!notifications.length && notifications?.map((item) => (
                    <Box
                      onClick={handleRead(item)}
                      key={item.id}
                      className={classNames(classes.notificationItem, item.type)}
                    >
                      <Box
                        className={classNames(classes.notificationItemTitleWrapper, {
                          readIndicator: item.status === NotificationStatus.unread
                            || item.status === NotificationStatus.new
                        })}>
                        <Box mr={2}>
                          <Typography className={classes.primaryTitle} variant='subtitle1'>
                            {lang === 'ar' ? item.message_ar : item.message_en}
                          </Typography>
                          <Typography className={classes.primaryTitle} variant='subtitle2'>
                            <Trans
                              i18nKey='notifications.subTitleWithLink'
                              components={[<a target='_blank' href={item.links[0]} />]}
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt={1}>
                        <Typography className={classes.primaryTitle} variant='h6'>
                          {item.created_at && renderDate(item.created_at)}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                  {!notifications.length && <Box data-testid="no-notifications" className={classes.noDataFoundWrapper}>
                    <NoFound title='No data found' text='No data found'/>
                  </Box>}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default Notifications;
