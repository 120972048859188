import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { GET_PRODUCTS } from '../constants/api'
import api from '../services/axios'
import getErrorText from '../utils/getErrorText'

export type TProduct = {
  id: string,
  nameEn: string | null,
  nameAr: string | null,
  descriptionEn: string | null,
  descriptionAr: string | null,
  imageUrl?: string,
  apiUrl: string,
  documentationUrl: string,
  basePrice: string
}

type ProductsState = {
  loading: boolean,
  products: TProduct[],
  error: string
}

const stateAdapter = createEntityAdapter()

const initialState = stateAdapter.getInitialState({
  loading: false,
  products: [],
  error: ''
}) as ProductsState

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (_, { rejectWithValue }) => {
    return await api('get', GET_PRODUCTS, {}, rejectWithValue)
  })

const products = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setLoading(state: ProductsState, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setError(state: ProductsState) {
      state.error = initialState.error
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload.data
        state.error = initialState.error
        state.loading = false
      })
      .addCase(fetchProducts.rejected, (state, action: any) => {
        state.loading = false
        state.error = getErrorText(action.payload?.errors?.[0].title)
      })
  }
})

export const reducer: Reducer<typeof initialState> = products.reducer
export const { setLoading, setError } = products.actions
