import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@takamol/unified-components'
import { useAppDispatch } from '../../store/root'
import { resetState } from '../../store/modalManager'
import ActionIcon from '../modalManager/ActionIcon'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary,
  },
  blue: {
    color: theme.UP.common.alternativeBlue,
  },
  modalWrapper: {
    textAlign: 'center',
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`,
    '@media screen and (max-width:550px)': {
      padding: `${theme.spacing(5)}px ${theme.spacing(2)}px`,
    }
  },
  titleWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
  contentWrapper: {
    fontSize: 16,
    lineHeight: 1.5,
    height: 'calc(100vh - 402px)',
    marginBottom: theme.spacing(4),
    overflowY: 'auto',
    textAlign: 'left',
    color: theme.UP.content.primary,
    '& p, & ul, & ol': {
      font: 'inherit',
    },
    '@media screen and (max-width:550px)': {
      height: 'calc(100vh - 386px)',
    }
  }
}))

const TermsModal = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(resetState())
  }

  return (
    <Box className={classes.modalWrapper}>
      <ActionIcon/>
      <Box className={classes.titleWrapper}>
        <Typography className={classes.primary} variant="h1">{t('summary.terms')}</Typography>
      </Box>
      {/*TODO Add ar version of the terms and conditions*/}
      <Box className={classes.contentWrapper}>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Introductions</Typography>
          </Box>
          <Typography paragraph={true}>
            These Terms of Use (together with the documents referred to in this document) include the needed
            instructions for how to use and benefit from our website "tiptech.sa" (hereinafter referred to as "our
            Site"), either as a browser or as a user. Instructions for using our site include access and browse
            procedures or registration to use the site.
          </Typography>
          <Typography paragraph={true}>
            We recommend that you print a copy of these conditions for possible reference in the future.
          </Typography>
          <Typography paragraph={true}>
            By using our Site, you confirm your acceptance of these Terms of Use and agree to comply with its
            provisions.
          </Typography>
          <Typography paragraph={true}>
            If you do not accept these terms, you should not use the site.
          </Typography>
          <Typography paragraph={true}>
            This Terms and Conditions document shall apply to all users of Takamol Integration Platform
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Definitions:</Typography>
          </Box>
          <ul>
            <li><b>Takamol Integration Platform:</b> Takamol Integration Platform provides information on Takamol’s API
              products. Allows users to subscribe to a plan and create applications to make use of offered APIs
            </li>
            <li><b>Takamol:</b> Takamol company, who owns and provides Takamol Integrated Platform services.</li>
            <li>
              <b>Beneficiary:</b> the user of Takamol Integration Platform, including government and private sector
              companies and establishments. User can be:
              <ul>
                <li><b>Guest user:</b> user who can browse the website without logging in or subscribing to the website.
                </li>
                <li><b>Registered user:</b> user who registered successfully in the portal. This user can explore the
                  APIs products
                </li>
                <li><b>Subscribed user:</b> user who created subscription on API products. This user has full access on
                  the subscribed product as per the subscription plan.
                </li>
              </ul>
            </li>
          </ul>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Subscription Plan terms and Payment
              Conditions:</Typography>
          </Box>
          <ul>
            <li>Subscription plan for the Establishment user is annual.</li>
            <li>Each API product requires a separate subscription request.</li>
            <li>Each API product has a different annual price.</li>
            <li>The subscription amount is not refunded in any case.</li>
            <li>Each API product has a rate limit for maximum available API requests per month.</li>
            <li>User can review prices and select the suitable payment method as indicated for them on the website</li>
            <li>In the event of cancellation of the subscription, the subscriber cannot recover his/her subscription and
              benefit from the services. If the user would like to subscribe again, then they require a new
              subscription.
            </li>
            <li>An E-invoice will be generated for each subscription that complies with Zakat, Tax and Customs Authority
              requirements.
            </li>
          </ul>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Scope</Typography>
          </Box>
          <Typography paragraph={true}>
            The Scope of <b>“Takamol Integration Platform”</b> Shall be limited to Enabling the Subscribed user to
            Browse Takamol or its partners Services, through the product Details and Documentation provided on the
            platform to integrate and subscribe within the scope of the subscribed user work and through the agreed upon
            subscription plans and payment method.
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>User Declaration</Typography>
          </Box>
          <Typography paragraph={true}>
            You acknowledge and agree to abide by these Terms of Use and acknowledge to:
          </Typography>
          <Typography paragraph={true}>
            Maintain your login information - your username, password and any other information-that will be provided to
            you after you create the user account confidentially and not disclose it to a third party, as part of our
            privacy and security procedures.
          </Typography>
          <Typography paragraph={true}>
            Agree that your use of our site is subject to these terms and all local and national laws, rules, and
            regulations, as well as copyrights and data transfer laws.
          </Typography>
          <Typography paragraph={true}>
            Know that We have the authority to disable any user account or password, whether chosen by you or assigned
            to you by us, at any time; and without providing reasons for doing so.
          </Typography>
          <Typography paragraph={true}>
            You have the authority and the legal capacity to make these conditions binding, and to ensure that all
            information provided and necessary for registration on the site is correct, updated, and complete
            information as required in the platform.
          </Typography>
          <Typography paragraph={true}>
            The user has the responsibility to ensure that all users of the site comply with his or her Internet
            connection on the terms of use and other applicable provisions.
          </Typography>
          <Typography paragraph={true}>
            If the account is used by several persons or on behalf of an entity, the person who has registered the
            account in its name shall be responsible for all activities and shall immediately inform the company of any
            unlawful or unauthorized use of the account. If you suspect that someone else is aware of your user account
            or password, Please notify us at tip@takamol.com.sa If you suspect any breach to your account information.
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Access to our site:</Typography>
          </Box>
          <Typography paragraph={true}>
            Our site is designed for use within Saudi Arabia, and we do not guarantee the availability of our content
            elsewhere. We are entitled to limit the provision of our site and legalize its services to anyone at any
            time. The user also bears full responsibility for entering our website from outside the Kingdom.
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Agreement Period</Typography>
          </Box>
          <Typography paragraph={true}>
            This Agreement is valid from the effective subscription activation date. Subscription renewal shall occur 30
            days prior to expiry date. To avoid suspension fees must be paid prior to the set expiry date.
          </Typography>
          <Typography paragraph={true}>
            Subscription termination shall occur upon surpassing the expiry date of the subscription provisioning or by
            agreement from both parties.
          </Typography>
          <Typography paragraph={true}>
            Takamol reserves all the rights to accept or reject any subscription request with no reason to be applied.
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Service Availability</Typography>
          </Box>
          <Typography paragraph={true}>
            Takamol shall make all effort to provide service continuity and availability 24/7 and during official
            Holidays. However, Service provisioning and continuity shall always be subject to the following conditions.
          </Typography>
          <ol>
            <li>The service provisioning is reliant on the service connection provided by the local internet service
              providers or relevant authorities providing the subscribed service data and, on the systems, related to
              them or the subscriber existing system which Takamol shall bears no responsibility for.
            </li>
            <li>Takamol has the right to suspend the service temporarily from time to time for means of Emergency
              maintenance.
            </li>
          </ol>
          <Typography paragraph={true}>
            <i>Force Majeure</i>
          </Typography>
          <Typography paragraph={true}>
            Takamol Integration Platform shall not be held responsible under the exceptional Economic or natural
            Circumstances that occur during the implementation of the subscription. Such as hurricanes, flooding, fires
            or in case of war, action of foreign Enemies, labor dispute, Embargo or any that are deemed as an act of
            God. These circumstances may cause the parties to suffer an intolerable loss, that exceeds the ongoing
            economics of the Agreement.
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Intellectual Property Rights</Typography>
          </Box>
          <ol>
            <li>Takamol Company owns all intellectual property rights for our site, and all materials published in it
              are subject to the laws of copyright protection, the trademark system, the patent system of the GCC
              countries, the international treaties, and all other related regulations.
            </li>
            <li>You are entitled to print one copy and download parts / quotations from the content of our site for your
              personal use and share that content with other people in your organization after obtaining the written
              consent of Takamol.
            </li>
            <li>You may not modify the paper or digital copies of any material you have printed or downloaded in any
              way, and may not use any illustrations, photographs, series of video, audio, or other graphics.
            </li>
            <li>You may not use any part of the content of our Site for commercial purposes without permission to do so
              from us or from our authorized representatives.
            </li>
            <li>If you print, copy, or download any part of our site in violation of the terms of use, your right to use
              our site will be immediately suspended and you will have to return or destroy the material you copied.
            </li>
          </ol>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Not relying on information</Typography>
          </Box>
          <Typography paragraph={true}>
            Despite the efforts made to update our website, we do not guarantee or guarantee, directly or indirectly,
            the accuracy, timeliness and completeness of our content and we are not obliged to update it. You must
            obtain professional or specialized advice before making any decision or reversing a decision based on the
            content of our site.
          </Typography>
          <Typography paragraph={true}>
            We do not guarantee that the content of our site will meet your needs or expectations and we do not
            guarantee the accuracy, completeness or quality of our site or its content or continuity that provides some
            of its parts and we do not guarantee the absence of our site or any part of its contents from error or
            omission.
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>The limits of our responsibility</Typography>
          </Box>
          <ol>
            <li>
              To the maximum extent permitted by law, Takamol company, its employees, agents, and affiliates are not
              responsible for any loss or damage whether direct, indirect, episodic, or private, including but not
              limited to:
              <ul>
                <li>Losses or damages resulting from loss of profits and public trust, business, opportunity, or data.
                </li>
                <li>Any other non-material losses arising out of or in connection with your use of the Service or these
                  Terms of Use.
                </li>
                <li>Any violation of any of the regulations in force in the Kingdom of Saudi Arabia.</li>
              </ul>
            </li>
            <li>In the event of abuse by the user, Takamol shall take all legal action against him.</li>
            <li>We will not be liable for any loss or damage caused by viruses, denial of service attacks or other
              harmful material that may affect your computer, its software, data, or other materials obtained while you
              use our site or share any content through it, Associated with it.
            </li>
            <li>Takamol does not assume any responsibility for the suspension of the service for any reason and we do
              not guarantee the continuity of our site or any part of its content. We are entitled to suspend, block,
              stop or change all or part of our site without prior notice. Our site is available for any reason at any
              time.
            </li>
            <li>The User accepts full responsibility (and that Takamol assumes no liability to the User or to any third
              party) for any breach of these Terms of Use or any applicable law or regulation and the User shall bear
              any other consequences (including any damage or loss) Or any third party) for any such breach, and Takamol
              Company shall have the right to take the necessary action and refer to the user in the event of any
              damage, whether direct or indirect.
            </li>
            <li>Takamol is not responsible for the accuracy of the used content and / or provided by you or by any third
              party to our site.
            </li>
          </ol>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Data Privacy and confidentiality</Typography>
          </Box>
          <ol>
            <li>Takamol shall not bear any responsibility for any of the problems that may result due to the correctness
              or updating of the information provided to the Subscriber by the Relevant Authorities through the service.
            </li>
            <li>All information and data received in TIP services is confidential, and the Subscriber shall take all
              measures not to misuse it, disclose it to third parties, sell, store it in their systems or assign it to
              any other party. In the event of a violation of this, Takamol is entitled to terminate the subscription
              and the Subscriber shall pay the necessary compensation for any damages or losses due to such violation,
              as determined by the laws in the Kingdom of Saudi Arabia. Further, the Subscriber shall pay all the fines
              imposed against it by the Competent or Relevant Authorities.
            </li>
            <li>The Subscriber shall not enable non-employees to use the service and shall ensure that the service is
              used only by the employees of the Subscriber.
            </li>
            <li>The Subscriber shall solely be responsible for the use of the service, whether such service is used
              directly by the Subscriber, any of its employees, or others.
            </li>
            <li>Takamol may store data entered by the Subscriber through the service to view and disclose such
              information and data to the Competent Authority and Relevant Authorities as required by the business
              interest, and Takamol may use such information and data for statistical purposes.
            </li>
            <li>Takamol may store consumption data of TIP services for statistical purposes, service monitoring and
              invoice generation.
            </li>
          </ol>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Linking our site</Typography>
          </Box>
          <ol>
            <li>You have the right to link to our home page, considering the right to do so in a fair and legal manner
              that does not harm the reputation of our site.
            </li>
            <li>This linkage shall not be construed as a form of partnership or approval in a manner that suggests in
              any form of our participation or our express or implied consent.
            </li>
            <li>The link between our site and any website that you do not own is prohibited.</li>
            <li>You should not post any of our content on any other site, nor may you link to any part of our site
              except the homepage.
            </li>
            <li>We have the right to withdraw your license without prior notice.</li>
            <li>The site which is linked to our site shall be included in all the content standards stipulated in these
              conditions.
            </li>
          </ol>
          <Typography paragraph={true}>
            If you would like to use our website other than the above, please contact us at <a
            href="mailto:info@tiptech.sa">info@tiptech.sa</a> .
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Links and other parties' sources on our
              site</Typography>
          </Box>
          <Typography paragraph={true}>
            Our site contains links to other sites and sources provided by third parties, restricting your use of these
            links to view only.
          </Typography>
          <Typography paragraph={true}>
            We do not have the power to control the content of these sites or sources.
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Compensation</Typography>
          </Box>
          <Typography paragraph={true}>
            By using our site, you agree to indemnify, and hold harmless Takamol Business Services Holdings, our
            subsidiaries, affiliates, officers, directors, agents and employees from and against any claims, actions or
            claims of third parties arising out of or in connection with your use of our Site in any way, including any
            liability or expense arising from All or some of the claims, losses, damages (actual and consequential),
            judicial claims, judgments, costs of litigation and attorneys' fees. In this case, we will provide you with
            written notice of this claim, case, or action.
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Changes in Conditions</Typography>
          </Box>
          <Typography paragraph={true}>
            These terms and conditions are subject to change at any time by modifying this page so please check this
            page from time to time. The new terms are binding on you from the date of publication on our website.
          </Typography>
        </Box>
        <Box mb={5}>
          <Box mb={1}>
            <Typography variant="h2" className={classes.primary}>Applicable Law</Typography>
          </Box>
          <Typography paragraph={true}>
            These terms, conditions, representations, subject matter, and composition are subject to the provisions of
            the laws and regulations in force in the Kingdom of Saudi Arabia, if any of these terms and conditions is
            deemed void, invalid, or unenforceable under the applicable laws and regulations in the Kingdom of Saudi
            Arabia, this will not affect the validity.
          </Typography>
          <Typography paragraph={true}>
            The rest of the provisions and clauses are mandatory for users of the platform, and the courts of the
            Kingdom of Saudi Arabia have exclusive jurisdiction in this regard.
          </Typography>
        </Box>
      </Box>
      <Button onClick={handleClose} fullWidth id="ok">
        {t('buttons.ok')}
      </Button>
    </Box>
  );
};

export default TermsModal;
