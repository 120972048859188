import React, { useEffect, useState } from 'react';
import classNames from 'classnames'
import { Box, Typography } from '@material-ui/core'
import { ReactComponent as CheckIcon } from '../../assets/images/check-icon.svg'
import { ReactComponent as CopyIcon } from '../../assets/images/copy-icon.svg'
import { makeStyles } from '@material-ui/core/styles'

type CopyFieldProps = {
  value: string
  isMasked?: boolean,
  onCopy?: () => void
}

const useStyles = makeStyles((theme) => ({
  copyField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    background: theme.UP.content.background,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 6,
    cursor: 'pointer',

    '&.copied': {
      borderColor: theme.UP.content.success,
    },
  },
  url: {
    color: '#3ABBC1',
    overflow: 'hidden',
    wordBreak: 'break-word',
    '&.masked': {
      fontSize: 34,
      whiteSpace: 'nowrap',
    },
  }
}))

const CopyField = (props: CopyFieldProps) => {
  const { onCopy, value, isMasked } = props
  const classes = useStyles()
  const [ isCopied, setIsCopied ] = useState<boolean>(false)


  const handleCopy = () => {
    setIsCopied(true)
    navigator.clipboard.writeText(value)
    if (onCopy) onCopy()
  }

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => setIsCopied(false), 3000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [ isCopied ])

  return (
    <Box
      className={classNames(classes.copyField, { ['copied']: isCopied })}
      onClick={handleCopy}
    >
      <Typography
        variant='subtitle2'
        className={`${classes.url} ${isMasked ? 'masked' : ''}`}>
          {isMasked ? value.replace(/./g, '•') : value}
      </Typography>
      <Box ml={2} width={19} height={28} display='flex' alignItems='center' justifyContent='center'>
        {isCopied ? <CheckIcon/> : <CopyIcon/>}
      </Box>
    </Box>
  );
};

export default CopyField;
