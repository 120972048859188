import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  '@keyframes lds-dual-ring': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'}
  },
  preloaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
    background: theme.UP.type === 'light' ? '#6293a7' : '#031C25',
  },
  preloader: {
    margin: '16px 0 0',
    display: 'inline-block',
    verticalAlign: 'top',
    width: 82,
    height: 82,
    '&:after': {
      content: '""',
      display: 'block',
      width: 74,
      height: 74,
      margin: 8,
      borderRadius: '50%',
      border: `6px solid ${theme.UP.common.white}`,
      borderColor: `${theme.UP.common.white} transparent ${theme.UP.common.white} transparent`,
      animation: '$lds-dual-ring 1.2s linear infinite',
    },
  },
}))

const PagePreloader = ({ opacity = 1 }: { opacity?: number }) => {
  const classes = useStyles();
  return (
    <Box style={{ opacity }} className={classes.preloaderWrapper}>
      <div className={classes.preloader}/>
    </Box>
  );
};

export default PagePreloader;
