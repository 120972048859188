import React, { FC } from 'react';
import { DataGrid, IDataGridProps } from "@takamol/unified-components";
import NoFoundOverlay from "./NoFoundOverlay";
import ErrorOverlay from "./ErrorOverlay";
import DataGridLoader from "./DataGridLoader";
import { GridSortModel } from '@material-ui/data-grid'


interface DataGridWithOverlays extends IDataGridProps {
  noFoundTitle?: string;
  noFoundText?: string;
  errorTitle?: string;
  errorText?: string;
  onRefresh?: () => void;
  sortModel?: GridSortModel;
}

const DataGridWithOverlays: FC<DataGridWithOverlays> = (props) => {
  const {
    noFoundTitle,
    noFoundText,
    rows,
    autoHeight,
    errorTitle = '',
    errorText = '',
    onRefresh = () => null,
    loading,
  } = props;
  return (
    <DataGrid
      NoRowsOverlay={() => <NoFoundOverlay title={noFoundTitle} text={noFoundText}/>}
      ErrorOverlay={() => <ErrorOverlay title={errorTitle} text={errorText} onRefresh={onRefresh}/>}
      LoadingOverlay={() => <DataGridLoader/>}
      {...props}
      autoHeight={rows.length && !loading ? autoHeight : false}
    />
  );
};

export default DataGridWithOverlays;
