import React from 'react';
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary
  },
  securityItems: {
    display: 'flex',
    flexWrap: 'wrap',
    gridGap: theme.spacing(3),
    width: '100%',
  },
}))

const SecurityItems = (props: { securityItems: string[] }) => {
  const classes = useStyles()

  return (
    <Box className={classes.securityItems}>
      {props.securityItems.map((item, index) => (
        <Box key={index}>
          <Typography variant='subtitle1' className={classes.primary}>
            {item}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SecurityItems;
