import { PaletteType } from '@material-ui/core'

// A custom theme
const theme = {
  typography: {
    fontFamily: `Almarai, sans-serif`,
    fontSize: 14,
    h1: {
      fontSize: "27px",
      lineHeight: "35px",
      fontWeight: 700,
      fontStyle: "normal",
    },
    h2: {
      fontSize: "20px",
      lineHeight: "27px",
      fontWeight: 800,
      fontStyle: "normal",
    },
    h3: {
      fontSize: "18px",
      lineHeight: "25px",
      fontWeight: 700,
      fontStyle: "normal",
    },
    h4: {
      fontSize: "16px",
      lineHeight: "23px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    h5: {
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    h6: {
      fontSize: "12px",
      lineHeight: "21px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    subtitle1: {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 700,
      fontStyle: "normal",
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 400,
      fontStyle: "normal",
    },
    body1: {
      fontSize: "16px",
      lineHeight: "23px",
      fontWeight: 700,
      fontStyle: "normal",
    },
  },
  UP: {
    seed: '',
    type: 'light' as PaletteType,
    background: {
      default: {
        light: '#F1F3F4',
        dark: '#141517',
      },
      paper: {
        light: '#FFFFFF',
        dark: '#1A2632',
      },
    },
    sidebar: {
      navigation: {
        active: {
          light: '#FFFFFF',
          dark: '#FFFFFF',
        },
        inactive: {
          light: '#B6CBD2',
          dark: '#B6CBD2',
        },
      },
      background: {
        light: '#025373',
        dark: '#1A2632',
      },
      switcher: {
        light: '#FFFFFF',
        dark: '#24262B',
      },
    },
    modal: {
      background: {
        light: '#FFFFFF',
        dark: '#1A2632',
      },
      layout: {
        light: 'rgba(3, 20, 56, 0.6)',
        dark: 'rgba(12, 14, 20, 0.6)',
      },
    },
    content: {
      primary: {
        light: '#025373',
        dark: '#FFFFFF',
      },
      secondary: {
        light: '#169786',
        dark: '#49964D',
      },
      background: {
        light: '#F8F9FA',
        dark: '#1E3139',
      },
      border: {
        light: '#E9EDF3',
        dark: '#45474B',
      },
      success: {
        light: '#8BCEA4',
        dark: '#8BCEA4',
      },
      error: {
        light: '#C4262D',
        dark: '#C4262D',
      },
      gray: {
        light: '#6C7293',
        dark: '#CFE8FF',
      },
      lightGray: {
        light: '#E9EDF3',
        dark: '#364952',
      },
      paleGray: {
        light: '#FBFBFD',
        dark: '#1E3139',
      },
    },
    common: {
      alternativeBlue: '#8590B3',
      alternativeGreen: {
        light: '#105E66',
        dark: '#49964D'
      },
      alternativeGreenLight: '#8BCEA4',
      alternativeGreenDark: {
        light: '#6293a7',
        dark: '#f00',
      },
      grayDark: '#6E7880',
      white: '#FFFFFF',
      black: '#000000',
      green: {
        light: '#8BCEA4',
        dark: '#1CC4AE'
      },
      greenLight: '#169786',
      greenDark: '#105E66',
      gray: '#6C7293',
    },
    controls: {
      pagination: {
        items: {
          light: '#B9BABF',
          dark: '#5C656C'
        }
      },
      input: {
        text: {
          default: { light: '#0D5068', dark: '#FFFFFF' },
        },
        background: {
          default: { light: '#FFFFFF', dark: '#1A2632' },
          disabled: { light: '#F8F9FA', dark: '#1E3139' },
        },
        border: {
          active: { light: '#7EC0FD', dark: '#45474B' },
          inactive: { light: '#E9EDF3', dark: '#45474B' },
          disabled: { light: '#E9EDF3', dark: '#45474B' },
        },
      },
      button: {
        background: {
          default: { light: '#169786', dark: '#49964D' },
          disabled: { light: '#E9EDF3', dark: '#181A20' },
        },
        text: {
          default: { light: '#FFFFFF', dark: '#FFFFFF' },
          disabled: { light: '#A1A5B8', dark: '#A1A5B8' }
        }
      },
      dropdown: {},
      error: {
        text: '#C4262D',
        border: '#C4262D',
        background: '#C4262D',
      },
      success: {
        text: '#8BCEA4',
        border: '#8BCEA4',
        background: '#8BCEA4',
      },
      charts: {
        primary: {
          light: { startColor: '#23B899', stopColor: '#23B899' },
          dark: { startColor: '#97577C', stopColor: '#773D5F' },
        },
        secondary: {
          light: { startColor: '#387DFF', stopColor: '#80ACFF' },
          dark: { startColor: '#39354E', stopColor: '#252235' },
        },
        dashedLine: {
          light: '#EAEBF0',
          dark: '#45474B',
        },
      },
    },
  },
}

export default theme
