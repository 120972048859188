import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAppDispatch, useAppSelector } from '../../store/root'
import { getUserData, sendVerification } from '../../store/user'
import { Block, Button, TextButton } from '@takamol/unified-components'
import PagePreloader from '../../components/shared/PagePreloader'
import Divider from '../../components/shared/Divider'
import { resetState, showModal } from '../../store/modalManager'
import OtpAbsherModal from '../../components/profile/OtpAbsherModal'
import { ReactComponent as EditIcon } from '../../assets/images/icon-edit.svg'
import ChangePasswordModal from '../../components/profile/ChangePasswordModal'

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary,
  },
  secondary: {
    color: theme.UP.content.secondary,
  },
  infoWrapper: {
    background: theme.UP.content.background,
    padding: theme.spacing(3),
    border: `1px solid ${theme.UP.content.border}`,
    borderRadius: 8,
  },
  pageTitle: {
    marginBottom: theme.spacing(3),
    color: theme.UP.content.primary,
  },
  infoTitle: {
    marginBottom: theme.spacing(3),
    color: theme.UP.content.primary,
  },
  infoItemTitle: {
    margin: `${theme.spacing(3)}px 0 5px`,
    color: theme.UP.content.primary,
  },
  btnEdit: {
    ...theme.typography.h5,
    color: theme.UP.content.secondary + ' !important',
    padding: 0,
    marginLeft: 10,
    verticalAlign: 'baseline',
    '& svg': {
      marginLeft: 8
    },
    '& *': {
      color: 'inherit',
    },
  },
  btnChange: {
    marginTop: theme.spacing(3),
    width: 260,
    background: theme.UP.type === 'light'
        ?  `${theme.UP.content.primary} !important`
        : `#4078B7 !important`,
    color: theme.UP.common.white,
    '@media (max-width:550px)': {
      width: '100%',
    },
  },
}))

const Profile = () => {
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const { loading, name, nationalId, phoneNumber, birthDate, error } = useAppSelector(state => state.user)

  const handleEditPhoneNumber = () => {
    dispatch(sendVerification(nationalId))
    dispatch(showModal({
      variant: 'custom',
      children: <OtpAbsherModal/>,
      maxWidth: 561,
      onClose: () => {
        dispatch(resetState())
      }
    }))
  }

  const formatPhoneNumberUI = (phone: string) => {
    if (!phone) return '';
    if (phone.length === 8) {
      return `+966-5-${phone}`;
    }
    return phone.replace(/(009665)/ig, '+966-5-');
  }

  const handleEditPassword = () => {
    dispatch(showModal({
      variant: 'custom',
      children: <ChangePasswordModal/>,
      maxWidth: 561,
      onClose: () => {
        dispatch(resetState())
      }
    }))
  }

  useEffect(() => {
    dispatch(getUserData())
  }, [ dispatch ])

  return (
    <Layout>
      <Block>
        {loading && <PagePreloader/>}
        <Typography className={classes.pageTitle} variant="h2">
          {t('profile.title')}
        </Typography>
        <Box className={classes.infoWrapper}>
          <Typography className={classes.infoTitle} variant="h2">
            {t('profile.subTitle')}
          </Typography>
          <Divider />
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className={classes.infoItemTitle} variant="subtitle2">
                {t('profile.nameLabel')}
              </Typography>
              <Typography className={classes.primary} variant="subtitle1">
                {name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className={classes.infoItemTitle} variant="subtitle2">
                {t('profile.nidLabel')}
              </Typography>
              <Typography className={`${classes.primary} arial`} variant="subtitle1">
                {nationalId}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className={classes.infoItemTitle} variant="subtitle2">
                {t('profile.dobLabel')}
              </Typography>
              <Typography className={`${classes.primary} arial`} variant="subtitle1">
                {birthDate}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className={classes.infoItemTitle} variant="subtitle2">
                {t('profile.phoneLabel')}
              </Typography>
              <Typography className={classes.primary} variant="subtitle1">
                <span
                    style={{direction: "ltr", unicodeBidi: "bidi-override"}}
                    className="arial"
                >
                  {formatPhoneNumberUI(phoneNumber || '')}
                </span>
                <TextButton className={classes.btnEdit} id='editPhoneNumber' onClick={handleEditPhoneNumber}>
                  {t('profile.edit')}
                  <EditIcon/>
                </TextButton>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                  onClick={handleEditPassword}
                  className={classes.btnChange}
                  disabled={loading} id='changePassword'
                  type='button'
              >
                {t('profile.changePasswordButton')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Block>
    </Layout>
  )
}

export default Profile
