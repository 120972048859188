import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { localization } from '@takamol/up-localization/src';

// tslint:disable:max-line-length
const resources = {
  en: {
    translation: {
      ...localization.en,
      commonTip: {
        sessionExpired: 'If you are done, we will close the session',
      },
      subscriptions: {
        title: 'Subscriptions Management',
        id: 'Subscription ID',
        name: 'Name',
        productName: 'API product name',
        company: 'Establishment name',
        creationDate: 'Creation Date',
        expiryDate: 'Expiry Date',
        rateLimit: 'Rate Limit',
        requestsPerSec: '<0>{{limit}}</0> requests/sec',
        modalRequestsPerSec: '<0>{{limit}}</0> requests/sec',
        productPrice: 'Product price',
        pay: 'Pay',
        details: 'Details',
        status: 'Status',
        action: 'Action',
        requested: 'Requested',
        approved: 'Approved',
        rejected: 'Rejected',
        paid: 'Paid',
        active: 'Active',
        expired: 'Expired',
        activate: 'Activate',
        namePlaceholder: 'Enter API Product Name',
        subscriptionIdPlaceholder: 'Enter Subscription ID',
        establishmentPlaceholder: 'Enter Establishment Name',
        approve: 'Approve',
        reject: 'Reject',
        rejectModalTitle: 'Request rejected',
        rejectReason: 'Rejection reason',
        summaryModalTitle: 'Subscription Summary',
        areYouSureToApprove: 'Are you sure you want to approve request ID <0>{{id}}</0> ?',
        areYouSureToReject: 'Are you sure you want to reject request ID <0>{{id}}</0> ?',
        areYouSureToActivate: 'Are you sure you want to activate request ID <0>{{id}}</0> ?',
        clientIdLabel: 'Client ID',
        clientSecretLabel: 'Secret Key',
        clientIdPlaceholder: 'Enter Client ID',
        clientSecretPlaceholder: 'Enter Secret Key',
        enterReason: 'Please enter rejection reason:',
        reasonLabel: 'Enter the reason for rejection',
        approveAction: 'Approve request',
        rejectAction: 'Reject request',
        activateAction: 'Activate request',
        successfullyApproved: 'You have successfully approved request',
        successfullyRejected: 'You have successfully rejected request',
        successfullyActivated: 'You have successfully activated request',
        actionViewTooltip: 'View Details',
        actionInvoiceTooltip: 'View Invoice'
      },
      summary: {
        title: 'Summary Page',
        summaryDetails: 'Summary details',
        productName: 'API Product Name',
        productPrice: 'API Product Price',
        vat: 'VAT',
        limit: 'Rate Limit',
        totalPrice: 'Total Price',
        agreeWith: 'I agree with',
        terms: ' Terms and Conditions',
        pay: 'Pay'
      },
      products: {
        productBtn: 'Product details ',
        title: 'Explore API Products',
        baseInfo: 'Establishment Basic Info&Status',
        productPriceSubtitle: 'API Product Price',
        apiIncluded: 'APIs included',
        generalInfo: 'General Information',
        type: 'Type',
        endpoint: 'Endpoint',
        security: 'Security',
        clientID: 'ClientID',
        typeClientId: '(Type: Client ID)',
        parameters: "Parameters",
        subscribeToProduct: 'Subscribe to this product',
        basicPlan: 'Basic Plan',
        subscribe: 'Subscribe',
        requestService: 'Request service',
        subscriptions: 'Subscriptions',
        subscribeSubTitle: 'Please fill and review below details to submit your request',
        exampleRequest: 'Example request',
        responses: 'Responses',
        successfulOperation: 'The operation was successful.',
        productName: 'Product Name',
        estName: 'Establishment Name',
        estNumber: 'Establishment number',
        email: 'Email',
        emailPlaceholder: 'Enter your email',
        rateLimit: 'Rate Limit',
        apiProductPrice: 'API Product Price',
        currency: 'SAR',
        requestUnderReview: 'Your request has been submitted successfully and is currently under review.',
        required: 'required',
        name: 'Name',
        description: 'Description',
        headers: 'Headers',
        body: 'Body'
      },
      profile: {
        title: 'User Profile',
        subTitle: 'Profile information',
        nameLabel: 'Full Name',
        nidLabel: 'National ID',
        dobLabel: 'Date of birthday',
        phoneLabel: 'Phone number',
        modalTitlePhone: 'Change phone number',
        modalTitlePassword: 'Provide new password',
        modalSubTitleAbsher: 'The verification code has been sent to your registered mobile number in Absher',
        modalSubTitleOtp: 'Please type the verification code sent to <0>{{phone}}</0>',
        modalSubTitlePhone: 'Please make sure that you entered the right mobile number, a verification code will be sent to the mobile number in each login.',
        otpAbsherLabel: 'Confirmation Code',
        otpAbsherPlaceholder: 'Enter Confirmation Code',
        otpLabel: 'Verification code',
        otpPlaceholder: 'Enter verification code',
        phoneNumberLabel: 'Phone number',
        phoneNumberPlaceholder: '12345678',
        verify: 'Verify',
        next: 'Next',
        back: 'Back to profile page',
        save: 'Save changes',
        savePassword: 'Save new password',
        edit: 'Edit',
        resend: 'Resend SMS code',
        modalPhoneSuccess: 'Phone number has been updated successfully',
        modalPasswordSuccess: 'Password was changed successfully',
        passwordLabel: 'Your current password',
        passwordPlaceholder: 'Enter your current password',
        newPasswordLabel: 'Your new password',
        newPasswordPlaceholder: 'Enter your new password',
        passwordConfirmationLabel: 'Confirm the new password',
        passwordConfirmationPlaceholder: 'Confirm the new password',
        changePasswordButton: 'Change password'
      },
      filtersTip: {
        filterStatus: 'Filter by Status',
        filterSubmit: 'Filter by Creation Date',
        searchName: 'Search by API Product Name',
        establishmentSearchName: 'Search by Establishment Name',
        searchSubscriptionId: 'Search by Subscription ID',
        clearAll: 'Clear all'
      },
      landing: {
        heroTitle: 'Simplified access to all the data you need!',
        heroText: 'Reliable government data to help you develop and create new business opportunities - now provided by easy-to-access APIs',
        exploreProducts: 'Explore products',
        featuresTitle: 'We will help you empower your business with data',
        featureTitle1: 'Easy integration',
        featureText1: 'User friendly interface and clear documentation provides easy access to the data.',
        featureTitle2: 'Data quality',
        featureText2: 'Validated government data with reliable and up-to-date information.',
        featureTitle3: 'Documentation',
        featureText3: 'Clear and consistent developer documentation helps with smooth integration.',
        featureTitle4: 'Security',
        featureText4: 'We maintain the highest standards of security for our users.',
        gettingStartedTitle: 'Getting started',
        gettingStartedSubTitle: 'How to start using the TIP Portal? \nIt’s very simple!',
        gettingStartedListItem1: 'Register in our portal',
        gettingStartedListItem2: 'Explore our API products',
        gettingStartedListItem3: 'Subscribe to the desired product',
        contactTitle: 'Any questions about subscriptions?',
        contactText: 'If you have any questions, don’t hesitate to e-mail us!'
      },
      actions: {
        login: 'Login',
        signUp: 'Sign up',
        logout: 'Logout',
        confirm: 'Confirm',
        cancel: 'Cancel',
        keepSession: 'Keep session',
        endSession: 'End session',
        requestService: 'Request service',
        exploreProducts: 'Explore API products',
        backToProducts: 'Back to API Products',
      },
      sidebarLinks: {
        products: 'API Products',
        subscriptions: 'Subscriptions',
      },
      workspacesTip: {
        title: 'Change active workspace',
        estNumber: 'Establishment number',
        confirmModalTitle: 'Confirm action',
        defaultCompany: 'as a default company?',
        switch: 'Are you sure you want to switch to this Establishment {{name}}?',
        emptyWorkspaces: 'There is not any available establishment'
      },
      notifications: {
        subTitleWithLink: 'Click <0>here</0> for more info',
      },
      errorsTip: {
        emailUsedInAnotherEstablishment: 'Email already in use. Please enter an email that is not associated with any establishment.',
        emailUseAnotherUser: 'Email already in use. Please enter an email that is not associated with any establishment.',
        emailNotValid: 'Email is not valid. Please enter the actual establishment\'s email.',
        phoneNotValid: 'Invalid phone number',
        "national-id-error-1": "Invalid National ID",
        "iqama-id-error-1": "The Iqama Id Is Not Valid",
        "session-policy-1": "Absher verification is not activated",
        "session-policy-2": "Session is not available",
        "session-policy-3": "Action is available only for guest",
        "session-policy-4": "Available only for Guest with HSM",
        "session-policy-5": "Action is available only for user",
        "unifonic-error-1": "Sorry, you have entered an incorrect code 3 times, please click on the link above to receive a new code.",
        "unifonic-error-2": "You entered the wrong code. Please, try again",
        "unifonic-error-3": "Code was expired",
        "absher-error-1": "Sorry, you have entered an incorrect code 3 times, please click on the link above to receive a new code.",
        "absher-error-2": "You entered the wrong code. Please, try again",
        "absher-error-3": "Attempt is expired",
        "absher-error-4": "Sorry, you are not registered in Absher",
        "login-error-1": "Login or password is incorrect",
        "account-error-1": "Password and confirmation password should be equal",
        "account-error-2": "User is already registered",
        "account-error-3": "User is already registered",
        "account-error-4": "User not found",
        "change-password-error-1": "Sorry, you can't use your old password",
        "change-password-error-2": "You entered the wrong password",
        "change-password-error-3": "Your password should be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a symbol",
        "change-password-error-4": "Password and confirmation password should be equal",
        "change-password-error-5": "Your password should be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a symbol",
        "change-password-error-6": "Your password should be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a symbol",
        "change-password-error-7": "Your password should be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a symbol",
        "reset-password-error-1": "Action is not available",
        "reset-password-error-2": "Sorry, you can't use your old password",
        "reset-password-error-3": "Password and confirmation password should be equal",
        "reset-password-error-4": "Password is too short",
        "reset-password-error-5": "Your password should be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a symbol",
        "reset-password-error-6": "Your password should be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a symbol",
        "password-error-1": "Your password should be at least 8 characters long and contain an uppercase letter, a lowercase letter, a number, and a symbol",
        "password-error-2": "Password and confirmation password should be equal",
        "internal-schema-error": "Something went wrong",
        "user-error-1": "User not found",
        "user-error-2": "We didn't find any establishment associated with you. \nThe registration cannot be completed because no establishments connected to you have been found",
        "phone-error-1": "The phone number you entered is already used in TIP",
        "birth-date-error-1": "Supplied Birth date is not correct",
        "birth-date-error-2": "No data found",
        "hijri-date-error-1": "Hijri date is invalid",
        "gregorian-date-error-1": "Gregorian date is invalid"
      },
      errors: {
        ...localization.en.errors,
        "not_in_status_approved": 'Sorry, we can\'t proceed with this request because the subscription is not in the "Approved" status',
        "payment_status_paid": 'The subscription is already paid',
      },
    }
  },
  ar: {
    translation: {
      ...localization.ar,
      commonTip: {
        sessionExpired: 'في حال انتهاءك، سيتم إنهاء الجلسة خلال',
      },
      subscriptions: {
        title: 'إدارة الاشتراكات',
        id: 'رقم الاشتراك',
        name: 'الاسم',
        productName: 'اسم المنتج',
        company: 'اسم المنشأة',
        creationDate: 'تاريخ الاشتراك',
        expiryDate: 'تاريخ الانتهاء',
        rateLimit: 'الحد الأعلى',
        requestsPerSec: '<0>{{limit}}</0> استعلامات \\ بالثانية',
        modalRequestsPerSec: '<0>{{limit}}</0> استعلامات \\ بالثانية',
        productPrice: 'سعر المنتج',
        pay: 'الدفع',
        details: 'تفاصيل',
        status: 'الحالة',
        action: 'الإجراء المتاح',
        requested: 'تم التقديم',
        approved: 'مقبول',
        rejected: 'مرفوض',
        paid: 'مدفوع',
        active: 'فعال',
        expired: 'منتهي',
        activate: 'تفعيل',
        namePlaceholder: 'ادخل اسم المنتج',
        subscriptionIdPlaceholder: 'ادخل رقم الاشتراك',
        establishmentPlaceholder: 'ادخل اسم المنشأة',
        approve: 'قبول',
        reject: 'رفض',
        rejectModalTitle: 'طلب رفض',
        rejectReason: 'سبب الرفض',
        summaryModalTitle: 'تفاصيل الاشتراك',
        areYouSureToApprove: 'هل أنت متأكد من قبول الطلب رقم <0>{{id}}</0> ؟',
        areYouSureToReject: 'هل أنت متأكد من رفض الطلب رقم <0>{{id}}</0> ؟',
        areYouSureToActivate: 'هل أنت متأكد من تفعيل الطلب رقم <0>{{id}}</0> ؟',
        clientIdLabel: 'رقم العميل',
        clientSecretLabel: 'الرمز السري',
        clientIdPlaceholder: 'إدخال رقم العميل',
        clientSecretPlaceholder: 'إدخال الرمز السري',
        enterReason: 'الرجاء إدخال سبب الرفض:',
        reasonLabel: 'الرجاء إدخال سبب الرفض',
        approveAction: 'قبول الطلب',
        rejectAction: 'رفض الطلب',
        activateAction: 'تفعيل الطلب',
        successfullyApproved: 'تم موافقة الطلب بنجاح',
        successfullyRejected: 'تم رفض الطلب بنجاح',
        successfullyActivated: 'تم تفعيل الطلب بنجاح',
        actionViewTooltip: 'عرض التفاصيل',
        actionInvoiceTooltip: 'عرض الفاتورة'
      },
      summary: {
        title: 'Summary Page',
        summaryDetails: 'ملخص الاشتراك',
        productName: 'اسم المنتج',
        productPrice: 'سعر المنتج',
        vat: 'الضريبة المضافة',
        limit: 'الحد الأعلى شهرياً',
        totalPrice: 'السعر الكلي',
        agreeWith: 'أوافق على',
        terms: ' الشروط والأحكام',
        pay: 'الدفع'
      },
      products: {
        productBtn: 'تفاصيل المنتج',
        title: 'تصفح المنتجات',
        baseInfo: 'Establishment Basic Info&Status',
        productPriceSubtitle: 'API Product Price',
        apiIncluded: 'APIs included',
        generalInfo: 'General Information',
        type: 'Type',
        endpoint: 'Endpoint',
        security: 'Security',
        clientID: 'ClientID',
        typeClientId: '(Type: Client ID)',
        parameters: "Parameters",
        subscribeToProduct: 'Subscribe to this product',
        basicPlan: 'Basic Plan',
        subscribe: 'اشترك الآن',
        requestService: 'طلب الخدمة',
        subscriptions: 'الاشتراكات',
        subscribeSubTitle: 'الرجاء مراجعة وتعبئة التفاصيل التالية لإرسال طلبك',
        exampleRequest: 'Example request',
        responses: 'Responses',
        successfulOperation: 'The operation was successful.',
        productName: 'اسم المنتج',
        estName: 'اسم المنشأة',
        estNumber: 'رقم المنشأة',
        email: 'البريد الالكتروني',
        emailPlaceholder: 'يرجى إدخال بريدك الالكتروني',
        rateLimit: 'الحد الأعلى',
        apiProductPrice: 'سعر المنتج',
        currency: 'ريال سعودي',
        requestUnderReview: 'تم تقديم طلبك بنجاح. الطلب حالياً قيد المراجعة.',
        required: 'required',
        name: 'Name',
        description: 'Description',
        headers: 'Headers',
        body: 'Body'
      },
      profile: {
        title: 'ملف المستخدم',
        subTitle: 'معلومات الملف الشخصي',
        nameLabel: 'الاسم الكامل',
        nidLabel: 'رقم الهوية',
        dobLabel: 'تاريخ الميلاد',
        phoneLabel: 'رقم الجوال',
        modalTitlePhone: 'تغيير رقم الجوال',
        modalTitlePassword: 'تغيير كلمة المرور',
        modalSubTitleAbsher: 'تم إرسال رمز التحقق إلى رقم الجوال المسجل في أبشر',
        modalSubTitleOtp: '<0>{{phone}}</0> يرجى ادخال رمز التحقق المرسل الى الرقم ',
        modalSubTitlePhone: 'الرجاء التحقق من إدخال رقم جوال صحيح, سيتم إرسال رمز تحقق عند كل تسجيل دخول',
        otpAbsherLabel: 'رمز التحقق',
        otpAbsherPlaceholder: 'رمز التحقق',
        otpLabel: 'رمز التحقق',
        otpPlaceholder: 'ادخال رمز التحقق',
        phoneNumberLabel: 'رقم الجوال',
        phoneNumberPlaceholder: '12345678',
        verify: 'التحقق',
        next: 'التالي',
        back: 'الرجوع للملف الشخصي',
        save: 'حفظ التغييرات',
        savePassword: 'حفظ التغييرات',
        edit: 'تعديل',
        resend: 'إعادة إرسال',
        modalPhoneSuccess: 'تم تغيير رقم الجوال بنجاح',
        modalPasswordSuccess: 'تم تغيير كلمة المرور بنجاح',
        passwordLabel: 'كلمة المرور الحالية',
        passwordPlaceholder: 'ادخال كلمة المرور الحالية',
        newPasswordLabel: 'كلمة المرور الجديدة',
        newPasswordPlaceholder: 'كلمة المرور الجديدة',
        passwordConfirmationLabel: 'تأكيد كلمة المرور الجديدة',
        passwordConfirmationPlaceholder: 'تأكيد كلمة المرور الجديدة',
        changePasswordButton: 'تغيير كلمة المرور'
      },
      filtersTip: {
        filterStatus: 'تصفية حسب الحالة',
        filterSubmit: 'الحالة تاريخ الاشتراك',
        searchName: 'البحث عن طريق اسم المنتج',
        establishmentSearchName: 'البحث عن طريق اسم المنشأة',
        searchSubscriptionId: 'البحث عن طريق رقم الاشتراك',
        clearAll: 'مسح الكل'
      },
      landing: {
        heroTitle: 'وصول أسهل إلى المعلومات!',
        heroText: 'تتيح منصة تكامل الوصول إلى البيانات الحكومية عبر الربط الإلكتروني الموثوق والآمن لتساعدكم في تطوير أعمالكم',
        exploreProducts: 'تصفح المنتجات',
        featuresTitle: 'ندعمكم بالبيانات التي تساعدكم في تمكين وتطوير أعمالكم',
        featureTitle1: 'تكامل سهل',
        featureText1: 'ربط عبر واجهة آمنة وموثوقة وسهلة الاستخدام للوصول إلى البيانات المطلوبة',
        featureTitle2: 'جودة البيانات',
        featureText2: 'بيانات دقيقة وموثقة ومحدثة من الجهات الحكومية المعنية',
        featureTitle3: 'موثوقية فائقة',
        featureText3: 'بيانات يعتمد عليها للوصول إلى نتائج دقيقة وصحيحة',
        featureTitle4: 'أمان عالٍ',
        featureText4: 'نوفر أعلى معايير الأمان للمحافظة على بيانات المستخدمين',
        gettingStartedTitle: 'احصل على البيانات الآن',
        gettingStartedSubTitle: 'كيف يمكنني الاشتراك في منصة تكامل؟\n يمكنك الاشتراك بخطوات يسيرة',
        gettingStartedListItem1: 'سجل في منصة تكامل',
        gettingStartedListItem2: 'تصفح المنتجات التي توفرها المنصة',
        gettingStartedListItem3: 'الاشتراك بالمنتج المطلوب',
        contactTitle: 'هل لديك سؤال عن منصة تكامل؟',
        contactText: 'إذا كان لديك أي سؤال عن المنصة لا تتردد بالتواصل معنا!'
      },
      actions: {
        login: 'تسجيل الدخول',
        signUp: 'تسجيل جديد',
        logout: 'تسجيل الخروج',
        confirm: 'تأكيد',
        cancel: 'إلغاء',
        keepSession: 'اكمال الجلسة',
        endSession: 'انهاء الجلسة',
        requestService: 'طلب الخدمة',
        exploreProducts: 'تصفح المنتجات',
        backToProducts: 'العودة الى تصفح المنتجات',
      },
      sidebarLinks: {
        products: 'تصفح المنتجات',
        subscriptions: 'الاشتراكات',
      },
      workspacesTip: {
        title: 'تبديل حساب المستخدم',
        estNumber: 'رقم المنشأة',
        confirmModalTitle: 'تأكيد الطلب',
        defaultCompany: 'لتكون المنشأة الافتراضية؟',
        switch: 'هل تود الإنتقال الى هذه المنشأة {{name}}؟',
        emptyWorkspaces: 'لا يوجد منشآت'
      },
      notifications: {
        subTitleWithLink: 'انقر <0/>هنا<0> للمزيد من المعلومات',
      },
      errorsTip: {
        emailUsedInAnotherEstablishment: 'هذا البريد الالكتروني مستخدم من قبل في منشأة اخرى، يرجى إدخال بريد الكتروني مختلف',
        emailUseAnotherUser: 'هذا البريد الالكتروني مستخدم من قبل في منشأة اخرى، يرجى إدخال بريد الكتروني مختلف',
        emailNotValid: 'البريد الالكتروني المدخل غير صحيح، يرجى ادخال البريد لالكتروني الصحيح للمنشأة',
        phoneNotValid: 'رقم الهاتف المدخل غير مكتمل',
        "national-id-error-1": "رقم الهوية المدخله غير صحيحة",
        "iqama-id-error-1": "هوية الإقامة غير صالحة",
        "session-policy-1": "Absher verification is not activated",
        "session-policy-2": "Session is not available",
        "session-policy-3": "Action is available only for guest",
        "session-policy-4": "Available only for Guest with HSM",
        "session-policy-5": "Action is available only for user",
        "unifonic-error-1": "عذرًا ، لقد قمت بإدخال رمز غير صحيح ٣ مرات ، يرجى النقر على الرابط فوق لتلقي الرمز الجديد",
        "unifonic-error-2": "رمز التحقق غير صحيح, الرجاء المحاولة مرة اخرى",
        "unifonic-error-3": "انتهت صلاحية الرمز",
        "absher-error-1": "عذرًا ، لقد قمت بإدخال رمز غير صحيح ٣ مرات ، يرجى النقر على الرابط فوق لتلقي الرمز الجديد",
        "absher-error-2": "رمز التحقق غير صحيح, الرجاء المحاولة مرة اخرى",
        "absher-error-3": "Attempt is expired",
        "absher-error-4": "عذرا، أنت غير مسجل في أبشر",
        "login-error-1": "الرجاء التحقق من اسم المستخدم او كلمة المرور",
        "account-error-1": "يجب أن تكون كلمة المرور وكلمة المرور للتأكيد متساوية",
        "account-error-2": "هذا المستخدم مسجل من قبل",
        "account-error-3": "هذا المستخدم مسجل من قبل",
        "account-error-4": "رقم الهوية المدخل غير صحيح/غير مسجل في قوى",
        "change-password-error-1": "عفوا، لا يمكنك استخدام كلمة المرور مستخدمة مسبقا",
        "change-password-error-2": "لقد أدخلت كلمة مرور خاطئة",
        "change-password-error-3": "يجب ان تتكون كلمة المرور من 8 أحرف على الاقل وتحتوي على حرف كبير وحرف صغير ورقم ورمز",
        "change-password-error-4": "يجب أن تكون كلمة المرور وكلمة المرور للتأكيد متساوية",
        "change-password-error-5": "يجب ان تتكون كلمة المرور من 8 أحرف على الاقل وتحتوي على حرف كبير وحرف صغير ورقم ورمز",
        "change-password-error-6": "العديد من الأحرف المتكررة في كلمة المرور",
        "change-password-error-7": "لا توجد أحرف كافية في كلمة المرور",
        "reset-password-error-1": "Action is not available",
        "reset-password-error-2": "عفوا، لا يمكنك استخدام كلمة المرور مستخدمة مسبقا",
        "reset-password-error-3": "يجب أن تكون كلمة المرور وكلمة المرور للتأكيد متساوية",
        "reset-password-error-4": "يجب ان تتكون كلمة المرور من 8 أحرف على الاقل وتحتوي على حرف كبير وحرف صغير ورقم ورمز",
        "reset-password-error-5": "يجب ان تتكون كلمة المرور من 8 أحرف على الاقل وتحتوي على حرف كبير وحرف صغير ورقم ورمز",
        "reset-password-error-6": "يجب ان تتكون كلمة المرور من 8 أحرف على الاقل وتحتوي على حرف كبير وحرف صغير ورقم ورمز",
        "password-error-1": "يجب ان تتكون كلمة المرور من 8 أحرف على الاقل وتحتوي على حرف كبير وحرف صغير ورقم ورمز",
        "password-error-2": "يجب أن تكون كلمة المرور وكلمة المرور للتأكيد متساوية",
        "internal-schema-error": "مخطط التحقق من صحة الخط",
        "user-error-1": "المستخدم ليس موجود",
        "user-error-2": "لم يتم العثور على اي منشأة مرتبطة بك \nلإتمام التسجيل في منصة التكامل، يجب ان تكون تابعاً لمنشأة معينة",
        "phone-error-1": "رقم الهاتف المدخل مسجل من قبل",
        "birth-date-error-1": "تاريخ الميلاد المدخل غير صحيح",
        "birth-date-error-2": "لم يتم العثور على بيانات",
        "hijri-date-error-1": "Hijri date is invalid",
        "gregorian-date-error-1": "Gregorian date is invalid"
      },
      errors: {
        ...localization.en.errors,
        "not_in_status_approved": 'Sorry, we can\'t proceed with this request because the subscription is not in the "Approved" status',
        "payment_status_paid": 'تم دفع الاشتراك من قبل',
      },
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
