import React from 'react';
import { ReactComponent as EditActionIcon } from '../../assets/images/edit.svg'
import { ReactComponent as EditActionIconDark } from '../../assets/images/editDark.svg'
import { useTheme } from '@material-ui/core'

const EditIcon = () => {
  const theme = useTheme()
  return (
    <>
      {theme.UP.type === 'light'
        ? <EditActionIcon/>
        : <EditActionIconDark/>
      }
    </>
  );
};

export default EditIcon;
