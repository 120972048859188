import React from 'react';
import { ReactComponent as SuccessActionIcon } from '../../assets/images/success.svg'
import { ReactComponent as SuccessActionIconDark } from '../../assets/images/successDark.svg'
import { useTheme } from '@material-ui/core'

const SuccessIcon = () => {
  const theme = useTheme()
  return (
    <>
      {theme.UP.type === 'light'
        ? <SuccessActionIcon/>
        : <SuccessActionIconDark/>
      }
    </>
  );
};

export default SuccessIcon;
