import axios from "axios";
import { deserializer } from '@takamol/unified-components'
import getEnvVar from '../utils/getEnvVar'

axios.defaults.withCredentials = true;

export const instance = axios.create({
  baseURL: getEnvVar('REACT_APP_API_GATEWAY'),
  withCredentials: true
});

const api = async (
  method: string,
  url: string,
  payload?: object | any[],
  rejectWithValue?: any,
  options?: object
) => {
  try {
    // @ts-ignore
    const { data } = await instance[method](url, payload, { ...options })
    if (data.data) return deserializer(data)
    return data
  } catch (e: any) {
    if (rejectWithValue && e.response.status < 500 && e.response.status >= 400) {
      return rejectWithValue({
        ...e?.response?.data,
        status: e.response.status
      })
    }
    throw e
  }
}

export default api;
