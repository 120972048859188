import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Button, TextButton } from '@takamol/unified-components';
import { makeStyles } from '@material-ui/core/styles'
import { getRedirectionUrl, logout } from '../../store/auth'
import { useAppDispatch } from '../../store/root'
import { resetState } from '../../store/modalManager'

const STROKE_DASH_AMOUNT = 377

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.UP.content.primary,
  },
  secondary: {
    color: theme.UP.content.secondary,
  },
  gray: {
    color: theme.UP.content.gray
  },
  wrapper: {
    background: theme.UP.modal.background,
    padding: theme.spacing(5),
    borderRadius: 24,
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 1.5,
    '@media (max-width:450px)': {
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    },
    '& p': {
      margin: 0,
    },
  },
  icon: {
    display: 'block',
    margin: `0 auto ${theme.spacing(4)}px`,
  },
  countDownWrapper: {
    position: 'relative',
    width: 128,
    height: 128,
    margin: `${theme.spacing(5)}px auto 0`,
    '@media (max-width:450px)': {
      margin: `${theme.spacing(4)}px auto 0`,
    },
  },
  countDownTimer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontFamily: 'Arial !important',
    fontSize: 46,
    fontWeight: 'bold',
    lineHeight: 1,
    color: theme.UP.common.alternativeBlue,
  },
  circle: {
    width: '100%',
    height: '100%',
    strokeWidth: 10,
    stroke: theme.UP.modal.background,
    strokeLinecap: 'round',
    strokeDasharray: STROKE_DASH_AMOUNT,
    animation: "$circle 60s linear"
  },
  "@keyframes circle": {
    "from": {
      stroke: theme.UP.content.border,
      strokeDashoffset: 0,
    },
    "to": {
      stroke: theme.UP.content.border,
      strokeDashoffset: STROKE_DASH_AMOUNT,
    }
  },
}))

const ExpireTokenModal = () => {
  const [ counter, setCounter ] = useState(60)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const classes = useStyles()

  const handleKeepSession = () => {
    dispatch(resetState())
    window.localStorage.removeItem('tip_user_idle')
  }

  const handleEndSession = () => {
    dispatch(resetState())
    dispatch(logout()).then(() => dispatch(getRedirectionUrl()))
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (window.localStorage.getItem('tip_user_idle')) {
        setCounter(prevState => prevState - 1)
      } else {
        dispatch(resetState())
      }
    }, 1000)

    if (counter === 0) {
      clearInterval(timer)
      dispatch(logout()).then(() => dispatch(getRedirectionUrl()))
    }

    return () => {
      clearInterval(timer)
    }
  }, [ counter ])

  return (
    <Box className={classes.wrapper}>
      <Box>
        <Typography paragraph variant='inherit' className={classes.gray}>
          {t('commonTip.sessionExpired')}
        </Typography>
      </Box>
      <Box className={classes.countDownWrapper}>
        <svg width='100%' height='100%' fill='none'>
          <circle
            className={classes.circle}
            cx="64"
            cy="64"
            r="59"
          />
        </svg>
        <Typography className={classes.countDownTimer}>{counter}</Typography>
      </Box>
      <Box mt={5}>
        <Button onClick={handleKeepSession} fullWidth id='keepSession'>
          {t('actions.keepSession')}
        </Button>
      </Box>
      <Box mt={4}>
        <TextButton className={classes.secondary} fullWidth onClick={handleEndSession} id='endSession'>
          {t('actions.endSession')}
        </TextButton>
      </Box>
    </Box>
  );
};

export default ExpireTokenModal;
