import React, { useEffect, useState } from 'react'
import { Box, Button, List, Typography, ListItem, ListItemText, Collapse } from '@material-ui/core'
import { push } from 'connected-react-router'
import { useLocation } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { makeStyles } from '@material-ui/core/styles'
import userIcon from '../assets/images/user.svg'
import userIconDark from '../assets/images/userDark.svg'
import { RootState, useAppDispatch, useAppSelector } from '../store/root'
import {
  ThemeSwitcher,
  Header,
  SideBar,
  ProfileInfo,
  sortSerializer,
} from '@takamol/unified-components'
import {
  setTheme,
  sendTheme,
  getContext,
} from '../store/user'
import logoutIcon from '../assets/images/logoutIcon.svg'
import logoutIconDark from '../assets/images/logoutIconDark.svg'
import supportBg from '../assets/images/supportBg.svg'
import { SerializedError } from "@reduxjs/toolkit"
import { getRedirectionUrl, logout } from '../store/auth'
import { useTranslation } from 'react-i18next'
import { globalErrorService } from '../services/globalError'
import { showModal } from '../store/modalManager'
import Notifications from './notifications/Notifications'
import { ReactComponent as Logo } from '../assets/images/logo.svg'
import { ReactComponent as LogoAlt } from '../assets/images/logo-alt.svg'
import LanguageSwitcher from './shared/LanguageSwitcher'
import { ReactComponent as ArrowLightRight } from '../assets/images/arrow-light-r.svg'
import { ReactComponent as ArrowLightDown } from '../assets/images/arrow-light-d.svg'
import { ReactComponent as IconProducts } from '../assets/images/icon-products.svg'
import { ReactComponent as IconSubscriptions } from '../assets/images/icon-subscriptions.svg'
import { setWorkspace, Workspace, fetchWorkspaces } from '../store/workspaces'
import { PRODUCTS, PROFILE, SUBSCRIPTIONS, WORKSPACES } from '../constants/routes'
import { fetchSubscriptions, setLoading } from '../store/subscriptions'
import ExpireTokenModal from './modals/ExpireTokenModal'

interface ILayout {
  children: React.ReactNode,
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      '&.open': {
        '& [class*="background"]': {
          overflow: 'hidden'
        }
      },
    },
    '.MuiSwitch-thumb': {
      backgroundColor: theme.UP.type === 'light' ? theme.UP.common.black : theme.UP.common.white
    },
    '.arial':{
      fontFamily: 'Arial !important',
      '& input': {
        fontFamily: 'Arial !important',
        '&::-webkit-input-placeholder, &::-ms-input-placeholder, &::-moz-placeholder': {
          fontFamily: 'Almarai !important',
        },
      },
      '&::-webkit-input-placeholder, &::-ms-input-placeholder, &::-moz-placeholder': {
        fontFamily: 'Almarai !important',
      },
    },
    '.MuiInputBase-input, .MuiInputBase-input::placeholder': {
      fontFamily: 'Almarai !important'
    },
    '#otp, #phoneNumber::placeholder': {
      fontFamily: 'Arial !important'
    },
    '.MuiButton-label, .core-MuiButton-label': {
      fontFamily: 'Almarai !important',
      fontWeight: '400 !important'
    }
  },
  root: {
    flexGrow: 1,
  },
  companyName: {
    color: theme.UP.content.primary,
    '@media (max-width: 900px)': {
      display: 'none'
    }
  },
  background: {
    margin: `${theme.spacing(10)}px 0 0 auto`,
    width: 'calc(100% - 240px)',
    position: 'relative',
    backgroundColor: theme.UP.background.default,
    height: 'calc(100vh - 80px)',
    overflow: 'auto',
    "@media (max-width: 1100px)": {
      width: '100%',
    },
    "@media (max-width: 550px)": {
      margin: `60px 0 0 auto`,
      height: 'calc(100vh - 60px)',
    },
    "@media (max-width: 400px)": {
      padding: theme.spacing(2),
    },
  },
  darkMode: {
    color: theme.UP.common.white
  },
  logout: {
    background: theme.UP.background.paper,
    color: theme.UP.content.primary,
    border: `1px solid ${theme.UP.content.border}`,
    minWidth: 120,
    height: 42,
    textTransform: "unset",
    marginRight: theme.spacing(2),
    '@media (max-width: 550px)': {
      minWidth: 'unset',
      width: '40px',
      height: '40px',
      padding: 0,
      marginRight: 0,
      '& h5': {
        display: 'none'
      }
    }
  },
  logoutIcon: {
    marginLeft: theme.spacing(1),
    '@media (max-width: 550px)': {
      margin: 0
    }
  },
  sidebarWrapper: {
    '& > div > div': {
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  },
  profile: {
    margin: `0 0 ${theme.spacing(4)}px`,
    '& > div > div > p:nth-child(1)': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700
    },
    '& > div > div > p:nth-child(2)': {
      fontFamily: 'Arial',
      fontWeight: 400,
      color: '#BFC2C3'
    },
    '&.disabled': {
      cursor: 'default',
      pointerEvents: 'none',
      '& #profileInfo-btn': {
        display: 'none'
      }
    },
  },
  navList: {
    display: 'flex',
    flexDirection: 'column',
    border: 'solid rgba(255, 255, 255, 0.1)',
    borderWidth: '0 0 1px',
    overflow: 'visible',
    '& nav': {
      padding: 0,
    },
  },
  addNavList: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    '& nav': {
      padding: 0,
    },
  },
  navListItem: {
    color: theme.UP.common.white,
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '21px',
    minHeight: 50,
    border: 'solid rgba(255, 255, 255, 0.1)',
    borderWidth: '1px 0 0',
    '& *': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
    },
    '& span': {
      flip: false,
      direction: 'rtl',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& small': {
      fontFamily: 'Arial',
      fontSize: 12,
      color: '#BFC2C3',
    },
    '&.opener': {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      color: theme.UP.common.white,
      border: 'solid rgba(255, 255, 255, 0.1)',
      borderWidth: '1px 0 0',
      background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) no-repeat',
      backgroundColor: theme.UP.sidebar.background,
      '& svg': {
        transform: 'rotate(180deg)',
        transition: '0.4s transform',
      },
      '&.expanded': {
        '& svg': {
          transform: 'rotate(0deg)',
        }
      },
    },
    '&.link': {
      border: 'solid rgba(255, 255, 255, 0.1)',
      borderWidth: '1px 0 0',
      background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) no-repeat',
      position: 'sticky',
      color: theme.UP.common.white,
      bottom: 0,
      zIndex: 2,
      backgroundColor: theme.UP.sidebar.background,
      '& svg': {
        minWidth: 18,
        minHeight: 18,
        padding: 5,
        background: 'rgba(255, 255, 255, 0.15)',
        borderRadius: '50%',
        '[dir="rtl"] &': {
          transform: 'rotate(180deg)',
        },
      },
    },
    '&.add-nav-link': {
      fontSize: 16,
      minHeight: 54,
      fontWeight: 700,
      color: theme.UP.sidebar.navigation.inactive,
      '&:first-child': {
        borderTopWidth: 0,
      },
      '& svg': {
        minWidth: 20,
        minHeight: 20,
        marginRight: 16,
        fill: 'currentColor',
      },
      '&.active': {
        cursor: 'pointer',
        pointerEvents: 'initial',
        color: theme.UP.common.white,
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) no-repeat',
      },
    },
    '&.active': {
      cursor: 'default',
      pointerEvents: 'none',
      background: 'linear-gradient(to left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%) no-repeat',
    },
  },
  supportWrapper: {
    width: '208px',
    height: 172,
    background: `url(${supportBg}) no-repeat`,
    backgroundSize: 'cover',
    margin: `auto ${theme.spacing(2)}px ${theme.spacing(6)}px`,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(4)}px`,
    color: theme.UP.common.white,
    '& h3': {
      marginBottom: theme.spacing(0.5),
    },
  },
  supportBtn: {
    background: theme.UP.common.white,
    color: '#0D5068',
    height: 37,
    borderRadius: 10,
    padding: 0,
    marginTop: theme.spacing(3),
    textTransform: 'unset',

    '&:hover': {
      background: theme.UP.common.white,
    }
  },
  notificationWrapper: {
    margin: `0 ${theme.spacing(2)}px`,
    '@media (max-width: 550px)': {
      margin: `0 ${theme.spacing(1)}px`,
    }
  },
  header: {
    '& header': {
      justifyContent: 'center',
    },
  },
  logo: {
    width: 116,
    height: 'auto',
    '@media (min-width: 768px)': {
      width: 174,
    }
  },
  themeSwitcherWrapper: {
    // display: 'none',
    marginBottom: theme.spacing(6),
    '& h6': {
      fontWeight: 500,
    },
  },
  switchBase: {
    background: `${theme.UP.common.white} !important`,
  },
  thumb: {
    background: `#031438 !important`,
  },
  track: {
    background: `${theme.UP.common.white} !important`,
    opacity: '1 !important',
    borderColor: `${theme.UP.common.white} !important`,
  },
}))

const filtersSerializer = (data: any) => {
  let filter = '&'
  Object.keys(data).forEach(key => {
    if (data[key].value) {
      filter += `q[${key}]`
      filter += data[key].action ? `[${data[key].action}]=${data[key].value}${data[key].action === 'like' ? '*' : ''}` : `=${data[key].value}`
      filter += '&'
    }
  })
  return filter.substring(0, filter.length - 1)
}

const Layout = ({ children }: ILayout) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [ mobileOpen, setMobileOpen ] = React.useState<boolean>(false)
  const { theme, name, nationalId, img, sequenceNumber, roles } = useAppSelector((state: RootState) => state.user)
  const { workspaces, selectedWorkspace } = useAppSelector((state) => state.workspaces)
  const { filters } = useAppSelector((state) => state.subscriptions)
  // TODO: point of integration for global error handling in warning component
  const [ error, setError ] = useState<SerializedError | null>(null)
  const [ menuOpen, setMenuOpen ] = React.useState<boolean>(true)
  const showWorkspaces = location.pathname.includes(PRODUCTS) || location.pathname.includes(SUBSCRIPTIONS)
  const isUser = roles.includes('user')
  const isDisabled = isUser && !sequenceNumber

  const onIdle = () => {
    window.localStorage.setItem('tip_user_idle', 'idle')
    dispatch(showModal({
      variant: 'custom',
      maxWidth: 550,
      children: <ExpireTokenModal/>,
      onClose: () => console.log('continue session')
    }))
  }

  useIdleTimer({
    startOnMount: true,
    crossTab: true,
    timeout: 39 * 60 * 1000, // 39min
    onIdle,
  })

  useEffect(() => {
    const error$ = globalErrorService.getError()
    const errorSubscription = error$.subscribe((error: SerializedError) => {
      error ? setError(error) : setError(null)
    })
    if (workspaces.length <= 0 && isUser) dispatch(fetchWorkspaces())
    return () => {
      errorSubscription.unsubscribe()
    }
  }, [ dispatch ])

  const handleLogoutModal = () => {
    dispatch(showModal({
      variant: 'confirm',
      title: t('changeCompanyProfile.modalTitle'),
      text: t('common.logoutConfirmText'),
      showIcon: false,
      onConfirm: () => {
        dispatch(logout()).then(() => dispatch(getRedirectionUrl()))
      }
    }))
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleClose = () => {
    setMobileOpen(false)
  }

  const isActive = () => {
    return workspaces.some((item: Workspace) => sequenceNumber === item.sequenceNumber.toString())
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const mode = event.target.checked ? 'dark' : 'light'
    dispatch(setTheme(mode))
    dispatch(sendTheme(mode))
  }

  const handleZendesk = () => {
    window.zE.activate({ hideOnClose: true })
  }

  const goToUserProfile = () => {
    dispatch(push(PROFILE))
  }

  const handlePush = (url: string) => {
    dispatch(push(url))
  }

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen)
  }

  const handleRefresh = (sequenceNumber: number, laborOffice: number) => {
    dispatch(setLoading(true))
    dispatch(fetchSubscriptions({
      currentPage: 1,
      sort: sortSerializer([ { field: 'id', sort: 'desc' } ]),
      filters: filtersSerializer({
        ...filters,
        // TODO Mocked on FE
        ...{
          personal_number: {
            value: nationalId,
            action: 'eq'
          },
          sequence_number: {
            value: sequenceNumber,
            action: 'eq'
          },
          labor_office: {
            value: laborOffice,
            action: 'eq'
          }
        }
      })
    }))
  }

  const handleWorkspace = (workspace: Workspace) => () => {
    dispatch(showModal({
      variant: 'confirm',
      title: t('workspacesTip.confirmModalTitle'),
      text: isActive()
          ? t('workspacesTip.switch', { name: workspace.establishmentName })
          : `${t('workspaces.sure')} ${workspace.establishmentName} ${t('workspacesTip.defaultCompany')}`,
      showIcon: false,
      onConfirm: () => {
        dispatch(setWorkspace(workspace.id))
        .then(() => {
          setMobileOpen(false)
          dispatch(getContext()).then(() => {
            if (location.pathname.includes('subscriptions')) {
              handleRefresh(workspace.sequenceNumber, workspace.laborOffice)
            }
          })
        })
      }
    }))
  }

  return (
    <Box height="100%" width="100%" className={classes.header}>
      <Header handleDrawerToggle={handleDrawerToggle}>
        <Box mr={2}>
          {theme === 'light' ? <Logo className={classes.logo}/> : <LogoAlt className={classes.logo}/>}
        </Box>
        <Box className={classes.companyName}>
          <Typography variant="h3">
            {selectedWorkspace.establishmentName}
          </Typography>
        </Box>
        <Box flexGrow={1} alignItems="center" justifyContent="flex-end" display="flex" flexDirection="row">
          <Box>
            <LanguageSwitcher />
          </Box>
          <Box className={classes.notificationWrapper}>
            {isUser && <Notifications/>}
          </Box>
        </Box>
        <Box alignItems="center" display="flex">
          <Button id='logout-btn' onClick={handleLogoutModal} className={classes.logout} variant='outlined'>
            <Typography variant='h5'>
              {t('common.logout')}
            </Typography>
            <img className={classes.logoutIcon} src={theme === 'dark' ? logoutIconDark : logoutIcon} alt="logout"/>
          </Button>
        </Box>
      </Header>
      <Box display="flex" flexDirection="row" height='100%'>
        <Box className={classes.sidebarWrapper}>
          <SideBar
            onClose={handleClose}
            open={mobileOpen}
          >
            <Box className={`${classes.profile} ${isDisabled ? 'disabled' : ''}`}>
              <ProfileInfo
                onClick={isDisabled ? () => {return false} : goToUserProfile}
                name={name || 'user name'}
                personalNumber={nationalId || ''}
                image={img || theme === 'light' ? userIcon : userIconDark}
              />
            </Box>
            {roles.includes('user') && (
              <Box className={classes.navList}>
                <List component="nav">
                  {showWorkspaces && (
                    <>
                      <ListItem
                        button
                        className={`${classes.navListItem} opener ${menuOpen ? 'expanded' : ''}`}
                        onClick={handleMenuToggle}>
                        <ListItemText primary={t('sidebar.changeCompanyProfile')} />
                        <ArrowLightDown/>
                      </ListItem>
                      <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                        {workspaces.length > 0 && workspaces.map((workspace: Workspace) => (
                          <ListItem
                            button
                            className={`${classes.navListItem} ${workspace.sequenceNumber === +sequenceNumber ? 'active' : ''}`}
                            key={workspace?.id}
                            {...(
                              workspace.sequenceNumber !== +sequenceNumber && { onClick: handleWorkspace(workspace) }
                            )}
                          >
                            <ListItemText
                              primary={workspace?.establishmentName || ''}
                              secondary={workspace?.id}
                              secondaryTypographyProps={{
                                component: 'small'
                              }}
                            />
                          </ListItem>
                        ))}
                      </Collapse>
                    </>
                  )}
                  <ListItem button className={`${classes.navListItem} link`} onClick={() => handlePush(WORKSPACES)}>
                    <ListItemText primary={t('sidebar.viewAllCompanies')} />
                    <ArrowLightRight/>
                  </ListItem>
                </List>
              </Box>
            )}
            <Box className={classes.addNavList}>
              <List component="nav">
                {!roles.includes('admin') && !roles.includes('ibm-admin') && (
                  <ListItem
                    disabled={isDisabled}
                    button
                    className={`${classes.navListItem} add-nav-link ${location.pathname.includes(PRODUCTS) ? 'active' : ''}`}
                    onClick={() => handlePush(PRODUCTS)}>
                    <IconProducts/>
                    <ListItemText primary={t('sidebarLinks.products')} />
                  </ListItem>
                )}
                <ListItem
                  disabled={isDisabled}
                  button
                  className={`${classes.navListItem} add-nav-link ${location.pathname.includes(SUBSCRIPTIONS) ? 'active' : ''}`}
                  onClick={() => handlePush(SUBSCRIPTIONS)}>
                  <IconSubscriptions/>
                  <ListItemText primary={t('sidebarLinks.subscriptions')} />
                </ListItem>
              </List>
            </Box>
            <Box className={classes.supportWrapper}>
              <Typography variant='h3'>{t('sidebar.supportTime')}</Typography>
              <Typography variant='subtitle2'>{t('sidebar.contactUs')}</Typography>
              <Button onClick={handleZendesk} fullWidth className={classes.supportBtn}>
                <Typography variant='h5'>
                  {t('sidebar.sendBtn')}
                </Typography>
              </Button>
            </Box>
            <Box className={classes.themeSwitcherWrapper}>
              <ThemeSwitcher
                checked={theme === 'dark'}
                Mode={theme}
                classes={{
                  switchBase: classes.switchBase,
                  track: classes.track,
                  thumb: classes.thumb,
                }}
                title={t('sidebar.darkMode')}
                onChange={handleChange}/>
            </Box>
          </SideBar>
        </Box>
        <Box display="flex" flexDirection="column" className={classes.background} p={3}>
          <Box>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default Layout;
