import React from 'react';
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button } from '@takamol/unified-components'

interface IErrorOverlay {
  title: string;
  text?: string;
  onRefresh?: () => void;
}

const useStyles = makeStyles((theme) => ({
  errorWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(16)}px ${theme.spacing(5)}px ${theme.spacing(10)}px`,
    textAlign: 'center',
    zIndex: 3,
  },
  title: {
    color: theme.UP.content.error
  },
  text: {
    color: theme.UP.content.error
  },
  refreshBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 176,
    marginTop: theme.spacing(3),
    textTransform: 'unset',
  }
}));

const ErrorOverlay = (props: IErrorOverlay) => {
  const classes = useStyles()
  const { title, text, onRefresh } = props;
  const { t } = useTranslation()
  return (
    <Box className={classes.errorWrapper}>
      <Typography className={classes.title} variant='h3'>{title}</Typography>
      <Typography className={classes.text} variant='subtitle2'>{text}</Typography>
      {!!onRefresh && <Button
        id='refresh'
        className={classes.refreshBtn}
        onClick={onRefresh}>
          {t('common.onRefresh')}
      </Button>}
    </Box>
  );
};

export default ErrorOverlay;
