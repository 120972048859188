import React from 'react';
import { ReactComponent as ErrorActionIcon } from '../../assets/images/error.svg'
import { ReactComponent as ErrorActionIconDark } from '../../assets/images/errorDark.svg'
import { useTheme } from '@material-ui/core'

const ErrorIcon = () => {
  const theme = useTheme()
  return (
    <>
      {theme.UP.type === 'light'
        ? <ErrorActionIcon/>
        : <ErrorActionIconDark/>
      }
    </>
  );
};

export default ErrorIcon;
